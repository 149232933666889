
// v5 replace this with the FullScreenSidebar, right now it has prob with centering on the screen


// ok v3.2 add redux to change Marker state
import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';  // Import react-swipeable
import './Popup.css';
// import { useDispatch, useSelector } from 'react-redux';
// import { extendMarker, shrinkMarker } from '../../redux/ActionCreators'
import { store} from '../../redux/MyRedux'


const Popup = ({ property, setShowPopup }) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  // const markerState = useSelector(state => state.marker.markerState);
  // useEffect(() => {
  //   console.log('Marker state has been updated:', markerState);  // Log marker state whenever it changes
  // }, [markerState]);  // This effect runs every time the markerState updates
  // const dispatch = useDispatch();
//

  console.log("####### redux popup.js #########")
  console.log(store.getState().isExtented)

  useEffect(() => {
    // Close the popup if the user presses ESC key
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        setShowPopup(false);
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [setShowPopup]);

  // Handle swipe gestures
  const handleSwipe = (e) => {
    if (e.dir === 'Left') {
      // Swipe left, go to next media
      setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
    } else if (e.dir === 'Right') {
      // Swipe right, go to previous media
      setCurrentMediaIndex((prevIndex) =>
        prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
      );
    }
  };

  // Get the current media (image or video)
  const currentMedia = property.media[currentMediaIndex];

  // Swipeable props for detecting swipe events
  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe, // Set the swipe handler
    preventDefaultTouchmoveEvent: true,  // Prevent scroll when swiping
    trackMouse: true,  // Allow swipe with mouse on desktop
  });

  // Handle next media click
  const handleNextMedia = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
  };

  // Handle previous media click
  const handlePrevMedia = () => {
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="popup-overlay" onClick={() => setShowPopup(false)}>
      <div
        className="popup-content"
        onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it
        {...swipeHandlers} // Attach swipe handlers
      >
        <button className="close-btn" onClick={() => setShowPopup(false)}>
          ×
        </button>

        {/* Property Information */}
        <div className="popup-header">
          <h2>{property.address}</h2>
          <p className="popup-title"><strong>{property.title}</strong></p>
        </div>

        {/* Media Slideshow */}
        <div className="media-container">
          {currentMedia?.url && (
            <>
              {currentMedia?.type === 'video' ? (
                <video
                  src={`https://${currentMedia.url}`}
                  controls
                  className="popup-video"
                  alt={property.city}
                  autoPlay
                />
              ) : (
                <div className="image-container">
                  <img
                    src={`https://${currentMedia.url}`}
                    alt={property.city}
                    className="popup-image"
                  />
                  {/* Navigation Arrows embedded on the image */}
                  <button className="prev-btn" onClick={handlePrevMedia}>
                    &lt;
                  </button>
                  <button className="next-btn" onClick={handleNextMedia}>
                    &gt;
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* Property Details */}
        <div className="popup-info">
          <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
          <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>
          <p><strong>Location:</strong> Latitude: {property.location.coordinates[0]}, Longitude: {property.location.coordinates[1]}</p>
        </div>

        <div className="popup-description">
          <p><strong>Description:</strong> {property.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Popup;





// ok v3.1 make remove the arrow, make the img bigger
// import React, { useState, useEffect } from 'react';
// import { useSwipeable } from 'react-swipeable';  // Import react-swipeable
// import './Popup.css';

// const Popup = ({ property, setShowPopup }) => {
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

//   useEffect(() => {
//     // Close the popup if the user presses ESC key
//     const handleEscape = (e) => {
//       if (e.key === 'Escape') {
//         setShowPopup(false);
//       }
//     };

//     window.addEventListener('keydown', handleEscape);

//     return () => {
//       window.removeEventListener('keydown', handleEscape);
//     };
//   }, [setShowPopup]);

//   // Handle swipe gestures
//   const handleSwipe = (e) => {
//     if (e.dir === 'Left') {
//       // Swipe left, go to next media
//       setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
//     } else if (e.dir === 'Right') {
//       // Swipe right, go to previous media
//       setCurrentMediaIndex((prevIndex) =>
//         prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//       );
//     }
//   };

//   // Get the current media (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   // Swipeable props for detecting swipe events
//   const swipeHandlers = useSwipeable({
//     onSwiped: handleSwipe, // Set the swipe handler
//     preventDefaultTouchmoveEvent: true,  // Prevent scroll when swiping
//     trackMouse: true,  // Allow swipe with mouse on desktop
//   });

//   // Handle next media click
//   const handleNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
//   };

//   // Handle previous media click
//   const handlePrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   return (
//     <div className="popup-overlay" onClick={() => setShowPopup(false)}>
//       <div
//         className="popup-content"
//         onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it
//         {...swipeHandlers} // Attach swipe handlers
//       >
//         <button className="close-btn" onClick={() => setShowPopup(false)}>
//           ×
//         </button>

//         {/* Property Information */}
//         <div className="popup-header">
//           <h2>{property.address}</h2>
//           <p className="popup-title"><strong>{property.title}</strong></p>
//         </div>

//         {/* Media Slideshow */}
//         <div className="media-container">
//           {currentMedia?.url && (
//             <>
//               {currentMedia?.type === 'video' ? (
//                 <video
//                   src={`https://${currentMedia.url}`}
//                   controls
//                   className="popup-video"
//                   alt={property.city}
//                   autoPlay
//                 />
//               ) : (
//                 <div className="image-container">
//                   <img
//                     src={`https://${currentMedia.url}`}
//                     alt={property.city}
//                     className="popup-image"
//                   />
//                   {/* Navigation Arrows embedded on the image */}
//                   <button className="prev-btn" onClick={handlePrevMedia}>
//                     &lt;
//                   </button>
//                   <button className="next-btn" onClick={handleNextMedia}>
//                     &gt;
//                   </button>
//                 </div>
//               )}
//             </>
//           )}
//         </div>

//         {/* Property Details */}
//         <div className="popup-info">
//           <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//           <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>
//           <p><strong>Location:</strong> Latitude: {property.location.coordinates[0]}, Longitude: {property.location.coordinates[1]}</p>
//         </div>

//         <div className="popup-description">
//           <p><strong>Description:</strong> {property.description}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Popup;




// ok v3 fix css on mobile
// import React, { useState, useEffect } from 'react';
// import './Popup.css'; // Assuming you have some basic styles

// const Popup = ({ property, setShowPopup }) => {
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

//   useEffect(() => {
//     // Close the popup if the user presses ESC key
//     const handleEscape = (e) => {
//       if (e.key === 'Escape') {
//         setShowPopup(false);
//       }
//     };

//     window.addEventListener('keydown', handleEscape);

//     return () => {
//       window.removeEventListener('keydown', handleEscape);
//     };
//   }, [setShowPopup]);

//   // Handle showing the next media in the slideshow
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
//   };

//   // Handle showing the previous media in the slideshow
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Only render the popup if property exists
//   if (!property) {
//     return null;
//   }

//   // Get the current media (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div className="popup-overlay">
//       <div
//         className="popup-content"
//         onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it (but not on close button)
//       >
//         <button className="close-btn" onClick={() => setShowPopup(false)}>
//           X
//         </button>

//         {/* Property Information */}
//         <div className="popup-header">
//           <h2>{property.address}</h2>
//           <p className="popup-title"><strong>{property.title}</strong></p>
//         </div>

//         <div className="popup-info">
//           {/* <p><strong>City:</strong> {property.city}</p>
//           <p><strong>Status:</strong> {property.status}</p> */}
//           <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//           <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>
//           <p><strong>Location:</strong> Latitude: {property.location.coordinates[0]}, Longitude: {property.location.coordinates[1]}</p>
//         </div>

//         <div className="popup-description">
//           <p><strong>Description:</strong> {property.description}</p>
//         </div>

//         {/* Media Slideshow */}
//         <div className="media-container">
//           {currentMedia?.url && (
//             <>
//               {currentMedia?.type === 'video' ? (
//                 <video
//                   src={`https://${currentMedia.url}`}
//                   controls
//                   className="popup-video"
//                   alt={property.city}
//                   autoPlay
//                 />
//               ) : (
//                 <img
//                   src={`https://${currentMedia.url}`}
//                   alt={property.city}
//                   className="popup-image"
//                 />
//               )}
//             </>
//           )}
//         </div>

//         {/* Navigation buttons for the slideshow */}
//         <div className="slideshow-controls">
//           <button className="prev-btn" onClick={showPrevMedia}>
//             ←
//           </button>
//           <button className="next-btn" onClick={showNextMedia}>
//             →
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Popup;





//ok v2.2 cleanup stuff
// import React, { useState, useEffect } from 'react';
// import './Popup.css'; // Assuming you have some basic styles

// const Popup = ({ property, setShowPopup }) => {
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

//   useEffect(() => {
//     // Close the popup if the user presses ESC key
//     const handleEscape = (e) => {
//       if (e.key === 'Escape') {
//         setShowPopup(false);
//       }
//     };

//     window.addEventListener('keydown', handleEscape);

//     return () => {
//       window.removeEventListener('keydown', handleEscape);
//     };
//   }, [setShowPopup]);

//   // Handle showing the next media in the slideshow
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
//   };

//   // Handle showing the previous media in the slideshow
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Only render the popup if property exists
//   if (!property) {
//     return null;
//   }

//   // Get the current media (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div className="popup-overlay">
//       <div
//         className="popup-content"
//         onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it (but not on close button)
//       >
//         <button className="close-btn" onClick={() => setShowPopup(false)}>
//           X
//         </button>

//         {/* Property Information */}
//         <div className="popup-header">
//           <h2>{property.address}</h2>
//           <p className="popup-title"><strong>{property.title}</strong></p>
//         </div>

//         <div className="popup-info">
//           {/* <p><strong>City:</strong> {property.city}</p>
//           <p><strong>Status:</strong> {property.status}</p> */}
//           <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//           <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>
//           <p><strong>Location:</strong> Latitude: {property.location.coordinates[0]}, Longitude: {property.location.coordinates[1]}</p>
//         </div>

//         <div className="popup-description">
//           <p><strong>Description:</strong> {property.description}</p>
//         </div>

//         {/* Media Slideshow */}
//         <div className="media-container">
//           {currentMedia?.url && (
//             <>
//               {currentMedia?.type === 'video' ? (
//                 <video
//                   src={`https://${currentMedia.url}`}
//                   controls
//                   className="popup-video"
//                   alt={property.city}
//                   autoPlay
//                 />
//               ) : (
//                 <img
//                   src={`https://${currentMedia.url}`}
//                   alt={property.city}
//                   className="popup-image"
//                 />
//               )}
//             </>
//           )}
//         </div>

//         {/* Navigation buttons for the slideshow */}
//         <div className="slideshow-controls">
//           <button className="prev-btn" onClick={showPrevMedia}>
//             ←
//           </button>
//           <button className="next-btn" onClick={showNextMedia}>
//             →
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Popup;



// ok v2.1 make it look better, centering stuff
// import React, { useState, useEffect } from 'react';
// import './Popup.css'; // Assuming you have some basic styles

// const Popup = ({ property, setShowPopup }) => {
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

//   useEffect(() => {
//     // Close the popup if the user presses ESC key
//     const handleEscape = (e) => {
//       if (e.key === 'Escape') {
//         setShowPopup(false);
//       }
//     };

//     window.addEventListener('keydown', handleEscape);

//     return () => {
//       window.removeEventListener('keydown', handleEscape);
//     };
//   }, [setShowPopup]);

//   // Handle showing the next media in the slideshow
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
//   };

//   // Handle showing the previous media in the slideshow
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Only render the popup if property exists
//   if (!property) {
//     return null;
//   }

//   // Get the current media (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div className="popup-overlay">
//       <div
//         className="popup-content"
//         onClick={(e) => e.stopPropagation()} // Prevent the popup from closing when clicking inside it (but not on close button)
//       >
//         <button className="close-btn" onClick={() => setShowPopup(false)}>
//           X
//         </button>

//         {/* Property Information */}
//         <h2>{property.address}</h2>
//         <p><strong>Price:</strong> ${property.price}</p>
//         <p><strong>City:</strong> {property.city}</p>
//         <p><strong>Status:</strong> {property.status}</p>
//         <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//         <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>
//         <p><strong>Location:</strong> Latitude: {property.location.coordinates[0]}, Longitude: {property.location.coordinates[1]}</p>
//         <p><strong>Description:</strong> {property.description}</p>

//         {/* Media Slideshow */}
//         <div className="media-container">
//           {currentMedia?.url && (
//             <>
//               {currentMedia?.type === 'video' ? (
//                 <video
//                   src={`https://${currentMedia.url}`}
//                   controls
//                   className="popup-video"
//                   alt={property.city}
//                   autoPlay
//                 />
//               ) : (
//                 <img
//                   src={`https://${currentMedia.url}`}
//                   alt={property.city}
//                   className="popup-image"
//                 />
//               )}
//             </>
//           )}
//         </div>

//         {/* Navigation buttons for the slideshow */}
//         <div className="slideshow-controls">
//           <button className="prev-btn" onClick={showPrevMedia}>
//             ←
//           </button>
//           <button className="next-btn" onClick={showNextMedia}>
//             →
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Popup;



// ok v2 display all media and more info
// import React, { useState, useEffect } from 'react';
// import './Popup.css'; // Assuming you have some basic styles

// const Popup = ({ property, setShowPopup }) => {
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

//   useEffect(() => {
//     // Close the popup if the user presses ESC key
//     const handleEscape = (e) => {
//       if (e.key === 'Escape') {
//         setShowPopup(false);
//       }
//     };

//     window.addEventListener('keydown', handleEscape);

//     return () => {
//       window.removeEventListener('keydown', handleEscape);
//     };
//   }, [setShowPopup]);

//   // Close the popup when clicking the overlay
//   const handleOverlayClick = () => {
//     setShowPopup(false);
//   };

//   // Handle showing the next media in the slideshow
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
//   };

//   // Handle showing the previous media in the slideshow
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Only render the popup if property exists
//   if (!property) {
//     return null;
//   }

//   // Get the current media (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div className="popup-overlay" onClick={handleOverlayClick}>
//       <div className="popup-content" onClick={(e) => e.stopPropagation()}>
//         {/* Close button */}
//         <button className="close-btn" onClick={() => setShowPopup(false)}>
//           X
//         </button>

//         {/* Property Information */}
//         <h2>{property.title || "Property Details"}</h2>
//         <p><strong>Address:</strong> {property.address}</p>
//         <p><strong>Price:</strong> ${property.price}</p>
//         <p><strong>City:</strong> {property.city}</p>
//         <p><strong>Status:</strong> {property.status}</p>
//         <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//         <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>
//         <p><strong>Description:</strong> {property.description}</p>

//         {/* Location */}
//         <p><strong>Location:</strong> Latitude: {property.location.coordinates[0]}, Longitude: {property.location.coordinates[1]}</p>

//         {/* Scrollable Container for Media */}
//         <div className="media-container">
//           {currentMedia?.url && (
//             <>
//               {currentMedia?.type === 'video' ? (
//                 <video
//                   src={`https://${currentMedia.url}`}
//                   controls
//                   className="popup-video"
//                   alt={property.city}
//                   autoPlay
//                   style={{
//                     width: '100%',
//                     borderRadius: '8px',
//                     marginBottom: '10px',
//                   }}
//                 />
//               ) : (
//                 <img
//                   src={`https://${currentMedia.url}`}
//                   alt={property.city}
//                   className="popup-image"
//                   style={{
//                     width: '100%',
//                     borderRadius: '8px',
//                     marginBottom: '10px',
//                   }}
//                 />
//               )}
//             </>
//           )}
//         </div>

//         {/* Navigation buttons for the slideshow */}
//         <div className="slideshow-controls" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//           <button className="prev-btn" onClick={showPrevMedia}>
//             ←
//           </button>
//           <button className="next-btn" onClick={showNextMedia}>
//             →
//           </button>
//         </div>

//         {/* Display all media thumbnails */}
//         {property.media.length > 1 && (
//           <div className="media-thumbnails">
//             {property.media.map((item, index) => (
//               <button
//                 key={index}
//                 onClick={() => setCurrentMediaIndex(index)}
//                 style={{
//                   border: currentMediaIndex === index ? '2px solid #007BFF' : 'none',
//                   margin: '5px',
//                   padding: '5px',
//                   borderRadius: '5px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 {item.type === 'video' ? (
//                   <video
//                     src={`https://${item.url}`}
//                     alt={`Media ${index + 1}`}
//                     style={{ width: '80px', height: 'auto', borderRadius: '5px' }}
//                   />
//                 ) : (
//                   <img
//                     src={`https://${item.url}`}
//                     alt={`Media ${index + 1}`}
//                     style={{ width: '80px', height: 'auto', borderRadius: '5px' }}
//                   />
//                 )}
//               </button>
//             ))}
//           </div>
//         )}

//       </div>
//     </div>
//   );
// };

// export default Popup;




// // ok v1 show all img OK, fix double pop up
// import React, { useState, useEffect } from 'react';
// import './Popup.css'; // Assuming you have some basic styles

// const Popup = ({ property, setShowPopup }) => {
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

//   useEffect(() => {
//     // Close the popup if the user presses ESC key
//     const handleEscape = (e) => {
//       if (e.key === 'Escape') {
//         setShowPopup(false);
//       }
//     };

//     window.addEventListener('keydown', handleEscape);

//     return () => {
//       window.removeEventListener('keydown', handleEscape);
//     };
//   }, [setShowPopup]);

//   // Close the popup when clicking the overlay
//   const handleOverlayClick = () => {
//     setShowPopup(false);
//   };

//   // Handle showing the next media in the slideshow
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % property.media.length);
//   };

//   // Handle showing the previous media in the slideshow
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Only render the popup if property exists
//   if (!property) {
//     return null;
//   }

//   // Get the current media (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div className="popup-overlay" onClick={handleOverlayClick}>
//       <div className="popup-content" onClick={(e) => e.stopPropagation()}>
//         <button className="close-btn" onClick={() => setShowPopup(false)}>
//           X
//         </button>
//         <h2>{property.address}</h2>
//         <p>Price: ${property.price}</p>
//         <p>{property.city}</p>

//         {/* Media Slideshow */}
//         <div className="media-container">
//           {currentMedia?.url && (
//             <>
//               {currentMedia?.type === 'video' ? (
//                 <video
//                   src={`https://${currentMedia.url}`}
//                   controls
//                   className="popup-video"
//                   alt={property.city}
//                   autoPlay
//                 />
//               ) : (
//                 <img
//                   src={`https://${currentMedia.url}`}
//                   alt={property.city}
//                   className="popup-image"
//                 />
//               )}
//             </>
//           )}
//         </div>

//         {/* Navigation buttons for the slideshow */}
//         <div className="slideshow-controls">
//           <button className="prev-btn" onClick={showPrevMedia}>←</button>
//           <button className="next-btn" onClick={showNextMedia}>→</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Popup;
