// ok now (check why does it log so many white the isSelected condition is set already) v9.2 add touch event to toggle marker -> fix isSelected check right now many marker run log inside that condition
import React, { useState } from "react";
import FullScreenSidebar from "./FullScreenSidebar";
import { store} from '../redux/MyRedux'

const Marker2 = ({ property, mapRef, selectedPlace, setGestureHandling  }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);




  // Function to toggle the clicked state and open sidebar
  const handleMarkerClick = () => {
    setIsClicked(!isClicked); // Toggle the clicked state
    setIsSidebarOpen(true); // Open sidebar on marker click

    setGestureHandling('none'); // Example: disable gestureHandling when marker is clicked

  };

  // Close the popup and sidebar when the close button is clicked
  const handleClosePopup = (e) => {
    e.stopPropagation(); // Prevent click from propagating to the overlay
    setIsClicked(false); // Close the popup
    setIsSidebarOpen(false); // Close the sidebar

    setGestureHandling('auto');
    setIsHovered(false)
  };

  // Close the popup if overlay is clicked
  const handleOverlayClick = () => {
    setIsClicked(false); // Close the popup
    setIsSidebarOpen(false); // Close the sidebar
  };

  // Show the previous media item
  const showPrevMedia = () => {
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
    );
  };

  // Show the next media item
  const showNextMedia = () => {
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === property.media.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Current media item being displayed (image or video)
  const currentMedia = property.media[currentMediaIndex];

  // Check if this marker is selected (matching selectedPlace)
  var isSelected = selectedPlace && selectedPlace._id === property._id;


  // console.log("####### prop _id  Marker2.js #########")
  // console.log (property._id)
  if (isSelected) {


    // console.log("####### isSelected  Marker2.js #########")
    // console.log (isSelected)
    console.log("####### redux  Marker2.js #########")
    console.log (selectedPlace)
    console.log(property._id)
    console.log(store.getState().isExtented)
    if (!store.getState().isExtented){ isSelected=false}

  }

  // Marker styles that change based on hover, click, or selection
  const markerStyles = {
    position: "relative",
    color: "#000",
    backgroundColor: isHovered || isClicked || isSelected ? "white" : "#fff",
    padding: isHovered || isClicked || isSelected ? "10px 20px" : "10px",
    borderRadius: isHovered || isClicked || isSelected ? "30px" : "50%",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s ease",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: isHovered || isClicked || isSelected ? "150px" : "1px", // Expand when hovered or clicked or selected
    height: "1px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    lineHeight: isHovered || isClicked || isSelected ? "normal" : "60px",
    zIndex: isHovered || isClicked || isSelected ? 3 : 1, // Set z-index based on hover or click state
  };

  // Marker dot styles (changes position and size when hovered or clicked)
  const markerDotStyles = {
    position: "absolute",
    width: "25px",  // Size of the circle
    height: "25px", // Size of the circle
    borderRadius: "50%", // Circle shape
    top: "50%", // Center vertically within the parent container
    left: isHovered || isClicked || isSelected ? "10%" : "50%", // Moves the dot when hovered or clicked or selected
    transform: "translate(-50%, -50%)", // Center the dot exactly
    zIndex: 2, // Keeps it below the hover effect and text
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  // Text on hover
  const textOnHoverStyles = {
    position: "absolute",
    width: "100px",  // White circle size
    height: "100px", // White circle size
    backgroundColor: "white", // Color of the white part
    borderRadius: "50%", // Circle shape
    top: "50%", // Center the white part vertically
    left: "50%", // Center the white part horizontally
    transform: "translate(-50%, -50%)", // Adjust for perfect centering
    zIndex: 4, // Put the white part above the black dot and text
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div
      style={markerStyles}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleMarkerClick} // Handle click/touch for expansion
    >
      {/* Marker dot */}
      <div style={markerDotStyles}>
        <img
          src="/images/icons8-meal-50.png"  // Replace with your image path
          alt="Icon"
          style={{
            width: "15px",   // Icon size to fit within the circle
            height: "15px",  // Icon size to fit within the circle
            objectFit: "contain", // Ensures the icon fits within the circle
          }}
        />
      </div>

      {/* Text on hover */}
      {(isHovered || isSelected) && (
        <div style={textOnHoverStyles}>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#000",
              textAlign: "center",
              transform: "translateX(35px)", // Shift text within the circle
            }}
          >
            {property.title}
          </span>
        </div>
      )}

      {/* Fullscreen Sidebar (popup) */}
      {isSidebarOpen && property && (
        <FullScreenSidebar
          property={property}  // Pass the entire property object
          onClose={handleClosePopup} // Pass the close handler
          mapRef={mapRef}
        />
      )}

      {/* Popup window (modal) */}
      {isClicked && !isSidebarOpen && (
        <div
          className="popup-overlay"
          style={{
            position: "fixed",
            top: "50%",
            left: "25%", // Move the popup to the left
            transform: "translate(-50%, -50%)", // Keep it centered based on its own width
            width: "33%",  // Take up 1/3 of the screen's width
            maxWidth: "400px", // Max width for better control
            height: "auto", // Adjust height based on content
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
            zIndex: 9999, // Ensure the popup is on top
            overflowY: "auto", // Scroll if content exceeds height
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          onClick={handleOverlayClick} // Close the popup when clicking outside
        >
          <div
            className="popup-content"
            onClick={(e) => e.stopPropagation()} // Prevent the popup from closing if clicking inside
            style={{ width: "100%" }}
          >
            {/* Close button */}
            <button
              className="close-btn"
              onClick={handleClosePopup}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "#ff5c5c",
                color: "white",
                border: "none",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
                fontSize: "18px",
              }}
            >
              X
            </button>

            <h2>{property.title}</h2>
            <p><strong>Description:</strong> {property.description}</p>
            <p><strong>Location:</strong> {`Latitude: ${property.location.coordinates[0]}, Longitude: ${property.location.coordinates[1]}`}</p>
            <p><strong>Status:</strong> {property.status}</p>
            <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
            <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>

            {/* Media Slideshow */}
            <div className="media-container">
              {currentMedia?.url && (
                <>
                  {currentMedia?.type === "video" ? (
                    <video
                      src={`https://${currentMedia.url}`}
                      controls
                      className="popup-video"
                      alt={property.city}
                      autoPlay
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        marginBottom: "10px",
                      }}
                    />
                  ) : (
                    <img
                      src={`https://${currentMedia.url}`}
                      alt={property.city}
                      className="popup-image"
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        marginBottom: "10px",
                      }}
                    />
                  )}
                </>
              )}
            </div>

            {/* Navigation buttons for the slideshow */}
            <div className="slideshow-controls" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <button className="prev-btn" onClick={showPrevMedia}>
                ←
              </button>
              <button className="next-btn" onClick={showNextMedia}>
                →
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Marker2;





// ok v9.1 add stoping map when sidebar open,
// import React, { useState } from "react";
// import FullScreenSidebar from "./FullScreenSidebar";

// const Marker2 = ({ property, mapRef, selectedPlace, setGestureHandling  }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isClicked, setIsClicked] = useState(false);
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // Function to toggle the clicked state and open sidebar
//   const handleMarkerClick = () => {
//     setIsClicked(!isClicked); // Toggle the clicked state
//     setIsSidebarOpen(true); // Open sidebar on marker click

//     setGestureHandling('none'); // Example: disable gestureHandling when marker is clicked

//   };

//   // Close the popup and sidebar when the close button is clicked
//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar

//     setGestureHandling('auto');
//     setIsHovered(false)
//   };

//   // Close the popup if overlay is clicked
//   const handleOverlayClick = () => {
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   // Show the previous media item
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Show the next media item
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === property.media.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   // Current media item being displayed (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   // Check if this marker is selected (matching selectedPlace)
//   const isSelected = selectedPlace && selectedPlace._id === property._id;

//   // Marker styles that change based on hover, click, or selection
//   const markerStyles = {
//     position: "relative",
//     color: "#000",
//     backgroundColor: isHovered || isClicked || isSelected ? "white" : "#fff",
//     padding: isHovered || isClicked || isSelected ? "10px 20px" : "10px",
//     borderRadius: isHovered || isClicked || isSelected ? "30px" : "50%",
//     textAlign: "center",
//     fontSize: "12px",
//     fontWeight: "bold",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//     transition: "all 0.3s ease",
//     display: "inline-flex",
//     alignItems: "center",
//     justifyContent: "center",
//     cursor: "pointer",
//     width: isHovered || isClicked || isSelected ? "150px" : "1px", // Expand when hovered or clicked or selected
//     height: "1px",
//     overflow: "hidden",
//     whiteSpace: "nowrap",
//     lineHeight: isHovered || isClicked || isSelected ? "normal" : "60px",
//     zIndex: isHovered || isClicked || isSelected ? 3 : 1, // Set z-index based on hover or click state
//   };

//   // Marker dot styles (changes position and size when hovered or clicked)
//   const markerDotStyles = {
//     position: "absolute",
//     width: "25px",  // Size of the circle
//     height: "25px", // Size of the circle
//     borderRadius: "50%", // Circle shape
//     top: "50%", // Center vertically within the parent container
//     left: isHovered || isClicked || isSelected ? "10%" : "50%", // Moves the dot when hovered or clicked or selected
//     transform: "translate(-50%, -50%)", // Center the dot exactly
//     zIndex: 2, // Keeps it below the hover effect and text
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   };

//   // Text on hover
//   const textOnHoverStyles = {
//     position: "absolute",
//     width: "100px",  // White circle size
//     height: "100px", // White circle size
//     backgroundColor: "white", // Color of the white part
//     borderRadius: "50%", // Circle shape
//     top: "50%", // Center the white part vertically
//     left: "50%", // Center the white part horizontally
//     transform: "translate(-50%, -50%)", // Adjust for perfect centering
//     zIndex: 4, // Put the white part above the black dot and text
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   };

//   return (
//     <div
//       style={markerStyles}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleMarkerClick} // Handle click/touch for expansion
//     >
//       {/* Marker dot */}
//       <div style={markerDotStyles}>
//         <img
//           src="/images/icons8-meal-50.png"  // Replace with your image path
//           alt="Icon"
//           style={{
//             width: "15px",   // Icon size to fit within the circle
//             height: "15px",  // Icon size to fit within the circle
//             objectFit: "contain", // Ensures the icon fits within the circle
//           }}
//         />
//       </div>

//       {/* Text on hover */}
//       {(isHovered || isSelected) && (
//         <div style={textOnHoverStyles}>
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               transform: "translateX(35px)", // Shift text within the circle
//             }}
//           >
//             {property.title}
//           </span>
//         </div>
//       )}

//       {/* Fullscreen Sidebar (popup) */}
//       {isSidebarOpen && property && (
//         <FullScreenSidebar
//           property={property}  // Pass the entire property object
//           onClose={handleClosePopup} // Pass the close handler
//           mapRef={mapRef}
//         />
//       )}

//       {/* Popup window (modal) */}
//       {isClicked && !isSidebarOpen && (
//         <div
//           className="popup-overlay"
//           style={{
//             position: "fixed",
//             top: "50%",
//             left: "25%", // Move the popup to the left
//             transform: "translate(-50%, -50%)", // Keep it centered based on its own width
//             width: "33%",  // Take up 1/3 of the screen's width
//             maxWidth: "400px", // Max width for better control
//             height: "auto", // Adjust height based on content
//             backgroundColor: "white",
//             padding: "20px",
//             borderRadius: "8px",
//             boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
//             zIndex: 9999, // Ensure the popup is on top
//             overflowY: "auto", // Scroll if content exceeds height
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "flex-start",
//             alignItems: "center",
//           }}
//           onClick={handleOverlayClick} // Close the popup when clicking outside
//         >
//           <div
//             className="popup-content"
//             onClick={(e) => e.stopPropagation()} // Prevent the popup from closing if clicking inside
//             style={{ width: "100%" }}
//           >
//             {/* Close button */}
//             <button
//               className="close-btn"
//               onClick={handleClosePopup}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 backgroundColor: "#ff5c5c",
//                 color: "white",
//                 border: "none",
//                 padding: "10px",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 fontSize: "18px",
//               }}
//             >
//               X
//             </button>

//             <h2>{property.title}</h2>
//             <p><strong>Description:</strong> {property.description}</p>
//             <p><strong>Location:</strong> {`Latitude: ${property.location.coordinates[0]}, Longitude: ${property.location.coordinates[1]}`}</p>
//             <p><strong>Status:</strong> {property.status}</p>
//             <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//             <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>

//             {/* Media Slideshow */}
//             <div className="media-container">
//               {currentMedia?.url && (
//                 <>
//                   {currentMedia?.type === "video" ? (
//                     <video
//                       src={`https://${currentMedia.url}`}
//                       controls
//                       className="popup-video"
//                       alt={property.city}
//                       autoPlay
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={`https://${currentMedia.url}`}
//                       alt={property.city}
//                       className="popup-image"
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   )}
//                 </>
//               )}
//             </div>

//             {/* Navigation buttons for the slideshow */}
//             <div className="slideshow-controls" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//               <button className="prev-btn" onClick={showPrevMedia}>
//                 ←
//               </button>
//               <button className="next-btn" onClick={showNextMedia}>
//                 →
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Marker2;





// ok v9  link Marker and Cart
// import React, { useState } from "react";
// import FullScreenSidebar from "./FullScreenSidebar";

// const Marker2 = ({ property, mapRef, selectedPlace }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isClicked, setIsClicked] = useState(false);
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // Function to toggle the clicked state and open sidebar
//   const handleMarkerClick = () => {
//     setIsClicked(!isClicked); // Toggle the clicked state
//     setIsSidebarOpen(true); // Open sidebar on marker click
//   };

//   // Close the popup and sidebar when the close button is clicked
//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   // Close the popup if overlay is clicked
//   const handleOverlayClick = () => {
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   // Show the previous media item
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Show the next media item
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === property.media.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   // Current media item being displayed (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   // Check if this marker is selected (matching selectedPlace)
//   const isSelected = selectedPlace && selectedPlace._id === property._id;

//   // Marker styles that change based on hover, click, or selection
//   const markerStyles = {
//     position: "relative",
//     color: "#000",
//     backgroundColor: isHovered || isClicked || isSelected ? "white" : "#fff",
//     padding: isHovered || isClicked || isSelected ? "10px 20px" : "10px",
//     borderRadius: isHovered || isClicked || isSelected ? "30px" : "50%",
//     textAlign: "center",
//     fontSize: "12px",
//     fontWeight: "bold",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//     transition: "all 0.3s ease",
//     display: "inline-flex",
//     alignItems: "center",
//     justifyContent: "center",
//     cursor: "pointer",
//     width: isHovered || isClicked || isSelected ? "150px" : "1px", // Expand when hovered or clicked or selected
//     height: "1px",
//     overflow: "hidden",
//     whiteSpace: "nowrap",
//     lineHeight: isHovered || isClicked || isSelected ? "normal" : "60px",
//     zIndex: isHovered || isClicked || isSelected ? 3 : 1, // Set z-index based on hover or click state
//   };

//   // Marker dot styles (changes position and size when hovered or clicked)
//   const markerDotStyles = {
//     position: "absolute",
//     width: "25px",  // Size of the circle
//     height: "25px", // Size of the circle
//     borderRadius: "50%", // Circle shape
//     top: "50%", // Center vertically within the parent container
//     left: isHovered || isClicked || isSelected ? "10%" : "50%", // Moves the dot when hovered or clicked or selected
//     transform: "translate(-50%, -50%)", // Center the dot exactly
//     zIndex: 2, // Keeps it below the hover effect and text
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   };

//   // Text on hover
//   const textOnHoverStyles = {
//     position: "absolute",
//     width: "100px",  // White circle size
//     height: "100px", // White circle size
//     backgroundColor: "white", // Color of the white part
//     borderRadius: "50%", // Circle shape
//     top: "50%", // Center the white part vertically
//     left: "50%", // Center the white part horizontally
//     transform: "translate(-50%, -50%)", // Adjust for perfect centering
//     zIndex: 4, // Put the white part above the black dot and text
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   };

//   return (
//     <div
//       style={markerStyles}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleMarkerClick} // Handle click/touch for expansion
//     >
//       {/* Marker dot */}
//       <div style={markerDotStyles}>
//         <img
//           src="/images/icons8-meal-50.png"  // Replace with your image path
//           alt="Icon"
//           style={{
//             width: "15px",   // Icon size to fit within the circle
//             height: "15px",  // Icon size to fit within the circle
//             objectFit: "contain", // Ensures the icon fits within the circle
//           }}
//         />
//       </div>

//       {/* Text on hover */}
//       {(isHovered || isSelected) && (
//         <div style={textOnHoverStyles}>
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               transform: "translateX(35px)", // Shift text within the circle
//             }}
//           >
//             {property.title}
//           </span>
//         </div>
//       )}

//       {/* Fullscreen Sidebar (popup) */}
//       {isSidebarOpen && property && (
//         <FullScreenSidebar
//           property={property}  // Pass the entire property object
//           onClose={handleClosePopup} // Pass the close handler
//           mapRef={mapRef}
//         />
//       )}

//       {/* Popup window (modal) */}
//       {isClicked && !isSidebarOpen && (
//         <div
//           className="popup-overlay"
//           style={{
//             position: "fixed",
//             top: "50%",
//             left: "25%", // Move the popup to the left
//             transform: "translate(-50%, -50%)", // Keep it centered based on its own width
//             width: "33%",  // Take up 1/3 of the screen's width
//             maxWidth: "400px", // Max width for better control
//             height: "auto", // Adjust height based on content
//             backgroundColor: "white",
//             padding: "20px",
//             borderRadius: "8px",
//             boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
//             zIndex: 9999, // Ensure the popup is on top
//             overflowY: "auto", // Scroll if content exceeds height
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "flex-start",
//             alignItems: "center",
//           }}
//           onClick={handleOverlayClick} // Close the popup when clicking outside
//         >
//           <div
//             className="popup-content"
//             onClick={(e) => e.stopPropagation()} // Prevent the popup from closing if clicking inside
//             style={{ width: "100%" }}
//           >
//             {/* Close button */}
//             <button
//               className="close-btn"
//               onClick={handleClosePopup}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 backgroundColor: "#ff5c5c",
//                 color: "white",
//                 border: "none",
//                 padding: "10px",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 fontSize: "18px",
//               }}
//             >
//               X
//             </button>

//             <h2>{property.title}</h2>
//             <p><strong>Description:</strong> {property.description}</p>
//             <p><strong>Location:</strong> {`Latitude: ${property.location.coordinates[0]}, Longitude: ${property.location.coordinates[1]}`}</p>
//             <p><strong>Status:</strong> {property.status}</p>
//             <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//             <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>

//             {/* Media Slideshow */}
//             <div className="media-container">
//               {currentMedia?.url && (
//                 <>
//                   {currentMedia?.type === "video" ? (
//                     <video
//                       src={`https://${currentMedia.url}`}
//                       controls
//                       className="popup-video"
//                       alt={property.city}
//                       autoPlay
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={`https://${currentMedia.url}`}
//                       alt={property.city}
//                       className="popup-image"
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   )}
//                 </>
//               )}
//             </div>

//             {/* Navigation buttons for the slideshow */}
//             <div className="slideshow-controls" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//               <button className="prev-btn" onClick={showPrevMedia}>
//                 ←
//               </button>
//               <button className="next-btn" onClick={showNextMedia}>
//                 →
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Marker2;






// ok v8 clone v7 fix color and phone css
// import React, { useState } from "react";
// import FullScreenSidebar from "./FullScreenSidebar";

// const Marker2 = ({ property }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isClicked, setIsClicked] = useState(false);
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // Function to toggle the clicked state and open sidebar
//   const handleMarkerClick = () => {
//     setIsClicked(!isClicked); // Toggle the clicked state
//     setIsSidebarOpen(true); // Open sidebar on marker click
//   };

//   // Close the popup and sidebar when the close button is clicked
//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   // Close the popup if overlay is clicked
//   const handleOverlayClick = () => {
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   // Show the previous media item
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Show the next media item
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === property.media.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   // Current media item being displayed (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div
//       style={{
//         position: "relative",
//         color: "#000",
//         backgroundColor: isHovered || isClicked ? "white" : "#fff",
//         // backgroundColor: isHovered || isClicked ? "rgba(255, 255, 255, 0.85)" : "rgba(255, 255, 255, 0.95)",
//         padding: isHovered || isClicked ? "10px 20px" : "10px",
//         borderRadius: isHovered || isClicked ? "30px" : "50%",
//         textAlign: "center",
//         fontSize: "12px",
//         fontWeight: "bold",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//         transition: "all 0.3s ease",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//         width: isHovered || isClicked ? "150px" : "1px",
//         height: "1px",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//         lineHeight: isHovered || isClicked ? "normal" : "60px",
//         zIndex: isHovered || isClicked ? 3 : 1, // Set z-index based on hover or click state
//       }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleMarkerClick} // Handle click/touch for expansion
//     >
//       {/* Marker dot */}
//       <div
//         style={{
//           position: "absolute",
//           width: "25px",  // Size of the circle
//           height: "25px", // Size of the circle
//           borderRadius: "50%", // Circle shape
//           top: "50%", // Center vertically within the parent container
//           left: isHovered || isClicked ? "10%" : "50%", // Moves the dot when hovered or clicked
//           transform: "translate(-50%, -50%)", // Center the dot exactly
//           zIndex: 2, // Keeps it below the hover effect and text
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <img
//           src="/images/icons8-meal-50.png"  // Replace with your image path
//           alt="Icon"
//           style={{
//             width: "15px",   // Icon size to fit within the circle
//             height: "15px",  // Icon size to fit within the circle
//             objectFit: "contain", // Ensures the icon fits within the circle
//           }}
//         />
//       </div>

//       {/* Text on hover */}
//       {isHovered && (
//         <div
//           style={{
//             position: "absolute",
//             width: "100px",  // White circle size
//             height: "100px", // White circle size
//             backgroundColor: "white", // Color of the white part
//             borderRadius: "50%", // Circle shape
//             top: "50%", // Center the white part vertically
//             left: "50%", // Center the white part horizontally
//             transform: "translate(-50%, -50%)", // Adjust for perfect centering
//             zIndex: 4, // Put the white part above the black dot and text
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               transform: "translateX(35px)", // Shift text within the circle
//             }}
//           >
//             {property.title}
//           </span>
//         </div>
//       )}
// {/* Fullscreen Sidebar (popup) */}
// {isSidebarOpen && property && (
//   <FullScreenSidebar
//     property={property}  // Pass the entire property object
//     onClose={handleClosePopup} // Pass the close handler
//   />
// )}

//             {/* Popup window (modal) */}
//             {isClicked && !isSidebarOpen && (
//               <div
//                 className="popup-overlay"
//                 style={{
//                   position: "fixed",
//                   top: "50%",
//                   left: "25%", // Move the popup to the left
//                   transform: "translate(-50%, -50%)", // Keep it centered based on its own width
//                   width: "33%",  // Take up 1/3 of the screen's width
//                   maxWidth: "400px", // Max width for better control
//                   height: "auto", // Adjust height based on content
//                   backgroundColor: "white",
//                   padding: "20px",
//                   borderRadius: "8px",
//                   boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
//                   zIndex: 9999, // Ensure the popup is on top
//                   overflowY: "auto", // Scroll if content exceeds height
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "flex-start",
//                   alignItems: "center",
//                 }}
//                 onClick={handleOverlayClick} // Close the popup when clicking outside
//               >


//           <div
//             className="popup-content"
//             onClick={(e) => e.stopPropagation()} // Prevent the popup from closing if clicking inside
//             style={{ width: "100%" }}
//           >
//             {/* Close button */}
//             <button
//               className="close-btn"
//               onClick={handleClosePopup}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 backgroundColor: "#ff5c5c",
//                 color: "white",
//                 border: "none",
//                 padding: "10px",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 fontSize: "18px",
//               }}
//             >
//               X
//             </button>

//             <h2>{property.title}</h2>
//             <p><strong>Description:</strong> {property.description}</p>
//             <p><strong>Location:</strong> {`Latitude: ${property.location.coordinates[0]}, Longitude: ${property.location.coordinates[1]}`}</p>
//             <p><strong>Status:</strong> {property.status}</p>
//             <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//             <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>

//             {/* Media Slideshow */}
//             <div className="media-container">
//               {currentMedia?.url && (
//                 <>
//                   {currentMedia?.type === "video" ? (
//                     <video
//                       src={`https://${currentMedia.url}`}
//                       controls
//                       className="popup-video"
//                       alt={property.city}
//                       autoPlay
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={`https://${currentMedia.url}`}
//                       alt={property.city}
//                       className="popup-image"
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   )}
//                 </>
//               )}
//             </div>

//             {/* Navigation buttons for the slideshow */}
//             <div className="slideshow-controls" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//               <button className="prev-btn" onClick={showPrevMedia}>
//                 ←
//               </button>
//               <button className="next-btn" onClick={showNextMedia}>
//                 →
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Marker2;




// ok v7 maker it look better, and popup at 1/3 at the center ofthe
// import React, { useState } from "react";
// import FullScreenSidebar from "./FullScreenSidebar";

// const Marker2 = ({ property }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isClicked, setIsClicked] = useState(false);
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   // Function to toggle the clicked state and open sidebar
//   const handleMarkerClick = () => {
//     setIsClicked(!isClicked); // Toggle the clicked state
//     setIsSidebarOpen(true); // Open sidebar on marker click
//   };

//   // Close the popup and sidebar when the close button is clicked
//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   // Close the popup if overlay is clicked
//   const handleOverlayClick = () => {
//     setIsClicked(false); // Close the popup
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   // Show the previous media item
//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   // Show the next media item
//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === property.media.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   // Current media item being displayed (image or video)
//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div
//       style={{
//         position: "relative",
//         color: "#000",
//         backgroundColor: isHovered || isClicked ? "white" : "#fff",
//         // backgroundColor: isHovered || isClicked ? "rgba(255, 255, 255, 0.85)" : "rgba(255, 255, 255, 0.95)",
//         padding: isHovered || isClicked ? "10px 20px" : "10px",
//         borderRadius: isHovered || isClicked ? "30px" : "50%",
//         textAlign: "center",
//         fontSize: "12px",
//         fontWeight: "bold",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//         transition: "all 0.3s ease",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//         width: isHovered || isClicked ? "150px" : "1px",
//         height: "1px",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//         lineHeight: isHovered || isClicked ? "normal" : "60px",
//         zIndex: isHovered || isClicked ? 3 : 1, // Set z-index based on hover or click state
//       }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleMarkerClick} // Handle click/touch for expansion
//     >
//       {/* Marker dot */}
//       <div
//         style={{
//           position: "absolute",
//           width: "25px",  // Size of the circle
//           height: "25px", // Size of the circle
//           borderRadius: "50%", // Circle shape
//           top: "50%", // Center vertically within the parent container
//           left: isHovered || isClicked ? "10%" : "50%", // Moves the dot when hovered or clicked
//           transform: "translate(-50%, -50%)", // Center the dot exactly
//           zIndex: 2, // Keeps it below the hover effect and text
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <img
//           src="/images/icons8-meal-50.png"  // Replace with your image path
//           alt="Icon"
//           style={{
//             width: "15px",   // Icon size to fit within the circle
//             height: "15px",  // Icon size to fit within the circle
//             objectFit: "contain", // Ensures the icon fits within the circle
//           }}
//         />
//       </div>

//       {/* Text on hover */}
//       {isHovered && (
//         <div
//           style={{
//             position: "absolute",
//             width: "100px",  // White circle size
//             height: "100px", // White circle size
//             backgroundColor: "white", // Color of the white part
//             borderRadius: "50%", // Circle shape
//             top: "50%", // Center the white part vertically
//             left: "50%", // Center the white part horizontally
//             transform: "translate(-50%, -50%)", // Adjust for perfect centering
//             zIndex: 4, // Put the white part above the black dot and text
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               transform: "translateX(35px)", // Shift text within the circle
//             }}
//           >
//             {property.title}
//           </span>
//         </div>
//       )}
// {/* Fullscreen Sidebar (popup) */}
// {isSidebarOpen && property && (
//   <FullScreenSidebar
//     property={property}  // Pass the entire property object
//     onClose={handleClosePopup} // Pass the close handler
//   />
// )}

//             {/* Popup window (modal) */}
//             {isClicked && !isSidebarOpen && (
//               <div
//                 className="popup-overlay"
//                 style={{
//                   position: "fixed",
//                   top: "50%",
//                   left: "25%", // Move the popup to the left
//                   transform: "translate(-50%, -50%)", // Keep it centered based on its own width
//                   width: "33%",  // Take up 1/3 of the screen's width
//                   maxWidth: "400px", // Max width for better control
//                   height: "auto", // Adjust height based on content
//                   backgroundColor: "white",
//                   padding: "20px",
//                   borderRadius: "8px",
//                   boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
//                   zIndex: 9999, // Ensure the popup is on top
//                   overflowY: "auto", // Scroll if content exceeds height
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "flex-start",
//                   alignItems: "center",
//                 }}
//                 onClick={handleOverlayClick} // Close the popup when clicking outside
//               >


//           <div
//             className="popup-content"
//             onClick={(e) => e.stopPropagation()} // Prevent the popup from closing if clicking inside
//             style={{ width: "100%" }}
//           >
//             {/* Close button */}
//             <button
//               className="close-btn"
//               onClick={handleClosePopup}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 backgroundColor: "#ff5c5c",
//                 color: "white",
//                 border: "none",
//                 padding: "10px",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 fontSize: "18px",
//               }}
//             >
//               X
//             </button>

//             <h2>{property.title}</h2>
//             <p><strong>Description:</strong> {property.description}</p>
//             <p><strong>Location:</strong> {`Latitude: ${property.location.coordinates[0]}, Longitude: ${property.location.coordinates[1]}`}</p>
//             <p><strong>Status:</strong> {property.status}</p>
//             <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//             <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>

//             {/* Media Slideshow */}
//             <div className="media-container">
//               {currentMedia?.url && (
//                 <>
//                   {currentMedia?.type === "video" ? (
//                     <video
//                       src={`https://${currentMedia.url}`}
//                       controls
//                       className="popup-video"
//                       alt={property.city}
//                       autoPlay
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={`https://${currentMedia.url}`}
//                       alt={property.city}
//                       className="popup-image"
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   )}
//                 </>
//               )}
//             </div>

//             {/* Navigation buttons for the slideshow */}
//             <div className="slideshow-controls" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//               <button className="prev-btn" onClick={showPrevMedia}>
//                 ←
//               </button>
//               <button className="next-btn" onClick={showNextMedia}>
//                 →
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Marker2;





// ok v6 fix the click through the popup to the map
// import React, { useState } from "react";

// const Marker2 = ({ property }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isClicked, setIsClicked] = useState(false);
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

//   const handleMarkerClick = () => {
//     setIsClicked(!isClicked); // Toggle the clicked state
//   };

//   const handleClosePopup = (e) => {
//     e.stopPropagation(); // Prevent click from propagating to the overlay
//     setIsClicked(false); // Close the popup
//   };

//   const handleOverlayClick = () => {
//     setIsClicked(false); // Close the popup if overlay is clicked
//   };

//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === property.media.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div
//       style={{
//         position: "relative",
//         color: "#000",
//         backgroundColor: isHovered || isClicked ? "white" : "#fff",
//         padding: isHovered || isClicked ? "10px 20px" : "10px",
//         borderRadius: isHovered || isClicked ? "30px" : "50%",
//         textAlign: "center",
//         fontSize: "12px",
//         fontWeight: "bold",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//         transition: "all 0.3s ease",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//         width: isHovered || isClicked ? "150px" : "1px",
//         height: "1px",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//         lineHeight: isHovered || isClicked ? "normal" : "60px",
//         zIndex: isHovered || isClicked ? 3 : 1, // Set z-index based on hover or click state
//       }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleMarkerClick} // Handle click/touch for expansion
//     >
//       {/* Marker dot */}
//       <div
//         style={{
//           position: "absolute",
//           width: "25px",  // Size of the circle
//           height: "25px", // Size of the circle
//           borderRadius: "50%", // Circle shape
//           top: "50%", // Center vertically within the parent container
//           left: isHovered || isClicked ? "10%" : "50%", // Moves the dot when hovered or clicked
//           transform: "translate(-50%, -50%)", // Center the dot exactly
//           zIndex: 2, // Keeps it below the hover effect and text
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <img
//           src="/images/icons8-meal-50.png"  // Replace with your image path
//           alt="Icon"
//           style={{
//             width: "15px",   // Icon size to fit within the circle
//             height: "15px",  // Icon size to fit within the circle
//             objectFit: "contain", // Ensures the icon fits within the circle
//           }}
//         />
//       </div>

//       {/* Text on hover */}
//       {(isHovered) && (
//         <div
//           style={{
//             position: "absolute",
//             width: "100px",  // White circle size
//             height: "100px", // White circle size
//             backgroundColor: "white", // Color of the white part
//             borderRadius: "50%", // Circle shape
//             top: "50%", // Center the white part vertically
//             left: "50%", // Center the white part horizontally
//             transform: "translate(-50%, -50%)", // Adjust for perfect centering
//             zIndex: 4, // Put the white part above the black dot and text
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               transform: "translateX(35px)", // Shift text within the circle
//             }}
//           >
//             {property.title}
//           </span>
//         </div>
//       )}

//       {/* Popup window (modal) */}
//       {isClicked && (
//         <div
//           className="popup-overlay"
//           style={{
//             position: "fixed",
//             top: "50%",
//             left: "25%", // Move the popup to the left
//             transform: "translate(-50%, -50%)", // Keep it centered based on its own width
//             width: "33%",  // Take up 1/3 of the screen's width
//             maxWidth: "400px", // Max width for better control
//             height: "auto", // Adjust height based on content
//             backgroundColor: "white",
//             padding: "20px",
//             borderRadius: "8px",
//             boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
//             zIndex: 9999, // Ensure the popup is on top
//             overflowY: "auto", // Scroll if content exceeds height
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "flex-start",
//             alignItems: "center",
//           }}
//           onClick={handleOverlayClick} // Close the popup when clicking outside
//         >
//           <div
//             className="popup-content"
//             onClick={(e) => e.stopPropagation()} // Prevent the popup from closing if clicking inside
//             style={{ width: "100%" }}
//           >
//             {/* Close button */}
//             <button
//               className="close-btn"
//               onClick={handleClosePopup}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 backgroundColor: "#ff5c5c",
//                 color: "white",
//                 border: "none",
//                 padding: "10px",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 fontSize: "18px",
//               }}
//             >
//               X
//             </button>

//             <h2>{property.title}</h2>
//             <p><strong>Description:</strong> {property.description}</p>
//             <p><strong>Location:</strong> {`Latitude: ${property.location.coordinates[0]}, Longitude: ${property.location.coordinates[1]}`}</p>
//             <p><strong>Status:</strong> {property.status}</p>
//             <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//             <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>

//             {/* Media Slideshow */}
//             <div className="media-container">
//               {currentMedia?.url && (
//                 <>
//                   {currentMedia?.type === "video" ? (
//                     <video
//                       src={`https://${currentMedia.url}`}
//                       controls
//                       className="popup-video"
//                       alt={property.city}
//                       autoPlay
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={`https://${currentMedia.url}`}
//                       alt={property.city}
//                       className="popup-image"
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   )}
//                 </>
//               )}
//             </div>

//             {/* Navigation buttons for the slideshow */}
//             <div className="slideshow-controls" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//               <button className="prev-btn" onClick={showPrevMedia}>
//                 ←
//               </button>
//               <button className="next-btn" onClick={showNextMedia}>
//                 →
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Marker2;




// ok v5 add pop up
// import React, { useState } from "react";

// const Marker2 = ({ property }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isClicked, setIsClicked] = useState(false);
//   const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

//   const handleMarkerClick = () => {
//     setIsClicked(!isClicked); // Toggle the clicked state
//   };

//   const handleClosePopup = () => {
//     setIsClicked(false); // Close the popup by setting state to false
//   };

//   const handleOverlayClick = () => {
//     setIsClicked(false); // Close the popup if overlay is clicked
//   };

//   const showPrevMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === 0 ? property.media.length - 1 : prevIndex - 1
//     );
//   };

//   const showNextMedia = () => {
//     setCurrentMediaIndex((prevIndex) =>
//       prevIndex === property.media.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const currentMedia = property.media[currentMediaIndex];

//   return (
//     <div
//       style={{
//         position: "relative",
//         color: "#000",
//         backgroundColor: isHovered || isClicked ? "white" : "#fff",
//         padding: isHovered || isClicked ? "10px 20px" : "10px",
//         borderRadius: isHovered || isClicked ? "30px" : "50%",
//         textAlign: "center",
//         fontSize: "12px",
//         fontWeight: "bold",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//         transition: "all 0.3s ease",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//         width: isHovered || isClicked ? "150px" : "1px",
//         height: "1px",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//         lineHeight: isHovered || isClicked ? "normal" : "60px",
//         zIndex: isHovered || isClicked ? 3 : 1, // Set z-index based on hover or click state
//       }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleMarkerClick} // Handle click/touch for expansion
//     >
//       {/* Marker dot */}
//       <div
//         style={{
//           position: "absolute",
//           width: "25px",  // Size of the circle
//           height: "25px", // Size of the circle
//           borderRadius: "50%", // Circle shape
//           top: "50%", // Center vertically within the parent container
//           left: isHovered || isClicked ? "10%" : "50%", // Moves the dot when hovered or clicked
//           transform: "translate(-50%, -50%)", // Center the dot exactly
//           zIndex: 2, // Keeps it below the hover effect and text
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <img
//           src="/images/icons8-meal-50.png"  // Replace with your image path
//           alt="Icon"
//           style={{
//             width: "15px",   // Icon size to fit within the circle
//             height: "15px",  // Icon size to fit within the circle
//             objectFit: "contain", // Ensures the icon fits within the circle
//           }}
//         />
//       </div>

//       {/* Text on hover */}
//       {/* {isHovered && (
//         <div
//           style={{
//             position: "absolute",
//             top: "100%",
//             left: "50%",
//             transform: "translateX(-50%)",
//             backgroundColor: "white",
//             padding: "5px 10px",
//             borderRadius: "5px",
//             boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
//             fontSize: "12px",
//             fontWeight: "bold",
//             zIndex: 3,
//             whiteSpace: "nowrap",
//             width: "auto",
//             maxWidth: "120px",
//             textAlign: "center",
//           }}
//         >
//            <span
//              style={{
//                fontSize: "14px",
//                fontWeight: "bold",
//                color: "#000",
//                textAlign: "center",
//                transform: "translateX(35px)",
//              }}
//            >
//              {property.title}
//            </span>


//         </div>
//       )} */}
//       {(isHovered) && (
//         <div
//           style={{
//             position: "absolute",
//             width: "100px",  // White circle size
//             height: "100px", // White circle size
//             backgroundColor: "white", // Color of the white part
//             borderRadius: "50%", // Circle shape
//             top: "50%", // Center the white part vertically
//             left: "50%", // Center the white part horizontally
//             transform: "translate(-50%, -50%)", // Adjust for perfect centering
//             zIndex: 4, // Put the white part above the black dot and text
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               transform: "translateX(35px)", // Shift text within the circle
//             }}
//           >
//             {property.title}
//           </span>
//         </div>
//       )}


//       {/* Popup window (modal) */}
//       {isClicked && (
//         <div
//           className="popup-overlay"
//           style={{
//             position: "fixed",
//             top: "50%",
//             left: "25%", // Move the popup to the left
//             transform: "translate(-50%, -50%)", // Keep it centered based on its own width
//             width: "33%",  // Take up 1/3 of the screen's width
//             maxWidth: "400px", // Max width for better control
//             height: "auto", // Adjust height based on content
//             backgroundColor: "white",
//             padding: "20px",
//             borderRadius: "8px",
//             boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
//             zIndex: 9999, // Ensure the popup is on top
//             overflowY: "auto", // Scroll if content exceeds height
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "flex-start",
//             alignItems: "center",
//           }}
//           onClick={handleOverlayClick} // Close the popup when clicking outside
//         >
//           <div
//             className="popup-content"
//             onClick={(e) => e.stopPropagation()} // Prevent the popup from closing if clicking inside
//             style={{ width: "100%" }}
//           >
//             {/* Close button */}
//             <button
//               className="close-btn"
//               onClick={handleClosePopup}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 backgroundColor: "#ff5c5c",
//                 color: "white",
//                 border: "none",
//                 padding: "10px",
//                 borderRadius: "50%",
//                 cursor: "pointer",
//                 fontSize: "18px",
//               }}
//             >
//               X
//             </button>

//             <h2>{property.title}</h2>
//             <p><strong>Description:</strong> {property.description}</p>
//             <p><strong>Location:</strong> {`Latitude: ${property.location.coordinates[0]}, Longitude: ${property.location.coordinates[1]}`}</p>
//             <p><strong>Status:</strong> {property.status}</p>
//             <p><strong>Approved:</strong> {property.approved ? "Yes" : "No"}</p>
//             <p><strong>Created At:</strong> {new Date(property.createdAt).toLocaleDateString()}</p>

//             {/* Media Slideshow */}
//             <div className="media-container">
//               {currentMedia?.url && (
//                 <>
//                   {currentMedia?.type === "video" ? (
//                     <video
//                       src={`https://${currentMedia.url}`}
//                       controls
//                       className="popup-video"
//                       alt={property.city}
//                       autoPlay
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={`https://${currentMedia.url}`}
//                       alt={property.city}
//                       className="popup-image"
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginBottom: "10px",
//                       }}
//                     />
//                   )}
//                 </>
//               )}
//             </div>

//             {/* Navigation buttons for the slideshow */}
//             <div className="slideshow-controls" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
//               <button className="prev-btn" onClick={showPrevMedia}>
//                 ←
//               </button>
//               <button className="next-btn" onClick={showNextMedia}>
//                 →
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Marker2;




// // (ok add img to marker) v4 try to add icon
// import React, { useState } from "react";

// const Marker2 = ({ text }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [isClicked, setIsClicked] = useState(false);

//   const handleMarkerClick = () => {
//     setIsClicked(!isClicked); // Toggle the clicked state
//   };

//   return (
//     <div
//       style={{
//         position: "relative",
//         color: "#000",
//         backgroundColor: isHovered || isClicked ? "white" : "#fff",
//         padding: isHovered || isClicked ? "10px 20px" : "10px",
//         borderRadius: isHovered || isClicked ? "30px" : "50%",
//         textAlign: "center",
//         fontSize: "12px",
//         fontWeight: "bold",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//         transition: "all 0.3s ease",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//         width: isHovered || isClicked ? "150px" : "1px",
//         height: "1px",
//         // minWidth: "15px",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//         lineHeight: isHovered || isClicked ? "normal" : "60px",
//         zIndex: isHovered || isClicked ? 3 : 1, // Set z-index based on hover or click state
//       }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleMarkerClick} // Handle click/touch for expansion
//     >
//       {/*
//         Black dot for the marker, positioned in the center of the marker container.
//         The size of the dot is controlled by the width and height properties.
//         This dot will expand and change positioning based on hover or click states.
//       */}
//         <div
//           style={{
//             position: "absolute",
//             width: "25px",  // Size of the circle
//             height: "25px", // Size of the circle
//             borderRadius: "50%", // Circle shape
//             top: "50%", // Center vertically within the parent container
//             left: isHovered || isClicked ? "10%" : "50%", // Moves the dot when hovered or clicked
//             transform: "translate(-50%, -50%)", // Center the dot exactly
//             zIndex: 2, // Keeps it below the hover effect and text
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           {/* Replacing the black dot with an image */}
//           <img
//             // src="https://img.icons8.com/ios-filled/50/000000/restaurant.png"  // Replace with your image path
//             src="/images/icons8-meal-50.png"  // Replace with your image path
//             // **ALL icon here https://icons8.com/icon/set/cafe/metro, download em and put em in public/images, might use AI to gen black circle with white icon inside for coffee, bakery, etc
//             alt="Icon"
//             style={{
//               width: "15px",   // Icon size to fit within the circle
//               height: "15px",  // Icon size to fit within the circle
//               objectFit: "contain", // Ensures the icon fits within the circle
//             }}
//           />


//         {/* T-shirt image (commented out) */}
//         {/* Uncomment to use an image */}
//         {/* <img
//           src="t-shirt.png" // Path to your T-shirt image
//           alt="T-shirt"
//           style={{
//             width: "10px",
//             height: "auto",
//             filter: "invert(1)", // Optionally invert the color if the dot is black
//           }}
//         /> */}
//       </div>

//       {/* White part on hover (or click) */}
//       {(isHovered || isClicked) && (
//         <div
//           style={{
//             position: "absolute",
//             width: "100px",  // White circle size
//             height: "100px", // White circle size
//             backgroundColor: "white", // Color of the white part
//             borderRadius: "50%", // Circle shape
//             top: "50%", // Center the white part vertically
//             left: "50%", // Center the white part horizontally
//             transform: "translate(-50%, -50%)", // Adjust for perfect centering
//             zIndex: 4, // Put the white part above the black dot and text
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               transform: "translateX(35px)", // Shift text within the circle
//             }}
//           >
//             {text}
//           </span>
//         </div>
//       )}

//       {/* Pointy part (Triangle) */}
//       <div
//         style={{
//           position: "absolute",
//           width: "0",
//           height: "0",
//           borderLeft: "15px solid transparent", // Create triangle's left side
//           borderRight: "15px solid transparent", // Create triangle's right side
//           borderTop: "20px solid red", // Triangle's point (color for debugging)
//           top: "100%", // Position triangle at the bottom of the marker
//           left: "50%", // Center the triangle horizontally
//           transform: "translateX(-50%)", // Adjust position to center
//           zIndex: 3, // Place below the white part but above the black dot
//         }}
//       />
//     </div>
//   );
// };

// export default Marker2;



// //  (failed) v3 fix marker potition: continue to fix this later
// import React, { useState } from 'react';

// const Marker2 = ({ text }) => {
//   const [isHovered, setIsHovered] = useState(false);

//   return (
//     <div
//       style={{
//         position: "relative",
//         color: "#000",
//         backgroundColor: isHovered ? "white" : "#fff",
//         padding: isHovered ? "10px 20px" : "10px",
//         borderRadius: isHovered ? "30px" : "50%",
//         textAlign: "center",
//         fontSize: "12px",
//         fontWeight: "bold",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//         transition: "all 0.3s ease",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//         width: isHovered ? "150px" : "15px",
//         height: "15px",
//         minWidth: "15px",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//         lineHeight: isHovered ? "normal" : "60px",
//         borderRadius: isHovered ? "80px" : "50%",
//         zIndex: isHovered ? 3 : 1, // Set z-index based on hover state
//       }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {/* T-shirt in black circle */}
//       <div
//         style={{
//           position: "absolute",
//           width: "25px",
//           height: "25px",
//           backgroundColor: "black",
//           borderRadius: "50%",
//           top: "50%",
//           left: isHovered ? "10%" : "50%",
//           transform: "translate(-50%, -50%)",
//           zIndex: 2, // Keep it under the hover effect
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         {/* T-shirt image */}
//         {/* <img
//           src="t-shirt.png" // Path to your T-shirt image
//           alt="T-shirt"
//           style={{
//             width: "10px",
//             height: "auto",
//             filter: "invert(1)",
//           }}
//         /> */}
//       </div>

//       {/* White part on hover */}
//       {isHovered && (
//         <div
//           style={{
//             position: "absolute",
//             width: "100px",
//             height: "100px",
//             backgroundColor: "white",
//             borderRadius: "50%",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             zIndex: 4, // Highest z-index for the hovered component
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               transform: "translateX(35px)",
//             }}
//           >
//             {text}
//           </span>
//         </div>
//       )}
//       {/* Pointy part */}
//       <div
//         style={{
//           position: "absolute",
//           width: "0",
//           height: "0",
//           borderLeft: "15px solid transparent",
//           borderRight: "15px solid transparent",
//           borderTop: "20px solid red", // Change color for debugging
//           top: "100%",
//           left: "50%",
//           transform: "translateX(-50%)",
//           zIndex: 3, // Keep it below the white part
//         }}
//       />
//     </div>
//   );
// };
// export default Marker2;





// // v2 (phone OK, pointy not OK) fix this pointy part, phone hover
// import React, { useState } from 'react';

// const Marker2 = ({ text }) => {
//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => setIsHovered(true);
//   const handleMouseLeave = () => setIsHovered(false);

//   const handleTouchStart = (e) => {
//     e.preventDefault();
//     setIsHovered(true);
//   };

//   const handleTouchEnd = (e) => {
//     e.preventDefault();
//     setIsHovered(false);
//   };

//   return (
//     <div
//       style={{
//         position: "relative",
//         color: "#000",
//         backgroundColor: isHovered ? "white" : "#fff",
//         padding: isHovered ? "10px 20px" : "10px",
//         borderRadius: isHovered ? "30px" : "50%",
//         textAlign: "center",
//         fontSize: "12px",
//         fontWeight: "bold",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//         transition: "all 0.3s ease",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer",
//         width: isHovered ? "150px" : "15px",
//         height: "15px",
//         minWidth: "15px",
//         overflow: "hidden",
//         whiteSpace: "nowrap",
//         lineHeight: isHovered ? "normal" : "60px",
//         borderRadius: isHovered ? "80px" : "50%",
//       }}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//       onTouchStart={handleTouchStart}
//       onTouchEnd={handleTouchEnd}
//     >
//       {/* Black circle */}
//       <div
//         style={{
//           position: "absolute",
//           width: "25px",
//           height: "25px",
//           backgroundColor: "black",
//           borderRadius: "50%",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           zIndex: 1,
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         {/* T-shirt image */}
//         {/* <img
//           src="t-shirt.png"
//           alt="T-shirt"
//           style={{
//             width: "10px",
//             height: "auto",
//             filter: "invert(1)",
//           }}
//         /> */}
//       </div>

//       {/* White part on hover */}
//       {isHovered && (
//         <div
//           style={{
//             position: "absolute",
//             width: "100px",
//             height: "100px",
//             backgroundColor: "white",
//             borderRadius: "50%",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             zIndex: 2, // Ensure this is above the black circle
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <span
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               zIndex: 3, // Ensure text is on top
//               transform: "translateX(35px)", // Shift text to the left
//             }}
//           >
//             {text}
//           </span>
//         </div>
//       )}

//       {/* Pointy part */}
//       <div
//         style={{
//           position: "absolute",
//           width: "0",
//           height: "0",
//           borderLeft: "15px solid transparent",
//           borderRight: "15px solid transparent",
//           borderTop: "20px solid red",
//           top: "100%",
//           left: "50%",
//           transform: "translateX(-50%)",
//           zIndex: 0, // Ensure this is below the white part
//         }}
//       />
//     </div>
//   );
// };

// export default Marker2;




// OK v1 imported
// import React, { Component, useEffect, useState } from "react";



// const Marker2 = ({ text }) => {
//     const [isHovered, setIsHovered] = useState(false);

//     return (
//       <div
//         style={{
//           position: "relative",
//           color: "#000", // Text color
//           backgroundColor: isHovered ? "white" : "#fff", // White background initially, transparent on hover
//           padding: isHovered ? "10px 20px" : "10px", // Extra padding for capsule shape
//           borderRadius: isHovered ? "30px" : "50%", // Rounded capsule corners on hover
//           textAlign: "center",
//           fontSize: "12px", // Font size for readability
//           fontWeight: "bold", // Bold text
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow
//           transition: "all 0.3s ease", // Smooth transition for hover effects
//           display: "inline-flex", // Ensures the bubble fits its content
//           alignItems: "center", // Center the text vertically
//           justifyContent: "center", // Center the text horizontally
//           cursor: "pointer", // Indicate that the bubble is interactive
//           width: isHovered ? "150px" : "15px", // Expand width on hover
//           height: "15px", // Height for the capsule shape
//           minWidth: "15px", // Minimum width for the bubble
//           overflow: "hidden", // Hide text overflow
//           whiteSpace: "nowrap", // Prevent text wrapping
//           lineHeight: isHovered ? "normal" : "60px", // Adjust line height for capsule
//           borderRadius: isHovered ? "80px" : "50%", // Rounded corners for capsule effect
//         }}
//         onMouseEnter={() => setIsHovered(true)}
//         onMouseLeave={() => setIsHovered(false)}
//       >
//         {/* T-shirt in black circle */}
//         <div
//           style={{
//             position: "absolute",
//             width: "25px",
//             height: "25px",
//             backgroundColor: "black",
//             borderRadius: "50%", // Creates a circle
//             top: "50%",
//             left: isHovered ? "10%" : "50%",
//             transform: "translate(-50%, -50%)", // Center the black circle
//             zIndex: 1, // Ensures the black circle is on top
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           {/* T-shirt image */}
//           {/* <img
//             src="t-shirt.png" // Path to your T-shirt image
//             alt="T-shirt"
//             style={{
//               width: "10px",
//               height: "auto",
//               filter: "invert(1)", // Inverts the color of the T-shirt to white
//             }}
//           /> */}
//         </div>

//         {/* White part on hover */}
//         {isHovered && (
//           <div
//             style={{
//               position: "absolute",
//               width: "100px",
//               height: "100px",
//               backgroundColor: "white",
//               borderRadius: "50%",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               zIndex: 1000,
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center", // Center text inside the white part
//             }}
//           >
//             <span
//               style={{
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 color: "#000",
//                 textAlign: "center",
//                 zIndex: 1000,
//                 transform: "translateX(35px)", // Shift text to the left
//               }}
//             >
//               {text}
//             </span>
//           </div>
//         )}
//         {/* Pointy part */}
//         <div
//           style={{
//             position: "absolute",
//             width: "0",
//             height: "0",
//             borderLeft: "15px solid transparent", // Increase the width of the arrow
//             borderRight: "15px solid transparent", // Increase the width of the arrow
//             borderTop: "20px solid red", // Change the color to red for debugging
//             top: "100%", // Position it just below the circle
//             left: "50%",
//             transform: "translateX(-50%)", // Center the pointy part horizontally
//             zIndex: 2, // Make sure the arrow is on top
//           }}
//         />
//       </div>
//     );
//   };


//   export default Marker2;
