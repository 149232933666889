

// v8 make 1 card bigger for auto play vid like insta later


// ok v7.4 add redux
import React, { useState, useRef } from 'react';
import './Card.css';

const Card = ({ property, activeProperty, setActiveProperty, setShowPopup, onCartItemClick }) => {
    const { _id, price, address, city, media, title, description } = property;
    const [isHovered, setIsHovered] = useState(false);

    const videoRef = useRef(null);

    // Handle the click to set the active property, show the popup, and possibly trigger the cart item click
    const handleCardClick = () => {
        setActiveProperty(property); // Set the active property
        setShowPopup(true);  // Show the popup
        if (onCartItemClick) {
            onCartItemClick(property._id);  // Trigger cart item click if provided
            console.log("##### Card.js _id #######")
            console.log(property._id)
        }
    };

    return (
        <div
            id={`card-${_id}`}
            className={`card ${property === activeProperty ? 'is-active' : ''}`} // Highlight active property
            onClick={handleCardClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="position-relative overflow-hidden square-card">
                {/* Video or Image based on hover and media availability */}
                {isHovered && media?.[0]?.url ? (
                    <video
                        ref={videoRef}
                        className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
                        src={`https://${media[0].url}`}
                        muted
                        loop
                        playsInline
                    />
                ) : media?.[0]?.url ? (
                    <img
                        src={`https://${media[0].url}`}
                        alt={title}
                        className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
                    />
                ) : (
                    <div className="placeholder bg-light d-flex align-items-center justify-content-center text-center w-100 h-100">
                        <span>No media available</span>
                    </div>
                )}

                {/* Card body with the title, description, and location */}
                <div className="card-body position-relative z-index-2 text-white">
                    <p className="price fs-5 mb-1">{title}</p>
                    <div className="details">
                        <span>{description}</span>
                        {address && <p className="location">{address}, {city}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;






// ok v7.3 link Cart and Marker
// import React, { useState, useRef } from 'react';
// import './Card.css';

// const Card = ({ property, activeProperty, setActiveProperty, setShowPopup, onCartItemClick }) => {
//     const { _id, price, address, city, media, title, description } = property;
//     const [isHovered, setIsHovered] = useState(false);

//     const videoRef = useRef(null);

//     // Handle the click to set the active property, show the popup, and possibly trigger the cart item click
//     const handleCardClick = () => {
//         setActiveProperty(property); // Set the active property
//         setShowPopup(true);  // Show the popup
//         if (onCartItemClick) {
//             onCartItemClick(property._id);  // Trigger cart item click if provided
//             console.log("##### Card.js _id #######")
//             console.log(property._id)
//         }
//     };

//     return (
//         <div
//             id={`card-${_id}`}
//             className={`card ${property === activeProperty ? 'is-active' : ''}`} // Highlight active property
//             onClick={handleCardClick}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//         >
//             <div className="position-relative overflow-hidden square-card">
//                 {/* Video or Image based on hover and media availability */}
//                 {isHovered && media?.[0]?.url ? (
//                     <video
//                         ref={videoRef}
//                         className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                         src={`https://${media[0].url}`}
//                         muted
//                         loop
//                         playsInline
//                     />
//                 ) : media?.[0]?.url ? (
//                     <img
//                         src={`https://${media[0].url}`}
//                         alt={title}
//                         className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                     />
//                 ) : (
//                     <div className="placeholder bg-light d-flex align-items-center justify-content-center text-center w-100 h-100">
//                         <span>No media available</span>
//                     </div>
//                 )}

//                 {/* Card body with the title, description, and location */}
//                 <div className="card-body position-relative z-index-2 text-white">
//                     <p className="price fs-5 mb-1">{title}</p>
//                     <div className="details">
//                         <span>{description}</span>
//                         {address && <p className="location">{address}, {city}</p>}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Card;






// ok v7.2 make it look better, fix phone css,
// import React, { useState, useRef } from 'react';
// import './Card.css';

// const Card = ({ property, activeProperty, setActiveProperty, setShowPopup }) => {
//     const { _id, price, address, city, media, title, description } = property;
//     const [isHovered, setIsHovered] = useState(false);

//     const videoRef = useRef(null);

//     // Handle the click to set the active property and show the popup
//     const handleCardClick = () => {
//     setActiveProperty(property);
//     setShowPopup(true);  // Trigger showing the popup
//     };

//     return (
//     <div
//         id={`card-${_id}`}
//         className={`card ${property === activeProperty ? 'is-active' : ''}`} // Highlight active property
//         onClick={handleCardClick}
//         onMouseEnter={() => setIsHovered(true)}
//         onMouseLeave={() => setIsHovered(false)}
//     >
//         <div className="position-relative overflow-hidden square-card">
//         {isHovered && media[0]?.url ? (
//             <video
//             ref={videoRef}
//             className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//             src={`https://${media[0].url}`}
//             muted
//             loop
//             playsInline
//             />
//         ) : media[0]?.url ? (
//             <img
//             src={`https://${media[0].url}`}
//             alt={city}
//             className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//             />
//         ) : (
//             <div className="placeholder bg-light d-flex align-items-center justify-content-center text-center w-100 h-100">
//             <span>No media available</span>
//             </div>
//         )}

//         <div className="card-body position-relative z-index-2 text-white">
//             <p className="price fs-5 mb-1">{title}</p>
//                 <div className="details">
//                          <span > {description}</span>
//                          <p className="location"><br /></p>
//                 </div>
//         </div>
//         </div>
//     </div>
//     );
// };

// export default Card;



// ok v7.1 add the click event back, add some info on hover
    // => it is the header bootstrap thats causing it, remove the bootstrap from header fixed this
// import React, { useState, useRef } from 'react';
// import './Card.css';

// const Card = ({ property, activeProperty, setActiveProperty, setShowPopup }) => {
//     const { _id, price, address, city, media, title, description } = property;
//     const [isHovered, setIsHovered] = useState(false);

//     const videoRef = useRef(null);

//     // Handle the click to set the active property and show the popup
//     const handleCardClick = () => {
//     setActiveProperty(property);
//     setShowPopup(true);  // Trigger showing the popup
//     };

//     return (
//     <div
//         id={`card-${_id}`}
//         className={`card ${property === activeProperty ? 'is-active' : ''}`} // Highlight active property
//         onClick={handleCardClick}
//         onMouseEnter={() => setIsHovered(true)}
//         onMouseLeave={() => setIsHovered(false)}
//     >
//         <div className="position-relative overflow-hidden square-card">
//         {isHovered && media[0]?.url ? (
//             <video
//             ref={videoRef}
//             className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//             src={`https://${media[0].url}`}
//             muted
//             loop
//             playsInline
//             />
//         ) : media[0]?.url ? (
//             <img
//             src={`https://${media[0].url}`}
//             alt={city}
//             className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//             />
//         ) : (
//             <div className="placeholder bg-light d-flex align-items-center justify-content-center text-center w-100 h-100">
//             <span>No media available</span>
//             </div>
//         )}

//         <div className="card-body position-relative z-index-2 text-white">
//             <p className="price fs-5 mb-1">{title} {description}</p>

//         </div>
//         </div>
//     </div>
//     );
// };

// export default Card;



// ok v7 make it use bootstrap for better view, make some cart taller
// import React, { useState, useRef } from 'react';
// import './Card.css';

// const Card = ({ property, activeProperty, setActiveProperty, setShowPopup, isDoubleHeight }) => {
//     const { _id, price, address, city, media } = property;
//     const [isHovered, setIsHovered] = useState(false);

//     const videoRef = useRef(null);

//     const handleCardClick = () => {
//         setActiveProperty(property);
//         setShowPopup(true);  // Show the popup when the card is clicked
//     };

//     return (
//         <div
//             id={`card-${_id}`}
//             className={`card ${isDoubleHeight ? 'double-height' : ''}`}
//             onClick={handleCardClick}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//         >
//             <div className="position-relative overflow-hidden">
//                 {isHovered && media[0]?.url ? (
//                     <video
//                         ref={videoRef}
//                         className="video-overlay position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                         src={`https://${media[0].url}`}
//                         muted
//                         loop
//                         playsInline
//                     />
//                 ) : media[0]?.url ? (
//                     <img
//                         src={`https://${media[0].url}`}
//                         alt={city}
//                         className="card-image position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
//                     />
//                 ) : (
//                     <div className="placeholder bg-light d-flex align-items-center justify-content-center text-center w-100 h-100">
//                         <span>No media available</span>
//                     </div>
//                 )}

//                 <div className="card-body position-relative z-index-2 text-white">
//                     <p className="price fs-5 mb-1">${price}</p>
//                     <div className="details">
//                         <span className="index">{_id + 1}</span>
//                         <p className="location">{city}<br />{address}</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Card;


// OK v6 add onClick open popup and show all media of that cart
// import React, { useState, useRef } from 'react';
// import './Card.css';

// const Card = ({ property, activeProperty, setActiveProperty, setShowPopup }) => {
//     const { _id, price, address, city, media } = property;
//     const [isHovered, setIsHovered] = useState(false);
//     const [popupVisible, setPopupVisible] = useState(false);

//     const videoRef = useRef(null);

//     const handleCardClick = () => {
//         setActiveProperty(property);
//         setPopupVisible(true);  // Trigger showing the popup
//         setShowPopup(true);  // Show the popup when the card is clicked
//     };

//     return (
//         <>
//             <div
//                 id={`card-${_id}`}
//                 className={`card col-sm-12 col-md-6 col-lg-4 ${property === activeProperty ? 'is-active' : ''}`}
//                 onClick={handleCardClick}
//                 onMouseEnter={() => setIsHovered(true)}
//                 onMouseLeave={() => setIsHovered(false)}
//                 style={{ position: 'relative', overflow: 'hidden' }}
//             >
//                 {isHovered && media[0]?.url ? (
//                     <video
//                         ref={videoRef}
//                         className="video-overlay"
//                         src={`https://${media[0].url}`}
//                         muted
//                         loop
//                         playsInline
//                     />
//                 ) : media[0]?.url ? (
//                     <img
//                         src={`https://${media[0].url}`}
//                         alt={city}
//                         className="card-image"
//                     />
//                 ) : (
//                     <div className="placeholder">No media available</div>
//                 )}

//                 <p className="price">${price}</p>
//                 <div className="details">
//                     <span className="index">{_id + 1}</span>
//                     <p className="location">
//                         {city}<br />{address}
//                     </p>
//                 </div>
//             </div>


//         </>
//     );
// };

// export default Card;


// OK v5 use the data from mongo
// v4 try for fix the blocking on dev screen -> add err handling at isHovered, OK
// import React, { useState, useRef, useEffect } from 'react';
// import './Card.css';

// const Card = ({ property, activeProperty, setActiveProperty }) => {
//     // const { index, price, address, city, picture, bedrooms, bathrooms, carSpaces, videoUrl } = property;
//     const { _id, price, address, city, media, bedrooms, bathrooms, carSpaces, videoUrl } = property;


//     const [isHovered, setIsHovered] = useState(false);
//     const videoRef = useRef(null);

//     useEffect(() => {
//         const video = videoRef.current;

//         if (video) {
//             try {
//                 if (isHovered) {
//                     if (video.src && video.readyState >= 2) {
//                         video.play();

//                         const skipInterval = 1000; // Check every second
//                         const skipPercent = 0.1; // 10% skip
//                         const skipDuration = video.duration * skipPercent;

//                         if (isFinite(video.duration) && isFinite(skipDuration)) {
//                             const intervalId = setInterval(() => {
//                                 if (video.currentTime + skipDuration < video.duration) {
//                                     video.currentTime += skipDuration;
//                                 } else {
//                                     video.currentTime = video.duration;
//                                     clearInterval(intervalId);
//                                 }
//                             }, skipInterval);

//                             return () => clearInterval(intervalId);
//                         }
//                     }
//                 } else {
//                     video.pause();
//                     video.currentTime = 0;
//                 }
//             } catch (error) {
//                 console.error("Error handling video:", error);
//             }
//         }
//     }, [isHovered]);

//     return (
//         <div
//             id={`card-${_id}`}
//             className={`card col-sm-12 col-md-6 col-lg-4 ${property === activeProperty ? 'is-active' : ''}`}
//             onClick={() => setActiveProperty(property)}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//             style={{ position: 'relative', overflow: 'hidden' }}
//         >
//             {/* {isHovered ? (
//                 <video
//                     ref={videoRef}
//                     className="video-overlay"
//                     src={`https://${media[0].url}`}
//                     muted
//                     loop
//                     playsInline
//                 />
//             ) : (
//                 <img
//                     src={  `https://${media[0].url}`}
//                     alt={city}
//                     className="card-image"
//                 />
//             )} */}


//             {isHovered && media[0]?.url ? (
//                 <video
//                     ref={videoRef}
//                     className="video-overlay"
//                     src={`https://${media[0].url}`}
//                     muted
//                     loop
//                     playsInline
//                 />
//             ) : media[0]?.url ? (
//                 <img
//                     src={`https://${media[0].url}`}
//                     alt={city}
//                     className="card-image"
//                 />
//             ) : (
//                 <div className="placeholder">No media available</div> // Optional placeholder
//             )}

//             <p className="price">${price}</p>
//             <div className="details">
//                 <span className="index">{_id + 1}</span>
//                 <p className="location">
//                     {city}<br />{address}
//                 </p>
//                 {/* <ul className="features">
//                     <li className="icon-bed">{bedrooms}<span>bedrooms</span></li>
//                     <li className="icon-bath">{bathrooms}<span>bathrooms</span></li>
//                     <li className="icon-car">{carSpaces}<span>parking spots</span></li>
//                 </ul> */}
//             </div>
//         </div>
//     );
// };

// export default Card;




// v4 try for fix the blocking on dev screen -> add err handling at isHovered, OK
// import React, { useState, useRef, useEffect } from 'react';
// import './Card.css';

// const Card = ({ property, activeProperty, setActiveProperty }) => {
//     const { index, price, address, city, picture, bedrooms, bathrooms, carSpaces, videoUrl } = property;

//     const [isHovered, setIsHovered] = useState(false);
//     const videoRef = useRef(null);

//     useEffect(() => {
//         const video = videoRef.current;

//         if (video) {
//             try {
//                 if (isHovered) {
//                     if (video.src && video.readyState >= 2) {
//                         video.play();

//                         const skipInterval = 1000; // Check every second
//                         const skipPercent = 0.1; // 10% skip
//                         const skipDuration = video.duration * skipPercent;

//                         if (isFinite(video.duration) && isFinite(skipDuration)) {
//                             const intervalId = setInterval(() => {
//                                 if (video.currentTime + skipDuration < video.duration) {
//                                     video.currentTime += skipDuration;
//                                 } else {
//                                     video.currentTime = video.duration;
//                                     clearInterval(intervalId);
//                                 }
//                             }, skipInterval);

//                             return () => clearInterval(intervalId);
//                         }
//                     }
//                 } else {
//                     video.pause();
//                     video.currentTime = 0;
//                 }
//             } catch (error) {
//                 console.error("Error handling video:", error);
//             }
//         }
//     }, [isHovered]);

//     return (
//         <div
//             id={`card-${index}`}
//             className={`card col-sm-12 col-md-6 col-lg-4 ${property === activeProperty ? 'is-active' : ''}`}
//             onClick={() => setActiveProperty(property)}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//             style={{ position: 'relative', overflow: 'hidden' }}
//         >
//             {isHovered ? (
//                 <video
//                     ref={videoRef}
//                     className="video-overlay"
//                     src={videoUrl}
//                     muted
//                     loop
//                     playsInline
//                 />
//             ) : (
//                 <img
//                     src={picture}
//                     alt={city}
//                     className="card-image"
//                 />
//             )}
//             <p className="price">${price}</p>
//             <div className="details">
//                 <span className="index">{index + 1}</span>
//                 <p className="location">
//                     {city}<br />{address}
//                 </p>
//                 {/* <ul className="features">
//                     <li className="icon-bed">{bedrooms}<span>bedrooms</span></li>
//                     <li className="icon-bath">{bathrooms}<span>bathrooms</span></li>
//                     <li className="icon-car">{carSpaces}<span>parking spots</span></li>
//                 </ul> */}
//             </div>
//         </div>
//     );
// };

// export default Card;



// v3 play vid in skipping mode, and overwrite the thumbnail
// import React, { useState, useRef, useEffect } from 'react';

// import './Card.css';

// const Card = ({ property, activeProperty, setActiveProperty }) => {
//     const { index, price, address, city, picture, bedrooms, bathrooms, carSpaces, videoUrl } = property;

//     const [isHovered, setIsHovered] = useState(false);
//     const videoRef = useRef(null);

//     useEffect(() => {
//         const video = videoRef.current;
//         if (video && isHovered) {
//             video.play();
//             const skipInterval = 1000; // Check every second
//             const skipPercent = 0.1; // 10% skip
//             const skipDuration = video.duration * skipPercent;

//             const intervalId = setInterval(() => {
//                 if (video.currentTime + skipDuration < video.duration) {
//                     video.currentTime += skipDuration;
//                 } else {
//                     video.currentTime = video.duration;
//                     clearInterval(intervalId); // Stop skipping once the video ends
//                 }
//             }, skipInterval);

//             return () => clearInterval(intervalId); // Cleanup on unmount
//         } else if (video) {
//             video.pause();
//             video.currentTime = 0; // Reset to start
//         }
//     }, [isHovered]);

//     return (
//         <div
//             id={`card-${index}`}
//             className={`card col-sm-12 col-md-6 col-lg-4 ${property === activeProperty ? 'is-active' : ''}`}
//             onClick={() => setActiveProperty(property)}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//             style={{ position: 'relative', overflow: 'hidden' }}
//         >
//             {isHovered ? (
//                 <video
//                     ref={videoRef}
//                     className="video-overlay"
//                     src={videoUrl}
//                     muted
//                     loop
//                     playsInline
//                 />
//             ) : (
//                 <img
//                     src={picture}
//                     alt={city}
//                     className="card-image"
//                 />
//             )}
//             <p className="price">${price}</p>
//             <div className="details">
//                 <span className="index">{index + 1}</span>
//                 <p className="location">
//                     {city}<br />{address}
//                 </p>
//                 <ul className="features">
//                     <li className="icon-bed">{bedrooms}<span>bedrooms</span></li>
//                     <li className="icon-bath">{bathrooms}<span>bathrooms</span></li>
//                     <li className="icon-car">{carSpaces}<span>parking spots</span></li>
//                 </ul>
//             </div>
//         </div>
//     );
// };

// export default Card;


// // v2 add video play on hover
// import React, { useState } from 'react';
// import './Card.css';
// const Card = ({ property, activeProperty, setActiveProperty }) => {
//     const { index, price, address, city, picture, bedrooms, bathrooms, carSpaces, videoUrl } = property;

//     const [isHovered, setIsHovered] = useState(false);

//     return (
//         <div
//             id={`card-${index}`}
//             className={`card col-sm-12 col-md-6 col-lg-4 ${property === activeProperty ? 'is-active' : ''}`}
//             onClick={() => setActiveProperty(property)}
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//         >
//             <img src={picture} alt={city} />
//             {videoUrl && isHovered && (
//                 <video
//                     className="video-overlay"
//                     src={videoUrl}
//                     autoPlay
//                     muted
//                     loop
//                     playsInline
//                 />
//             )}
//             <p className="price">${price}</p>
//             <div className="details">
//                 <span className="index">{index + 1}</span>
//                 <p className="location">
//                     {city}<br />{address}
//                 </p>
//                 <ul className="features">
//                     <li className="icon-bed">{bedrooms}<span>bedrooms</span></li>
//                     <li className="icon-bath">{bathrooms}<span>bathrooms</span></li>
//                     <li className="icon-car">{carSpaces}<span>parking spots</span></li>
//                 </ul>
//             </div>
//         </div>
//     );
// };

// export default Card;








// v1 original
// import React from 'react';

// const Card = ({property, activeProperty, setActiveProperty}) => {

//     const {index,price, address, city, picture, bedrooms, bathrooms, carSpaces} = property;

//     return <div id={`card-${index}`} className={`card col-sm-12 col-md-6 col-lg-4 ${property === activeProperty ? 'is-active' : ''}`} onClick={() => setActiveProperty(property)}>
//         <img src={picture} alt={city} />
//         <p className="price">${price}</p>
//         <div className="details">
//             <span className="index">{index+1}</span>
//             <p className="location">
//             {city}<br />{address}
//             </p>
//             <ul className="features">
//                 <li className="icon-bed">{bedrooms}<span>bedrooms</span></li>
//                 <li className="icon-bath">{bathrooms}<span>bathrooms</span></li>
//                 <li className="icon-car">{carSpaces}<span>parking spots</span></li>
//             </ul>
//         </div>
//     </div>
// }

// export default Card;