







import React from "react";
import ReactDOM from "react-dom";


import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import App from "./App";
// import MapComponent from "./MapComponent";
// console.log(MapComponent);
// import { Provider } from 'react-redux';
// import { ConfigureStore } from './redux/configureStore';



const Home = () => {
  return (
    <div>
      <h2>Welcome to the Map Application!</h2>
      <p>
        This application allows you to find and save locations on a map. You can
        add markers, upload images and videos, and add notes.
      </p>
      <p>Navigate to the "Add Marker" page to start adding your locations!</p>
    </div>
  );
};


// Create the store using the ConfigureStore function
// const store = ConfigureStore();

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    {/* <Router> */}
    {/* <Provider store={store}> */}
      <App />
    {/* </Provider> */}
    {/* </Router> */}
  </React.StrictMode>,
  rootElement

);









