


// ok v10.1 add header
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// import GoogleMap from "./GoogleMap";
import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// Fix for default icon paths
import data from "./data";
import MarkerForm from "./MarkerForm";
import Upload from "./Upload";
// import MarkerForm from "./MarkerForm";
import Header from "./Header";
import { AppContainer } from "../App.styles2";
const icon =
  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
const iconShadow =
  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
});

L.Marker.prototype.options.icon = DefaultIcon;

const center = {
  lat: 21.044175,
  lng: 105.846411,
};

// MinIO Configuration
const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
const BUCKET_NAME = "demo-map";
const FILE_NAME = "location.json";

const DraggableMarkerOld = ({ position, setPosition }) => {
  const markerRef = useRef(null);

  useMapEvents({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        setPosition(marker.getLatLng());
      }
    },
  });

  return <Marker draggable position={position} ref={markerRef} />;
};

const DraggableMarkerOld2 = ({ position, setPosition }) => {
  const markerRef = useRef(null);
  const [currentCoords, setCurrentCoords] = useState(position);

  useMapEvents({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        const newPosition = marker.getLatLng();
        setPosition(newPosition);
        setCurrentCoords(newPosition); // Update the current coordinates

        // Log current location
        console.log("####### current ##########");
        console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
      }
    },
  });

  return (
    <Marker draggable position={position} ref={markerRef}>
      <Popup>
        Latitude: {currentCoords.lat}<br />
        Longitude: {currentCoords.lng}
      </Popup>
    </Marker>
  );
};

const DraggableMarker = ({ position, setPosition }) => {
  const markerRef = useRef(null);

  useMapEvents({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        const newPosition = marker.getLatLng();
        setPosition(newPosition);
        console.log("Marker dragged to: ", newPosition); // Log the new position
      }
    },
  });

  return (
    <Marker
      draggable
      position={position}
      ref={markerRef}
      eventHandlers={{
        dragend: () => {
          const marker = markerRef.current;
          if (marker) {
            const newPosition = marker.getLatLng();
            setPosition(newPosition);
            console.log("Marker dragged to: ", newPosition); // Log the new position
          }
        },
      }}
    >
      <Popup>
        Latitude: {position.lat}<br />
        Longitude: {position.lng}
      </Popup>
    </Marker>
  );
};

const SubMapComponent = () => {
  const [markerPosition, setMarkerPosition] = useState(center);
  const [notes, setNotes] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const newLocation = { lat: latitude, lng: longitude };
        setMarkerPosition(newLocation); // Move marker to current location
        if (mapRef.current) {
          mapRef.current.setView(newLocation, 13); // Center the map view on the current location
        }
      },
      () => null
    );
  }, []);

  const moveMarkerToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
        const newLocation = { lat: latitude, lng: longitude };
        setMarkerPosition(newLocation); // Update marker position
        if (mapRef.current) {
          mapRef.current.setView(newLocation, 13); // Center the map view on the new location
        }
      },
      (error) => {
        console.error("Geolocation Error:", error);
        alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
      }
    );
  };

  const V1moveMarkerToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        console.log("########### cord #######");
        console.log(`Current Latitude: ${latitude}, Current Longitude: ${longitude}`); // Log current coordinates
        const newLocation = { lat: latitude, lng: longitude };
        setMarkerPosition(newLocation); // Update marker position
        if (mapRef.current) {
          mapRef.current.setView(newLocation, 13); // Center the map view on the new location
        }
      },
      () => null
    );
  };

  const handleFileChange = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const uploadFile = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
    } catch (error) {
      console.error(`Error uploading ${type}:`, error);
      throw error;
    }
  };

  const saveLocation = async () => {
    try {
      let existingData = { locations: [] };

      try {
        const response = await axios.get(
          `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
        );
        existingData = response.data;
      } catch (fetchError) {
        if (fetchError.response && fetchError.response.status === 404) {
          console.warn(
            "File does not exist. Creating new file with empty data."
          );
        } else {
          throw fetchError;
        }
      }

      let locations = existingData.locations || [];

      const roundCoordinates = (coord) => ({
        lat: Math.round(coord.lat * 1e6) / 1e6,
        lng: Math.round(coord.lng * 1e6) / 1e6,
      });

      console.log("Current locations:", locations);

      const isDuplicate = locations.some((loc) => {
        const locCoords = loc.position || {};
        const markerCoords = markerPosition || {};
        if (
          !locCoords.lat ||
          !locCoords.lng ||
          !markerCoords.lat ||
          !markerCoords.lng
        ) {
          console.error("Invalid coordinates found. Skipping duplicate check.");
          return false;
        }
        return (
          roundCoordinates(locCoords).lat ===
            roundCoordinates(markerCoords).lat &&
          roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
        );
      });

      if (true) {
        const locationData = {
          position: markerPosition,
          notes: notes,
          imageUrl: null,
          videoUrl: null,
        };

        if (image) {
          try {
            locationData.imageUrl = await uploadFile(image, "images");
          } catch (uploadError) {
            console.error("Error uploading image:", uploadError);
          }
        }

        if (video) {
          try {
            locationData.videoUrl = await uploadFile(video, "videos");
          } catch (uploadError) {
            console.error("Error uploading video:", uploadError);
          }
        }

        locations.push(locationData);

        const updatedData = JSON.stringify({ locations });

        try {
          await axios.put(
            `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
            updatedData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          console.log("Location saved successfully:", updatedData);
          alert("Location saved successfully!");
        } catch (uploadError) {
          console.error("Error updating JSON file:", uploadError);
          alert("Error updating location data.");
        }
      } else {
        alert("This location has already been saved.");
      }
    } catch (error) {
      console.error("Error saving location:", error);
      alert("Error saving location.");
    }
  };

  const headerStyle = { display: 'flex', textAlign: 'center', flexDirection: 'row', padding: '5px 15px', position: 'relative', position: 'fixed', backgroundColor: '#e0e0e0', borderRadius: '6px', zIndex: 2, boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',};
  return (
    <div>
      <AppContainer>
      <Header/>
      <MapContainer
        center={markerPosition}
        zoom={13}
        style={{ height: "400px", width: "100%" }}
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <DraggableMarker
          position={markerPosition}
          setPosition={setMarkerPosition}
        />
      </MapContainer>
      <div>
        <button onClick={moveMarkerToCurrentLocation}>
          Move Marker to Current Location
        </button>
        {/* <button onClick={saveLocation}>Save Final Marker Location</button> */}
      </div>
      {/* <div>
        <textarea
          rows="3"
          cols="50"
          placeholder="Enter notes here..."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <div>
        <label>
          Upload Image:
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(e, setImage)}
          />
        </label>
      </div>
      <div>
        <label>
          Upload Video:
          <input
            type="file"
            accept="video/*"
            onChange={(e) => handleFileChange(e, setVideo)}
          />
        </label>
      </div> */}
      {/* <MarkerForm/> */}
      <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />
      {/* <Upload/> */}

      </AppContainer>
    </div>
  );
};

const Home2 = () => {
  return (
    <div>
      <h2>Welcome to the Map Application!</h2>
      <p>This application allows you to find and save locations on a map.</p>
      <p>Navigate to the "Add Marker" page to start adding your locations!</p>
    </div>
  );
};

const MapComponent = () => {
  // const data = await response.json();

  const [properties, setProperties] = useState(data.properties);
  const [DefaultCenter] = useState(data.properties[0]);
  const [activeProperty, setActiveProperty] = useState(data.properties[0]);
  return (
    <SubMapComponent />
    // <BrowserRouter>
    //   <Routes>
    //     {/* fix GG component */}
    //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
    //     <Route path="/current" element={<SubMapComponent />} />
    //   </Routes>
    // </BrowserRouter>
  );
};
export default MapComponent;




// ~v10 make it nicer
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";
// // import MarkerForm from "./MarkerForm";
// import Header from "./Header";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarkerOld = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const DraggableMarkerOld2 = ({ position, setPosition }) => {
//   const markerRef = useRef(null);
//   const [currentCoords, setCurrentCoords] = useState(position);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         setCurrentCoords(newPosition); // Update the current coordinates

//         // Log current location
//         console.log("####### current ##########");
//         console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
//       }
//     },
//   });

//   return (
//     <Marker draggable position={position} ref={markerRef}>
//       <Popup>
//         Latitude: {currentCoords.lat}<br />
//         Longitude: {currentCoords.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         console.log("Marker dragged to: ", newPosition); // Log the new position
//       }
//     },
//   });

//   return (
//     <Marker
//       draggable
//       position={position}
//       ref={markerRef}
//       eventHandlers={{
//         dragend: () => {
//           const marker = markerRef.current;
//           if (marker) {
//             const newPosition = marker.getLatLng();
//             setPosition(newPosition);
//             console.log("Marker dragged to: ", newPosition); // Log the new position
//           }
//         },
//       }}
//     >
//       <Popup>
//         Latitude: {position.lat}<br />
//         Longitude: {position.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       (error) => {
//         console.error("Geolocation Error:", error);
//         alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
//       }
//     );
//   };

//   const V1moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("########### cord #######");
//         console.log(`Current Latitude: ${latitude}, Current Longitude: ${longitude}`); // Log current coordinates
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       {/* <Header/> */}
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         {/* <button onClick={saveLocation}>Save Final Marker Location</button> */}
//       </div>
//       {/* <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div> */}
//       {/* <MarkerForm/> */}
//       <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />
//       {/* <Upload/> */}
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;



// ok v9 combine with MarkerForm.js

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";
// // import MarkerForm from "./MarkerForm";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarkerOld = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const DraggableMarkerOld2 = ({ position, setPosition }) => {
//   const markerRef = useRef(null);
//   const [currentCoords, setCurrentCoords] = useState(position);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         setCurrentCoords(newPosition); // Update the current coordinates

//         // Log current location
//         console.log("####### current ##########");
//         console.log(`Current Location: Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}`);
//       }
//     },
//   });

//   return (
//     <Marker draggable position={position} ref={markerRef}>
//       <Popup>
//         Latitude: {currentCoords.lat}<br />
//         Longitude: {currentCoords.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         const newPosition = marker.getLatLng();
//         setPosition(newPosition);
//         console.log("Marker dragged to: ", newPosition); // Log the new position
//       }
//     },
//   });

//   return (
//     <Marker
//       draggable
//       position={position}
//       ref={markerRef}
//       eventHandlers={{
//         dragend: () => {
//           const marker = markerRef.current;
//           if (marker) {
//             const newPosition = marker.getLatLng();
//             setPosition(newPosition);
//             console.log("Marker dragged to: ", newPosition); // Log the new position
//           }
//         },
//       }}
//     >
//       <Popup>
//         Latitude: {position.lat}<br />
//         Longitude: {position.lng}
//       </Popup>
//     </Marker>
//   );
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("Geolocation Success:", `Latitude: ${latitude}, Longitude: ${longitude}`);
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       (error) => {
//         console.error("Geolocation Error:", error);
//         alert("Error retrieving location. Make sure geolocation is enabled and allowed.");
//       }
//     );
//   };

//   const V1moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("########### cord #######");
//         console.log(`Current Latitude: ${latitude}, Current Longitude: ${longitude}`); // Log current coordinates
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         {/* <button onClick={saveLocation}>Save Final Marker Location</button> */}
//       </div>
//       {/* <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div> */}
//       {/* <MarkerForm/> */}
//       <MarkerForm lat={markerPosition.lat} lon={markerPosition.lng} />
//       {/* <Upload/> */}
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;



// // v8-> clone from v7 (note oK, img, vid, dup, recenter not ok) add note, img, fix duplicate -> looks like it is saving current location not the pin location; fix this

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         console.log("########### cord #######");
//         console.log(`Current Latitude: ${latitude}, Current Longitude: ${longitude}`); // Log current coordinates
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         <button onClick={saveLocation}>Save Final Marker Location</button>
//       </div>
//       <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div>
//       <MarkerForm/>
//       <Upload/>
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;




// ~v7(note oK, img, vid, dup, recenter not ok) add note, img, fix duplicate -> looks like it is saving current location not the pin location; fix this
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// // import GoogleMap from "./GoogleMap";
// import { BrowserRouter, Route, Routes, Switch, Link } from "react-router-dom";
// // Fix for default icon paths
// import data from "./data";
// import MarkerForm from "./MarkerForm";
// import Upload from "./Upload";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const SubMapComponent = () => {
//   const [markerPosition, setMarkerPosition] = useState(center);
//   const [notes, setNotes] = useState("");
//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Move marker to current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the current location
//         }
//       },
//       () => null
//     );
//   }, []);

//   const moveMarkerToCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation); // Update marker position
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13); // Center the map view on the new location
//         }
//       },
//       () => null
//     );
//   };

//   const handleFileChange = (event, setFile) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFile(file);
//     }
//   };

//   const uploadFile = async (file, type) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       return `${MINIO_ENDPOINT}/${BUCKET_NAME}/${type}/${file.name}`;
//     } catch (error) {
//       console.error(`Error uploading ${type}:`, error);
//       throw error;
//     }
//   };

//   const saveLocation = async () => {
//     try {
//       let existingData = { locations: [] };

//       try {
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           throw fetchError;
//         }
//       }

//       let locations = existingData.locations || [];

//       const roundCoordinates = (coord) => ({
//         lat: Math.round(coord.lat * 1e6) / 1e6,
//         lng: Math.round(coord.lng * 1e6) / 1e6,
//       });

//       console.log("Current locations:", locations);

//       const isDuplicate = locations.some((loc) => {
//         const locCoords = loc.position || {};
//         const markerCoords = markerPosition || {};
//         if (
//           !locCoords.lat ||
//           !locCoords.lng ||
//           !markerCoords.lat ||
//           !markerCoords.lng
//         ) {
//           console.error("Invalid coordinates found. Skipping duplicate check.");
//           return false;
//         }
//         return (
//           roundCoordinates(locCoords).lat ===
//             roundCoordinates(markerCoords).lat &&
//           roundCoordinates(locCoords).lng === roundCoordinates(markerCoords).lng
//         );
//       });

//       if (true) {
//         const locationData = {
//           position: markerPosition,
//           notes: notes,
//           imageUrl: null,
//           videoUrl: null,
//         };

//         if (image) {
//           try {
//             locationData.imageUrl = await uploadFile(image, "images");
//           } catch (uploadError) {
//             console.error("Error uploading image:", uploadError);
//           }
//         }

//         if (video) {
//           try {
//             locationData.videoUrl = await uploadFile(video, "videos");
//           } catch (uploadError) {
//             console.error("Error uploading video:", uploadError);
//           }
//         }

//         locations.push(locationData);

//         const updatedData = JSON.stringify({ locations });

//         try {
//           await axios.put(
//             `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//             updatedData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("Location saved successfully:", updatedData);
//           alert("Location saved successfully!");
//         } catch (uploadError) {
//           console.error("Error updating JSON file:", uploadError);
//           alert("Error updating location data.");
//         }
//       } else {
//         alert("This location has already been saved.");
//       }
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={markerPosition}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker
//           position={markerPosition}
//           setPosition={setMarkerPosition}
//         />
//       </MapContainer>
//       <div>
//         <button onClick={moveMarkerToCurrentLocation}>
//           Move Marker to Current Location
//         </button>
//         <button onClick={saveLocation}>Save Final Marker Location</button>
//       </div>
//       <div>
//         <textarea
//           rows="3"
//           cols="50"
//           placeholder="Enter notes here..."
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>
//           Upload Image:
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleFileChange(e, setImage)}
//           />
//         </label>
//       </div>
//       <div>
//         <label>
//           Upload Video:
//           <input
//             type="file"
//             accept="video/*"
//             onChange={(e) => handleFileChange(e, setVideo)}
//           />
//         </label>
//       </div>
//       <MarkerForm/>
//       <Upload/>
//     </div>
//   );
// };

// const Home2 = () => {
//   return (
//     <div>
//       <h2>Welcome to the Map Application!</h2>
//       <p>This application allows you to find and save locations on a map.</p>
//       <p>Navigate to the "Add Marker" page to start adding your locations!</p>
//     </div>
//   );
// };

// const MapComponent = () => {
//   // const data = await response.json();

//   const [properties, setProperties] = useState(data.properties);
//   const [DefaultCenter] = useState(data.properties[0]);
//   const [activeProperty, setActiveProperty] = useState(data.properties[0]);
//   return (
//     <SubMapComponent />
//     // <BrowserRouter>
//     //   <Routes>
//     //     {/* fix GG component */}
//     //     {/* <Route path="/" element={<GoogleMap DefaultCenter={DefaultCenter} />} /> */}
//     //     <Route path="/current" element={<SubMapComponent />} />
//     //   </Routes>
//     // </BrowserRouter>
//   );
// };
// export default MapComponent;

// OK v6 (still not auto move, write minio OK ) fix auto move map, write to minio
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// // Fix for default icon paths
// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// // MinIO Configuration
// const MINIO_ENDPOINT = "https://obj.tang17so82duytan.com";
// const BUCKET_NAME = "demo-map";
// const FILE_NAME = "location.json";

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);
//   const [markerPosition, setMarkerPosition] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setLocation(newLocation);
//         setMarkerPosition(newLocation); // Place marker at current location
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   }, []);
//   const saveLocation = async () => {
//     try {
//       // Initialize with an empty locations array
//       let existingData = { locations: [] };

//       try {
//         // Attempt to fetch the existing JSON file from MinIO
//         const response = await axios.get(
//           `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`
//         );
//         existingData = response.data;
//       } catch (fetchError) {
//         if (fetchError.response && fetchError.response.status === 404) {
//           // File does not exist, initialize with empty data
//           console.warn(
//             "File does not exist. Creating new file with empty data."
//           );
//         } else {
//           // Re-throw any other errors
//           throw fetchError;
//         }
//       }

//       // Parse the existing JSON data
//       let locations = existingData.locations || [];

//       // Log the current locations before appending
//       console.log("Current locations:", locations);

//       // Append the new location
//       locations.push(markerPosition);

//       // Log the new location being added
//       console.log("New location to be added:", markerPosition);

//       // Update the JSON data
//       const updatedData = JSON.stringify({ locations });

//       // Upload the updated JSON file to MinIO
//       await axios.put(
//         `${MINIO_ENDPOINT}/${BUCKET_NAME}/${FILE_NAME}`,
//         updatedData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       // Log successful save
//       console.log("Location saved successfully:", updatedData);

//       alert("Location saved successfully!");
//     } catch (error) {
//       console.error("Error saving location:", error);
//       alert("Error saving location.");
//     }
//   };

//   const placeMarkerAtCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation);
//         setLocation(newLocation); // Update the location state
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   };

//   return (
//     <div>
//       <MapContainer
//         center={location}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {markerPosition && (
//           <DraggableMarker
//             position={markerPosition}
//             setPosition={setMarkerPosition}
//           />
//         )}
//       </MapContainer>
//       <button onClick={placeMarkerAtCurrentLocation}>Current Location</button>
//       <button onClick={saveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;

//OK v5 fix marker icon
// import React, { useState, useEffect, useRef } from "react";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// // Fix for default icon paths
// // import icon from "leaflet/dist/images/marker-icon.png";
// // import iconShadow from "leaflet/dist/images/marker-shadow.png";

// const icon =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
// const iconShadow =
//   "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

// let DefaultIcon = L.icon({
//   iconUrl: icon,
//   shadowUrl: iconShadow,
//   iconAnchor: [12, 41], // Adjust anchor point to the bottom center of the icon
// });

// L.Marker.prototype.options.icon = DefaultIcon;

// const center = {
//   lat: 21.044175,
//   lng: 105.846411,
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);
//   const [markerPosition, setMarkerPosition] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setLocation(newLocation);
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   }, []);

//   const saveLocation = () => {
//     const locationData = JSON.stringify(markerPosition);
//     const blob = new Blob([locationData], { type: "application/json" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "location.json";
//     link.click();
//   };

//   const placeMarkerAtCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation);
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   };

//   return (
//     <div>
//       <MapContainer
//         center={location}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {markerPosition && (
//           <DraggableMarker
//             position={markerPosition}
//             setPosition={setMarkerPosition}
//           />
//         )}
//       </MapContainer>
//       <button onClick={placeMarkerAtCurrentLocation}>Current Location</button>
//       <button onClick={saveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;

// (OK, but the map does not move to current location )v4 fix current location
// import React, { useState, useEffect, useRef } from "react";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);
//   const [markerPosition, setMarkerPosition] = useState(null);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setLocation(newLocation);
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   }, []);

//   const saveLocation = () => {
//     const locationData = JSON.stringify(markerPosition);
//     const blob = new Blob([locationData], { type: "application/json" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "location.json";
//     link.click();
//   };

//   const placeMarkerAtCurrentLocation = () => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         const newLocation = { lat: latitude, lng: longitude };
//         setMarkerPosition(newLocation);
//         if (mapRef.current) {
//           mapRef.current.setView(newLocation, 13);
//         }
//       },
//       () => null
//     );
//   };

//   return (
//     <div>
//       <MapContainer
//         center={location}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//         whenCreated={(mapInstance) => {
//           mapRef.current = mapInstance;
//         }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {markerPosition && (
//           <DraggableMarker
//             position={markerPosition}
//             setPosition={setMarkerPosition}
//           />
//         )}
//       </MapContainer>
//       <button onClick={placeMarkerAtCurrentLocation}>Current Location</button>
//       <button onClick={saveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;

// OK (can save location, current location does not work, marker icon prob) v3 without api key
// import React, { useState, useEffect } from "react";
// import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

// const DraggableMarker = ({ position, setPosition }) => {
//   const markerRef = React.useRef(null);

//   useMapEvents({
//     dragend() {
//       const marker = markerRef.current;
//       if (marker != null) {
//         setPosition(marker.getLatLng());
//       }
//     },
//   });

//   return <Marker draggable position={position} ref={markerRef} />;
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setLocation({ lat: latitude, lng: longitude });
//       },
//       () => null
//     );
//   }, []);

//   const saveLocation = () => {
//     const locationData = JSON.stringify(location);
//     const blob = new Blob([locationData], { type: "application/json" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "location.json";
//     link.click();
//   };

//   return (
//     <div>
//       <MapContainer
//         center={location}
//         zoom={13}
//         style={{ height: "400px", width: "100%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         <DraggableMarker position={location} setPosition={setLocation} />
//       </MapContainer>
//       <button onClick={saveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;

//OK v2 but need api key
// import React, { useState, useCallback } from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// const containerStyle = {
//   width: "100%",
//   height: "400px",
// };

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

// const MapComponent = () => {
//   const [location, setLocation] = useState(center);

//   const onLoad = useCallback((map) => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setLocation({ lat: latitude, lng: longitude });
//         map.panTo({ lat: latitude, lng: longitude });
//       },
//       () => null
//     );
//   }, []);

//   const onMarkerDragEnd = (e) => {
//     const lat = e.latLng.lat();
//     const lng = e.latLng.lng();
//     setLocation({ lat, lng });
//   };

//   const saveLocation = () => {
//     const locationData = JSON.stringify(location);
//     const blob = new Blob([locationData], { type: "application/json" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "location.json";
//     link.click();
//   };

//   return (
//     <LoadScript googleMapsApiKey="AIzaSyApo7SZ0cD2Gl3y35Z52qcg4aUvpCMActA">
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={location}
//         zoom={10}
//         onLoad={onLoad}
//       >
//         <Marker
//           position={location}
//           draggable={true}
//           onDragEnd={onMarkerDragEnd}
//         />
//       </GoogleMap>
//       <button onClick={saveLocation}>Save Location</button>
//     </LoadScript>
//   );
// };

// export default MapComponent;

// v1 failed
// // src/MapComponent.js
// import React, { useState, useEffect } from "react";
// import {
//   MapContainer,
//   TileLayer,
//   Marker,
//   Popup,
//   useMapEvents,
// } from "react-leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";
// import { saveAs } from "file-saver";

// // Custom hook to handle map events
// function LocationMarker({ onLocationChange }) {
//   useMapEvents({
//     locationfound(e) {
//       onLocationChange(e.latlng);
//     },
//   });
//   return null;
// }

// const MapComponent = () => {
//   const [position, setPosition] = useState(null);

//   useEffect(() => {
//     const fetchLocation = () => {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setPosition([position.coords.latitude, position.coords.longitude]);
//         },
//         (error) => {
//           console.error("Error getting location:", error);
//         }
//       );
//     };

//     fetchLocation();
//   }, []);

//   const handleMarkerDragEnd = (e) => {
//     setPosition(e.latlng);
//   };

//   const handleSaveLocation = () => {
//     if (position) {
//       const blob = new Blob(
//         [JSON.stringify({ lat: position.lat, lng: position.lng })],
//         { type: "application/json" }
//       );
//       saveAs(blob, "location.json");
//     }
//   };

//   return (
//     <div>
//       <MapContainer
//         center={position || [51.505, -0.09]} // Default center if position is null
//         zoom={13}
//         style={{ height: "600px", width: "100%" }}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         />
//         {position && (
//           <Marker
//             position={position}
//             draggable={true}
//             onDragend={handleMarkerDragEnd}
//           >
//             <Popup>
//               <span>Your Location</span>
//             </Popup>
//           </Marker>
//         )}
//         <LocationMarker onLocationChange={setPosition} />
//       </MapContainer>
//       <button onClick={handleSaveLocation}>Save Location</button>
//     </div>
//   );
// };

// export default MapComponent;
