






import { createStore} from 'redux'


// the other part in Water.js file
//intitial store
const initialStore = {
//     count: 10,
// waterTexture: {},
// causticTexture:{},
// waterGeo: {},
isExtented: false,

}

//reducer: return updated state or old one
// it has two args: state, and action
function reducer(state, action ) {
    switch(action.type){
// case "DECREASE" : return {...state, count: state.count -1};
// case "INCREASE" : return {...state, count: state.count +1};
// case "postWaterTexture":  return {...state,  waterTexture: action.data};
// case "postWaterTexture":  return { waterTexture: action.data, ...state };

// case "postCausticTexture":  return {...state, causticTexture: action.data};
// case "postCausticTexture":  return { causticTexture: action.data ,...state};

case "EXTEND" : return {...state, isExtented: true};
case "SHRINK" : return {...state, isExtented: false};

// default: return {count: state.count};
default: return state;

    }
    // if (action.type === "postWaterTexture"){
    //     return {waterTexture: action.data};
    // }
    // if (action.type === "fetchWaterTexture"){
    //     // return { count: state.count -1};
    //     return {waterTexture: state.waterTexture};
    // }
    // if (action.type === "postCausticTexture"){
    //     return {causticTexture: action.data};
    // }
    // if (action.type === "fetchCausticTexture"){
    //     // return { count: state.count -1};
    //     return {causticTexture: state.causticTexture};
    // }

    // if (action.type === "DECREASE"){
    //     return { count: state.count -1};
    //     // return {waterTexture: state.waterTexture};

    // }

    // console.log(store.state);
    // console.log('hi*****************************');
    // return {count: state.count};
    // return state
}

//store
export const store = createStore(reducer, initialStore);







