// v6 clone below

// ok (nah still the same nothing added) v6 make it nicer, add auto reload on add marker
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// const { ObjectId } = require('mongodb');

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://comtruaduytan.com/api/markers/';//'https://comtruaduytan.com/backend/api/markers/'

const isValidObjectId = (id) => {
    const objectIdRegex = /^[0-9a-fA-F]{24}$/;
    return objectIdRegex.test(id);
};

const MarkerForm = ({ lat, lon }) => {
    const [markers, setMarkers] = useState([]);
    const [formData, setFormData] = useState({
        title: 'Tên địa điểm',
        description: 'Mô Tả',
        location: {
            type: 'Point',
            coordinates: [0, 0 ],
        },
        userId: '671e566bf1105a8a7070e048',
        mapId: '671e566bf1105a8a7070e048',
        contributor: '671e566bf1105a8a7070e048',
        media: [],
        sharedWith: ["671e566bf1105a8a7070e048"],
        status: 'private',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [files, setFiles] = useState([]); // State to handle file uploads


    // Use useEffect to update coordinates when lat or lon changes
    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            location: {
                type: 'Point',
                coordinates: [parseFloat(lat), parseFloat(lon)],
            },
        }));
    }, [lat, lon]); // Only re-run this effect if lat or lon changes

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Add new files to the existing list
    };

    const handleRemoveFile = (fileToRemove) => {
        setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove)); // Remove the selected file
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); // Reset error before submission

        try {
            // Type checking
            if (!isValidObjectId(formData.userId)) throw new Error("Invalid User ID format.");
            if (!isValidObjectId(formData.mapId)) throw new Error("Invalid Map ID format.");
            if (!isValidObjectId(formData.contributor)) throw new Error("Invalid Contributor ID format.");
            if (typeof formData.title !== 'string' || formData.title.trim() === '') throw new Error("Title must be a non-empty string.");
            if (typeof formData.description !== 'string') throw new Error("Description must be a string.");
            if (!Array.isArray(formData.sharedWith) || formData.sharedWith.length === 0) throw new Error("SharedWith must be a non-empty array.");
            // if (!files.length) throw new Error("At least one media file must be provided.");

            // Upload media files
            let mediaUrls = []; // Default to an empty array

            if (files && files.length > 0) {
                mediaUrls = await uploadMediaFiles();
                console.log('######## 2 ##########')
                console.log(mediaUrls)
            }

            // Construct the payload
            const payload = {
                ...formData,
                userId: formData.userId.trim(),
                mapId: formData.mapId.trim(),
                contributor: formData.contributor.trim(),
                location: formData.location,
                media: mediaUrls,
                sharedWith: formData.sharedWith.map(user => user.userId),
            };

            console.log('Submitting payload:', payload); // Log the payload

            // Send the request
            await axios.post(apiBaseUrl, payload);
            fetchMarkers();

            // Reload the page after successful submission
            window.location.reload();


            // Reset form data
            setFormData({
                title: '',
                description: '',
                location: { type: 'Point', coordinates: [0, 0] },
                userId: '',
                mapId: '',
                contributor: '',
                media: [],
                sharedWith: [],
                status: 'private',
            });
            setFiles([]); // Clear the files after submission
        } catch (error) {
            setError(`Error submitting marker: ${error.message}`);
            console.error('Error submitting marker:', error.response?.data || error.message);
        }
    };

    const uploadMediaFiles = async () => {

            const formData = new FormData();
            console.log('############ in uploadMediaFiles ###########')

            files.forEach(file => {
                formData.append('files', file); // Append each file to FormData
            });

            try {
                const response = await axios.post(`${apiBaseUrl}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                // return response.data.mediaUrls; // Assuming the API returns an array of media URLs
                console.log('############ 3 ###########')
                console.log(response)
                return response.data.results; // Assuming the API returns an array of media URLs

            } catch (error) {
                console.error(error);
                throw new Error('Error uploading files');
            }

    };

    const oldFetchMarkers = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(apiBaseUrl);
            setMarkers(response.data);
        } catch (error) {
            setError('Error fetching markers');
            console.error('Error fetching markers:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMarkers = async () => {
        setLoading(true);
        setError(null);
        try {
            // Get the token from localStorage (or any other place it's stored)
            const token = localStorage.getItem('token');

            // Make the GET request with the Authorization header containing the JWT token
            const response = await axios.get(apiBaseUrl, {
                headers: {
                    Authorization: `Bearer ${token}`  // Include the token in the request header
                }
            });

            setMarkers(response.data);
        } catch (error) {
            setError('Error fetching markers');
            console.error('Error fetching markers:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMarkers();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'lat' || name === 'lon') {
            // const lat = parseFloat(formData.location.coordinates[1]);
            // const lon = parseFloat(formData.location.coordinates[0]);
            setFormData((prev) => ({
                ...prev,
                location: {
                    type: 'Point',
                    coordinates: name === 'lat' ? [lon, parseFloat(value)] : [parseFloat(value), lat],
                },
            }));
        } else if (name === 'sharedWith') {
            const sharedUsers = value.split(',')
                .map(id => id.trim())
                .filter(id => isValidObjectId(id));
            setFormData((prev) => ({
                ...prev,
                sharedWith: sharedUsers.map(id => ({ userId: id })),
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };



    return (
        <div>
            <h2>Add Marker</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                />
                <textarea
                    name="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleChange}
                />
                <input
                    type="text"
                    name="lon"
                    placeholder="Longitude"
                    onChange={handleChange}
                    required
                    value={lon}
                />
                <input
                    type="text"
                    name="lat"
                    placeholder="Latitude"
                    onChange={handleChange}
                    required
                    value={lat}
                />
                <input
                    type="text"
                    name="userId"
                    placeholder="User ID (valid ObjectId)"
                    value={formData.userId}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="mapId"
                    placeholder="Map ID (valid ObjectId)"
                    value={formData.mapId}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="contributor"
                    placeholder="Contributor ID (valid ObjectId)"
                    value={formData.contributor}
                    onChange={handleChange}
                    required
                />
                <input
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    accept="image/*,video/*"
                />
                <div>
                    <h4>Selected Media:</h4>
                    <ul>
                        {files.map((file, index) => (
                            <li key={index}>
                                {file.name}
                                <button type="button" onClick={() => handleRemoveFile(file)}>Remove</button>
                            </li>
                        ))}
                    </ul>
                </div>
                <input
                    type="text"
                    name="sharedWith"
                    placeholder="Shared User IDs (comma separated)"
                    onChange={handleChange}
                />
                <select name="status" value={formData.status} onChange={handleChange}>
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                </select>
                <button type="submit">Add Marker</button>
            </form>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <h2>Markers</h2>

            {loading ? (
                <p>Loading markers...</p>
            ) : (
                <div
                    style={{
                        maxHeight: '400px', // Set a maximum height
                        overflowX: 'auto', // Enable horizontal scrolling
                        border: '1px solid #ccc', // Optional: border around the container
                        padding: '10px', // Optional: padding inside the container
                        marginTop: '10px', // Optional: margin above the container
                    }}
                >
                    <ul
                        style={{
                            display: 'flex', // Use flexbox for inline layout
                            flexWrap: 'nowrap', // Prevent wrapping to a new line
                            listStyle: 'none', // Remove bullet points
                            padding: '0', // Remove default padding
                            margin: '0', // Remove default margin
                        }}
                    >
                        {markers.map(marker => {
                            console.log(marker); // Log each marker object
                            return (
                            <li
                                key={marker._id}
                                style={{
                                    minWidth: '200px', // Set a minimum width for each marker item
                                    marginRight: '10px', // Space between items
                                    border: '1px solid #ccc', // Border for each marker item
                                    padding: '10px', // Padding for content
                                }}
                            >


                                <h3>{marker.title}</h3>
                                <p><strong>Description:</strong> {marker.description}</p>
                                <p><strong>Latitude:</strong> {marker.location.coordinates[1]}</p> {/* Latitude is at index 1 */}
                                <p><strong>Longitude:</strong> {marker.location.coordinates[0]}</p> {/* Longitude is at index 0 */}


                                <button
                                    style={{
                                        marginTop: '10px', // Space from Longitude line
                                        padding: '5px 10px', // Button padding
                                        backgroundColor: '#4CAF50', // Green background color
                                        color: 'white', // White text color
                                        border: 'none', // Remove the default border
                                        borderRadius: '5px', // Rounded corners
                                        cursor: 'pointer', // Cursor pointer on hover
                                    }}
                                    onClick={() => console.log(`Edit button clicked for marker ${marker._id}`)} // Handle the edit click event
                                >
                                    Edit
                                </button>



                                <div>
                                    <strong>Media:</strong>
                                    <ul style={{ padding: '0', listStyle: 'none' }}>
                                        {/* {marker.media.map((media, index) => (
                                            <li key={index}>
                                                <a href={media.url} target="_blank" rel="noopener noreferrer">
                                                    {media.name}
                                                </a>
                                            </li> */}
                                            {marker.media.map((media) => (
                                                <li key={media._id}> {/* Use media._id for unique keys */}
                                                    {media.type === 'image' ? (
                                                        <img
                                                            src={`https://${media.url}`} // Ensure the URL is valid
                                                            alt={marker.title}
                                                            style={{ maxWidth: '100%', height: 'auto' }} // Adjust styles as needed
                                                        />
                                                    ) : media.type === 'video' ? (
                                                        <video
                                                            controls
                                                            style={{ maxWidth: '100%', height: 'auto' }} // Adjust styles as needed
                                                        >
                                                            <source src={`https://${media.url}`} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    ) : (
                                                        <a href={media.url} target="_blank" rel="noopener noreferrer">
                                                            Media Link
                                                        </a>
                                                    )}
                                                </li>
                                        ))}
                                    </ul>
                                </div>
                            </li>
                        );
                        }
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MarkerForm;


// ok (Upload.js does receive media fullUrl, use it in main Add Marker ) v5 fix upload media and display media url
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// // const { ObjectId } = require('mongodb');

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';

// const isValidObjectId = (id) => {
//     const objectIdRegex = /^[0-9a-fA-F]{24}$/;
//     return objectIdRegex.test(id);
// };

// const MarkerForm = ({ lat, lon }) => {
//     const [markers, setMarkers] = useState([]);
//     const [formData, setFormData] = useState({
//         title: 'test',
//         description: 'test',
//         location: {
//             type: 'Point',
//             coordinates: [0, 0 ],
//         },
//         userId: '671e566bf1105a8a7070e048',
//         mapId: '671e566bf1105a8a7070e048',
//         contributor: '671e566bf1105a8a7070e048',
//         media: [],
//         sharedWith: ["671e566bf1105a8a7070e048"],
//         status: 'private',
//     });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [files, setFiles] = useState([]); // State to handle file uploads


//     // Use useEffect to update coordinates when lat or lon changes
//     useEffect(() => {
//         setFormData(prevState => ({
//             ...prevState,
//             location: {
//                 type: 'Point',
//                 coordinates: [parseFloat(lat), parseFloat(lon)],
//             },
//         }));
//     }, [lat, lon]); // Only re-run this effect if lat or lon changes

//     const handleFileChange = (e) => {
//         const newFiles = Array.from(e.target.files);
//         setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Add new files to the existing list
//     };

//     const handleRemoveFile = (fileToRemove) => {
//         setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove)); // Remove the selected file
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null); // Reset error before submission

//         try {
//             // Type checking
//             if (!isValidObjectId(formData.userId)) throw new Error("Invalid User ID format.");
//             if (!isValidObjectId(formData.mapId)) throw new Error("Invalid Map ID format.");
//             if (!isValidObjectId(formData.contributor)) throw new Error("Invalid Contributor ID format.");
//             if (typeof formData.title !== 'string' || formData.title.trim() === '') throw new Error("Title must be a non-empty string.");
//             if (typeof formData.description !== 'string') throw new Error("Description must be a string.");
//             if (!Array.isArray(formData.sharedWith) || formData.sharedWith.length === 0) throw new Error("SharedWith must be a non-empty array.");
//             // if (!files.length) throw new Error("At least one media file must be provided.");

//             // Upload media files
//             let mediaUrls = []; // Default to an empty array

//             if (files && files.length > 0) {
//                 mediaUrls = await uploadMediaFiles();
//                 console.log('######## 2 ##########')
//                 console.log(mediaUrls)
//             }

//             // Construct the payload
//             const payload = {
//                 ...formData,
//                 userId: formData.userId.trim(),
//                 mapId: formData.mapId.trim(),
//                 contributor: formData.contributor.trim(),
//                 location: formData.location,
//                 media: mediaUrls,
//                 sharedWith: formData.sharedWith.map(user => user.userId),
//             };

//             console.log('Submitting payload:', payload); // Log the payload

//             // Send the request
//             await axios.post(apiBaseUrl, payload);
//             fetchMarkers();

//             // Reset form data
//             setFormData({
//                 title: '',
//                 description: '',
//                 location: { type: 'Point', coordinates: [0, 0] },
//                 userId: '',
//                 mapId: '',
//                 contributor: '',
//                 media: [],
//                 sharedWith: [],
//                 status: 'private',
//             });
//             setFiles([]); // Clear the files after submission
//         } catch (error) {
//             setError(`Error submitting marker: ${error.message}`);
//             console.error('Error submitting marker:', error.response?.data || error.message);
//         }
//     };

//     const uploadMediaFiles = async () => {

//             const formData = new FormData();
//             console.log('############ in uploadMediaFiles ###########')

//             files.forEach(file => {
//                 formData.append('files', file); // Append each file to FormData
//             });

//             try {
//                 const response = await axios.post(`${apiBaseUrl}/upload`, formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data'
//                     }
//                 });
//                 // return response.data.mediaUrls; // Assuming the API returns an array of media URLs
//                 console.log('############ 3 ###########')
//                 console.log(response)
//                 return response.data.results; // Assuming the API returns an array of media URLs

//             } catch (error) {
//                 console.error(error);
//                 throw new Error('Error uploading files');
//             }

//     };

//     const oldFetchMarkers = async () => {
//         setLoading(true);
//         setError(null);
//         try {
//             const response = await axios.get(apiBaseUrl);
//             setMarkers(response.data);
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);
//         try {
//             // Get the token from localStorage (or any other place it's stored)
//             const token = localStorage.getItem('token');

//             // Make the GET request with the Authorization header containing the JWT token
//             const response = await axios.get(apiBaseUrl, {
//                 headers: {
//                     Authorization: `Bearer ${token}`  // Include the token in the request header
//                 }
//             });

//             setMarkers(response.data);
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers();
//     }, []);

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (name === 'lat' || name === 'lon') {
//             // const lat = parseFloat(formData.location.coordinates[1]);
//             // const lon = parseFloat(formData.location.coordinates[0]);
//             setFormData((prev) => ({
//                 ...prev,
//                 location: {
//                     type: 'Point',
//                     coordinates: name === 'lat' ? [lon, parseFloat(value)] : [parseFloat(value), lat],
//                 },
//             }));
//         } else if (name === 'sharedWith') {
//             const sharedUsers = value.split(',')
//                 .map(id => id.trim())
//                 .filter(id => isValidObjectId(id));
//             setFormData((prev) => ({
//                 ...prev,
//                 sharedWith: sharedUsers.map(id => ({ userId: id })),
//             }));
//         } else {
//             setFormData((prev) => ({ ...prev, [name]: value }));
//         }
//     };



//     return (
//         <div>
//             <h2>Add Marker</h2>
//             <form onSubmit={handleSubmit}>
//                 <input
//                     type="text"
//                     name="title"
//                     placeholder="Title"
//                     value={formData.title}
//                     onChange={handleChange}
//                     required
//                 />
//                 <textarea
//                     name="description"
//                     placeholder="Description"
//                     value={formData.description}
//                     onChange={handleChange}
//                 />
//                 <input
//                     type="text"
//                     name="lon"
//                     placeholder="Longitude"
//                     onChange={handleChange}
//                     required
//                     value={lon}
//                 />
//                 <input
//                     type="text"
//                     name="lat"
//                     placeholder="Latitude"
//                     onChange={handleChange}
//                     required
//                     value={lat}
//                 />
//                 <input
//                     type="text"
//                     name="userId"
//                     placeholder="User ID (valid ObjectId)"
//                     value={formData.userId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="mapId"
//                     placeholder="Map ID (valid ObjectId)"
//                     value={formData.mapId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="contributor"
//                     placeholder="Contributor ID (valid ObjectId)"
//                     value={formData.contributor}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="file"
//                     onChange={handleFileChange}
//                     multiple
//                     accept="image/*,video/*"
//                 />
//                 <div>
//                     <h4>Selected Media:</h4>
//                     <ul>
//                         {files.map((file, index) => (
//                             <li key={index}>
//                                 {file.name}
//                                 <button type="button" onClick={() => handleRemoveFile(file)}>Remove</button>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//                 <input
//                     type="text"
//                     name="sharedWith"
//                     placeholder="Shared User IDs (comma separated)"
//                     onChange={handleChange}
//                 />
//                 <select name="status" value={formData.status} onChange={handleChange}>
//                     <option value="private">Private</option>
//                     <option value="public">Public</option>
//                 </select>
//                 <button type="submit">Add Marker</button>
//             </form>

//             {error && <p style={{ color: 'red' }}>{error}</p>}

//             <h2>Markers</h2>
//             {loading ? (
//                 <p>Loading markers...</p>
//             ) : (
//                 <div
//                     style={{
//                         maxHeight: '400px', // Set a maximum height
//                         overflowX: 'auto', // Enable horizontal scrolling
//                         border: '1px solid #ccc', // Optional: border around the container
//                         padding: '10px', // Optional: padding inside the container
//                         marginTop: '10px', // Optional: margin above the container
//                     }}
//                 >
//                     <ul
//                         style={{
//                             display: 'flex', // Use flexbox for inline layout
//                             flexWrap: 'nowrap', // Prevent wrapping to a new line
//                             listStyle: 'none', // Remove bullet points
//                             padding: '0', // Remove default padding
//                             margin: '0', // Remove default margin
//                         }}
//                     >
//                         {markers.map(marker => {
//                             console.log(marker); // Log each marker object
//                             return (
//                             <li
//                                 key={marker._id}
//                                 style={{
//                                     minWidth: '200px', // Set a minimum width for each marker item
//                                     marginRight: '10px', // Space between items
//                                     border: '1px solid #ccc', // Border for each marker item
//                                     padding: '10px', // Padding for content
//                                 }}
//                             >
//                                 <h3>{marker.title}</h3>
//                                 <p><strong>Description:</strong> {marker.description}</p>
//                                 <p><strong>Latitude:</strong> {marker.location.coordinates[1]}</p> {/* Latitude is at index 1 */}
//                                 <p><strong>Longitude:</strong> {marker.location.coordinates[0]}</p> {/* Longitude is at index 0 */}
//                                 <div>
//                                     <strong>Media:</strong>
//                                     <ul style={{ padding: '0', listStyle: 'none' }}>
//                                         {/* {marker.media.map((media, index) => (
//                                             <li key={index}>
//                                                 <a href={media.url} target="_blank" rel="noopener noreferrer">
//                                                     {media.name}
//                                                 </a>
//                                             </li> */}
//                                             {marker.media.map((media) => (
//                                                 <li key={media._id}> {/* Use media._id for unique keys */}
//                                                     {media.type === 'image' ? (
//                                                         <img
//                                                             src={`https://${media.url}`} // Ensure the URL is valid
//                                                             alt={marker.title}
//                                                             style={{ maxWidth: '100%', height: 'auto' }} // Adjust styles as needed
//                                                         />
//                                                     ) : media.type === 'video' ? (
//                                                         <video
//                                                             controls
//                                                             style={{ maxWidth: '100%', height: 'auto' }} // Adjust styles as needed
//                                                         >
//                                                             <source src={`https://${media.url}`} type="video/mp4" />
//                                                             Your browser does not support the video tag.
//                                                         </video>
//                                                     ) : (
//                                                         <a href={media.url} target="_blank" rel="noopener noreferrer">
//                                                             Media Link
//                                                         </a>
//                                                     )}
//                                                 </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </li>
//                         );
//                         }
//                         )}
//                     </ul>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default MarkerForm;


// v4 display more info (url does not work though)
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';

// const isValidObjectId = (id) => {
//     const objectIdRegex = /^[0-9a-fA-F]{24}$/;
//     return objectIdRegex.test(id);
// };

// const MarkerForm = () => {
//     const [markers, setMarkers] = useState([]);
//     const [formData, setFormData] = useState({
//         title: '',
//         description: '',
//         location: {
//             type: 'Point',
//             coordinates: [0, 0],
//         },
//         userId: '',
//         mapId: '',
//         contributor: '',
//         media: [],
//         sharedWith: [],
//         status: 'private',
//     });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null); // Reset error before submission

//         try {
//             // Type checking
//             if (!isValidObjectId(formData.userId)) throw new Error("Invalid User ID format.");
//             if (!isValidObjectId(formData.mapId)) throw new Error("Invalid Map ID format.");
//             if (!isValidObjectId(formData.contributor)) throw new Error("Invalid Contributor ID format.");
//             if (typeof formData.title !== 'string' || formData.title.trim() === '') throw new Error("Title must be a non-empty string.");
//             if (typeof formData.description !== 'string') throw new Error("Description must be a string.");
//             if (!Array.isArray(formData.sharedWith) || formData.sharedWith.length === 0) throw new Error("SharedWith must be a non-empty array.");
//             if (!formData.media.length) throw new Error("At least one media file must be provided.");

//             // Construct the payload
//             const payload = {
//                 ...formData,
//                 userId: formData.userId.trim(),
//                 mapId: formData.mapId.trim(),
//                 contributor: formData.contributor.trim(),
//                 location: formData.location,
//                 sharedWith: formData.sharedWith.map(user => user.userId),
//             };

//             console.log('Submitting payload:', payload); // Log the payload

//             // Send the request
//             await axios.post(apiBaseUrl, payload);
//             fetchMarkers();

//             // Reset form data
//             setFormData({
//                 title: '',
//                 description: '',
//                 location: { type: 'Point', coordinates: [0, 0] },
//                 userId: '',
//                 mapId: '',
//                 contributor: '',
//                 media: [],
//                 sharedWith: [],
//                 status: 'private',
//             });
//         } catch (error) {
//             setError(`Error submitting marker: ${error.message}`);
//             console.error('Error submitting marker:', error.response?.data || error.message);
//         }
//     };

//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);
//         try {
//             const response = await axios.get(apiBaseUrl);
//             setMarkers(response.data);
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers();
//     }, []);

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (name === 'lat' || name === 'lon') {
//             const lat = parseFloat(formData.location.coordinates[1]);
//             const lon = parseFloat(formData.location.coordinates[0]);
//             setFormData((prev) => ({
//                 ...prev,
//                 location: {
//                     type: 'Point',
//                     coordinates: name === 'lat' ? [lon, parseFloat(value)] : [parseFloat(value), lat],
//                 },
//             }));
//         } else if (name === 'sharedWith') {
//             const sharedUsers = value.split(',')
//                 .map(id => id.trim())
//                 .filter(id => isValidObjectId(id));
//             setFormData((prev) => ({
//                 ...prev,
//                 sharedWith: sharedUsers.map(id => ({ userId: id })),
//             }));
//         } else {
//             setFormData((prev) => ({ ...prev, [name]: value }));
//         }
//     };

//     const handleFileChange = (e) => {
//         const files = Array.from(e.target.files);
//         const newMedia = files.map(file => ({
//             url: URL.createObjectURL(file),
//             type: file.type.startsWith('image/') ? 'image' : 'video',
//             file: file,
//             name: file.name,
//         }));

//         setFormData((prev) => ({
//             ...prev,
//             media: [...prev.media, ...newMedia],
//         }));
//     };

//     const handleRemoveMedia = (fileName) => {
//         setFormData((prev) => ({
//             ...prev,
//             media: prev.media.filter(media => media.name !== fileName),
//         }));
//     };

//     return (
//         <div>
//             <h2>Add Marker</h2>
//             <form onSubmit={handleSubmit}>
//                 <input
//                     type="text"
//                     name="title"
//                     placeholder="Title"
//                     value={formData.title}
//                     onChange={handleChange}
//                     required
//                 />
//                 <textarea
//                     name="description"
//                     placeholder="Description"
//                     value={formData.description}
//                     onChange={handleChange}
//                 />
//                 <input
//                     type="text"
//                     name="lon"
//                     placeholder="Longitude"
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="lat"
//                     placeholder="Latitude"
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="userId"
//                     placeholder="User ID (valid ObjectId)"
//                     value={formData.userId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="mapId"
//                     placeholder="Map ID (valid ObjectId)"
//                     value={formData.mapId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="contributor"
//                     placeholder="Contributor ID (valid ObjectId)"
//                     value={formData.contributor}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="file"
//                     multiple
//                     accept="image/*,video/*"
//                     onChange={handleFileChange}
//                 />
//                 <div>
//                     <h4>Selected Media:</h4>
//                     <ul>
//                         {formData.media.map((media) => (
//                             <li key={media.name}>
//                                 {media.name}
//                                 <button type="button" onClick={() => handleRemoveMedia(media.name)}>Remove</button>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//                 <input
//                     type="text"
//                     name="sharedWith"
//                     placeholder="Shared User IDs (comma separated)"
//                     onChange={handleChange}
//                 />
//                 <select name="status" value={formData.status} onChange={handleChange}>
//                     <option value="private">Private</option>
//                     <option value="public">Public</option>
//                 </select>
//                 <button type="submit">Add Marker</button>
//             </form>

//             {error && <p style={{ color: 'red' }}>{error}</p>}

//             <h2>Markers</h2>
// {loading ? (
//     <p>Loading markers...</p>
// ) : (
//     <div
//         style={{
//             maxHeight: '400px', // Set a maximum height
//             overflowX: 'auto', // Enable horizontal scrolling
//             border: '1px solid #ccc', // Optional: border around the container
//             padding: '10px', // Optional: padding inside the container
//             marginTop: '10px', // Optional: margin above the container
//         }}
//     >
//         <ul
//             style={{
//                 display: 'flex', // Use flexbox for inline layout
//                 flexWrap: 'nowrap', // Prevent wrapping to a new line
//                 listStyle: 'none', // Remove bullet points
//                 padding: '0', // Remove default padding
//                 margin: '0', // Remove default margin
//             }}
//         >
//             {markers.map(marker => (
//                 <li
//                     key={marker._id}
//                     style={{
//                         minWidth: '200px', // Set a minimum width for each marker item
//                         marginRight: '10px', // Space between items
//                         border: '1px solid #ccc', // Border for each marker item
//                         padding: '10px', // Padding for content
//                     }}
//                 >
//                     <h3>{marker.title}</h3>
//                     <p><strong>Description:</strong> {marker.description}</p>
//                     {/* <p><strong>Location:</strong> Longitude: {marker.location.coordinates[0]}, Latitude: {marker.location.coordinates[1]}</p>
//                     <p><strong>Contributor ID:</strong> {marker.contributor}</p>
//                     <p><strong>Status:</strong> {marker.status}</p>
//                     <p><strong>Shared With:</strong> {marker.sharedWith.join(', ')}</p>
//                     <p><strong>Created By:</strong> {marker.userId}</p> */}
//                     <div>
//                         <strong>Media:</strong>
//                         <ul style={{ padding: '0', listStyle: 'none' }}>
//                             {marker.media.map((media, index) => (
//                                 <li key={index}>
//                                     <a href={media.url} target="_blank" rel="noopener noreferrer">
//                                         {media.name}
//                                     </a>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 </li>
//             ))}
//         </ul>
//     </div>
// )}

//         </div>
//     );
// };

// export default MarkerForm;




// Ok (UI work but backend has prob) v3 fix upload media button
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// // const apiBaseUrl = 'http://192.168.0.102:3005/api/markers/';
// const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.102:3005/api/markers/';


// const isValidObjectId = (id) => {
//     const objectIdRegex = /^[0-9a-fA-F]{24}$/;
//     return objectIdRegex.test(id);
// };

// const MarkerForm = () => {
//     const [markers, setMarkers] = useState([]);
//     const [formData, setFormData] = useState({
//         title: '',
//         description: '',
//         location: {
//             type: 'Point',
//             coordinates: [0, 0],
//         },
//         userId: '',
//         mapId: '',
//         contributor: '',
//         media: [],
//         sharedWith: [],
//         status: 'private',
//     });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);


//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null); // Reset error before submission

//         try {
//             // Type checking
//             if (!isValidObjectId(formData.userId)) {
//                 throw new Error("Invalid User ID format.");
//             }
//             if (!isValidObjectId(formData.mapId)) {
//                 throw new Error("Invalid Map ID format.");
//             }
//             if (!isValidObjectId(formData.contributor)) {
//                 throw new Error("Invalid Contributor ID format.");
//             }
//             if (typeof formData.title !== 'string' || formData.title.trim() === '') {
//                 throw new Error("Title must be a non-empty string.");
//             }
//             if (typeof formData.description !== 'string') {
//                 throw new Error("Description must be a string.");
//             }
//             if (!Array.isArray(formData.sharedWith) || formData.sharedWith.length === 0) {
//                 throw new Error("SharedWith must be a non-empty array.");
//             }
//             if (!formData.media.length) {
//                 throw new Error("At least one media file must be provided.");
//             }

//             // Construct the payload
//             const payload = {
//                 ...formData,
//                 userId: formData.userId.trim(),
//                 mapId: formData.mapId.trim(),
//                 contributor: formData.contributor.trim(),
//                 location: formData.location, // Pass location directly as an object
//                 sharedWith: formData.sharedWith.map(user => user.userId), // Prepare sharedWith as an array of userIds
//             };

//             console.log('Submitting payload:', payload); // Log the payload

//             // Send the request
//             await axios.post(apiBaseUrl, payload);
//             fetchMarkers();

//             // Reset form data
//             setFormData({
//                 title: '',
//                 description: '',
//                 location: { type: 'Point', coordinates: [0, 0] },
//                 userId: '',
//                 mapId: '',
//                 contributor: '',
//                 media: [],
//                 sharedWith: [],
//                 status: 'private',
//             });
//         } catch (error) {
//             setError(`Error submitting marker: ${error.message}`); // Log error message
//             console.error('Error submitting marker:', error.response?.data || error.message); // Log the error response
//         }
//     };



//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);
//         try {
//             const response = await axios.get(apiBaseUrl);
//             setMarkers(response.data);
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers();
//     }, []);

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (name === 'lat' || name === 'lon') {
//             const lat = parseFloat(formData.location.coordinates[1]);
//             const lon = parseFloat(formData.location.coordinates[0]);
//             setFormData((prev) => ({
//                 ...prev,
//                 location: {
//                     type: 'Point',
//                     coordinates: name === 'lat' ? [lon, parseFloat(value)] : [parseFloat(value), lat],
//                 },
//             }));
//         } else if (name === 'sharedWith') {
//             const sharedUsers = value.split(',')
//                 .map(id => id.trim())
//                 .filter(id => isValidObjectId(id));
//             setFormData((prev) => ({
//                 ...prev,
//                 sharedWith: sharedUsers.map(id => ({ userId: id })),
//             }));
//         } else {
//             setFormData((prev) => ({ ...prev, [name]: value }));
//         }
//     };

//     const handleFileChange = (e) => {
//         const files = Array.from(e.target.files);
//         const newMedia = files.map(file => ({
//             url: URL.createObjectURL(file),
//             type: file.type.startsWith('image/') ? 'image' : 'video',
//             file: file,
//             name: file.name,
//         }));

//         setFormData((prev) => ({
//             ...prev,
//             media: [...prev.media, ...newMedia],
//         }));
//     };

//     const handleRemoveMedia = (fileName) => {
//         setFormData((prev) => ({
//             ...prev,
//             media: prev.media.filter(media => media.name !== fileName),
//         }));
//     };





//     return (
//         <div>
//             <h2>Add Marker</h2>
//             <form onSubmit={handleSubmit}>
//                 <input
//                     type="text"
//                     name="title"
//                     placeholder="Title"
//                     value={formData.title}
//                     onChange={handleChange}
//                     required
//                 />
//                 <textarea
//                     name="description"
//                     placeholder="Description"
//                     value={formData.description}
//                     onChange={handleChange}
//                 />
//                 <input
//                     type="text"
//                     name="lon"
//                     placeholder="Longitude"
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="lat"
//                     placeholder="Latitude"
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="userId"
//                     placeholder="User ID (valid ObjectId)"
//                     value={formData.userId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="mapId"
//                     placeholder="Map ID (valid ObjectId)"
//                     value={formData.mapId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="contributor"
//                     placeholder="Contributor ID (valid ObjectId)"
//                     value={formData.contributor}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="file"
//                     multiple
//                     accept="image/*,video/*"
//                     onChange={handleFileChange}
//                 />
//                 <div>
//                     <h4>Selected Media:</h4>
//                     <ul>
//                         {formData.media.map((media) => (
//                             <li key={media.name}>
//                                 {media.name}
//                                 <button type="button" onClick={() => handleRemoveMedia(media.name)}>Remove</button>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//                 <input
//                     type="text"
//                     name="sharedWith"
//                     placeholder="Shared User IDs (comma separated)"
//                     onChange={handleChange}
//                 />
//                 <select name="status" value={formData.status} onChange={handleChange}>
//                     <option value="private">Private</option>
//                     <option value="public">Public</option>
//                 </select>
//                 <button type="submit">Add Marker</button>
//             </form>

//             {error && <p style={{ color: 'red' }}>{error}</p>}

//             <h2>Markers</h2>
//             {loading ? (
//                 <p>Loading markers...</p>
//             ) : (
//                 <ul>
//                     {markers.map(marker => (
//                         <li key={marker._id}>{marker.title}</li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     );
// };

// export default MarkerForm;





// // OK v2
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const apiBaseUrl = 'http://192.168.0.102:3005/api/markers/';


// const isValidObjectId = (id) => {
//     const objectIdRegex = /^[0-9a-fA-F]{24}$/;
//     return objectIdRegex.test(id);
// };

// const MarkerForm = () => {
//     const [markers, setMarkers] = useState([]);
//     const [formData, setFormData] = useState({
//         title: '',
//         description: '',
//         location: {
//             type: 'Point',
//             coordinates: [0, 0],
//         },
//         userId: '',
//         mapId: '',
//         contributor: '',
//         media: [],
//         sharedWith: [],
//         status: 'private',
//     });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);


//     const fetchMarkers = async () => {
//         setLoading(true);
//         setError(null);
//         try {
//             const response = await axios.get(apiBaseUrl);
//             setMarkers(response.data);
//         } catch (error) {
//             setError('Error fetching markers');
//             console.error('Error fetching markers:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers();
//     }, []);

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (name === 'lat' || name === 'lon') {
//             const lat = parseFloat(formData.location.coordinates[1]);
//             const lon = parseFloat(formData.location.coordinates[0]);
//             setFormData((prev) => ({
//                 ...prev,
//                 location: {
//                     type: 'Point',
//                     coordinates: name === 'lat' ? [lon, parseFloat(value)] : [parseFloat(value), lat],
//                 },
//             }));
//         } else if (name === 'media') {
//             const media = value.split(',').map((url) => ({ url: url.trim(), type: 'image' }));
//             setFormData((prev) => ({ ...prev, media }));
//         } else if (name === 'sharedWith') {
//             const sharedUsers = value.split(',')
//                 .map(id => id.trim())
//                 .filter(id => isValidObjectId(id));
//             setFormData((prev) => ({
//                 ...prev,
//                 sharedWith: sharedUsers.map(id => ({ userId: id })),
//             }));
//         } else {
//             setFormData((prev) => ({ ...prev, [name]: value }));
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null); // Reset error before submission

//         try {
//             const payload = {
//                 ...formData,
//                 userId: formData.userId.trim(),
//                 mapId: formData.mapId.trim(),
//                 contributor: formData.contributor.trim(),
//             };

//             console.log('Submitting payload:', payload); // Log the payload

//             await axios.post(apiBaseUrl, payload);
//             fetchMarkers();
//             setFormData({
//                 title: '',
//                 description: '',
//                 location: { type: 'Point', coordinates: [0, 0] },
//                 userId: '',
//                 mapId: '',
//                 contributor: '',
//                 media: [],
//                 sharedWith: [],
//                 status: 'private',
//             });
//         } catch (error) {
//             setError('Error submitting marker');
//             console.error('Error submitting marker:', error.response?.data || error.message); // Log the error response
//         }
//     };

//     return (
//         <div>
//             <h2>Add Marker</h2>
//             <form onSubmit={handleSubmit}>
//                 <input
//                     type="text"
//                     name="title"
//                     placeholder="Title"
//                     value={formData.title}
//                     onChange={handleChange}
//                     required
//                 />
//                 <textarea
//                     name="description"
//                     placeholder="Description"
//                     value={formData.description}
//                     onChange={handleChange}
//                 />
//                 <input
//                     type="text"
//                     name="lon"
//                     placeholder="Longitude"
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="lat"
//                     placeholder="Latitude"
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="userId"
//                     placeholder="User ID (valid ObjectId)"
//                     value={formData.userId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="mapId"
//                     placeholder="Map ID (valid ObjectId)"
//                     value={formData.mapId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="contributor"
//                     placeholder="Contributor ID (valid ObjectId)"
//                     value={formData.contributor}
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="media"
//                     placeholder="Media URLs (comma separated)"
//                     onChange={handleChange}
//                 />
//                 <input
//                     type="text"
//                     name="sharedWith"
//                     placeholder="Shared User IDs (comma separated)"
//                     onChange={handleChange}
//                 />
//                 <select name="status" value={formData.status} onChange={handleChange}>
//                     <option value="private">Private</option>
//                     <option value="public">Public</option>
//                 </select>
//                 <button type="submit">Add Marker</button>
//             </form>

//             {error && <p style={{ color: 'red' }}>{error}</p>}

//             <h2>Markers</h2>
//             {loading ? (
//                 <p>Loading markers...</p>
//             ) : (
//                 <ul>
//                     {markers.map(marker => (
//                         <li key={marker._id}>{marker.title}</li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     );
// };

// export default MarkerForm;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const MarkerForm = () => {
//     const [markers, setMarkers] = useState([]);
//     const [formData, setFormData] = useState({
//         title: '',
//         description: '',
//         location: {
//             type: 'Point',
//             coordinates: [0, 0], // default coordinates
//         },
//         userId: '', // replace with actual user ID
//     });

//     // Update the base URL for Axios requests
//     const apiBaseUrl = 'http://localhost:3005/api/markers';

//     const fetchMarkers = async () => {
//         try {
//             const response = await axios.get(apiBaseUrl);
//             setMarkers(response.data);
//         } catch (error) {
//             console.error('Error fetching markers:', error);
//         }
//     };

//     useEffect(() => {
//         fetchMarkers();
//     }, []);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         if (name === 'coordinates') {
//             const coords = value.split(',').map(Number);
//             setFormData((prev) => ({
//                 ...prev,
//                 location: { ...prev.location, coordinates: coords },
//             }));
//         } else {
//             setFormData((prev) => ({
//                 ...prev,
//                 [name]: value,
//             }));
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await axios.post(apiBaseUrl, formData);
//             fetchMarkers(); // Fetch updated markers after submission
//             setFormData({
//                 title: '',
//                 description: '',
//                 location: { type: 'Point', coordinates: [0, 0] },
//                 userId: '',
//             });
//         } catch (error) {
//             console.error('Error submitting marker:', error); // Log the error
//         }
//     };

//     return (
//         <div>
//             <h2>Add Marker</h2>
//             <form onSubmit={handleSubmit}>
//                 <input
//                     type="text"
//                     name="title"
//                     placeholder="Title"
//                     value={formData.title}
//                     onChange={handleChange}
//                     required
//                 />
//                 <textarea
//                     name="description"
//                     placeholder="Description"
//                     value={formData.description}
//                     onChange={handleChange}
//                 />
//                 <input
//                     type="text"
//                     name="coordinates"
//                     placeholder="Coordinates (lon, lat)"
//                     onChange={handleChange}
//                     required
//                 />
//                 <input
//                     type="text"
//                     name="userId"
//                     placeholder="User ID"
//                     value={formData.userId}
//                     onChange={handleChange}
//                     required
//                 />
//                 <button type="submit">Add Marker</button>
//             </form>

//             <h2>Markers</h2>
//             <ul>
//                 {markers.map(marker => (
//                     <li key={marker._id}>{marker.title}</li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default MarkerForm;


