
module.exports = {
  'secretKey1': process.env.SECRET_KEY_1 || '16665-66690-09546-23321',
  'mongoUrl1': process.env.MONGO_URL_1 || 'mongodb://localhost:27017',
  // 'getAllURL': process.env.GET_ALL_URL || 'https://192.168.1.217:3005/', // https://comtruaduytan.com/
  'getAllURL': process.env.GET_ALL_URL || ' https://comtruaduytan.com/', // https://comtruaduytan.com/
  'interval': parseInt(process.env.INTERVAL, 10) || 4000
}

// module.exports = {
//   'secretKey1': '16665-66690-09546-23321',
//   'mongoUrl1' : 'mongodb://localhost:27017',
//   'getAllURL': "https://192.168.1.217:3005/",
//   'interval': 4000
// }

// module.exports = {
//   'secretKey1': '16665-66690-09546-23321',
//   'mongoUrl1' : 'mongodb://192.168.1.107:27017,192.168.1.108:27017,192.168.1.132:27017/DB4app2?replicaSet=myreplica',
//   // 'getAllURL': "http://192.168.1.246:3005/",
//   // 'getAllURL': "http://192.168.1.107:3005/",
//   'getAllURL':  "http://27.72.101.197:80/be/",
//   // "http://localhost:82/api/",
//   'interval': 4000
// }