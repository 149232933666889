


// // failed v2 query all user -> this is only display the item not fetch it, other location
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import './GridLayout.css';

// const GridLayout = () => {
//   const [items, setItems] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // Fetch data from the `/users` route
//     axios.get('/allusers')
//       .then((response) => {
//         setItems(response.data.users); // Assuming the data is under 'users'
//         setLoading(false);
//       })
//       .catch((err) => {
//         setError(err);
//         setLoading(false);
//       });
//   }, []);

//   // If loading, display a loading message
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   // If there is an error, display an error message
//   if (error) {
//     return <div>Error loading data: {error.message}</div>;
//   }

//   return (
//     <div className="grid-container">
//       {items.map((item) => {
//         console.log("######## ExplorePage.js #####");
//         console.log(item);

//         // Safely check if media exists and has at least one item
//         const imageUrl = item.media && item.media.length > 0 ? 'https://' + item.media[0].url : null;

//         return (
//           <div className="grid-item" key={item._id}>
//             {/* Access and display individual properties of each item */}
//             {imageUrl ? (
//               <img
//                 src={imageUrl}  // Use the safe image URL
//                 alt={item.city}  // Use 'city' for the alt text
//                 style={{ width: '100%', borderRadius: '8px' }}
//               />
//             ) : (
//               <div style={{ width: '100%', height: '200px', backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
//                 {/* Display a placeholder if no media URL is available */}
//                 <p>No image available</p>
//               </div>
//             )}
//             <h3>{item.city}</h3>   {/* Render 'city' from the object */}
//             <p>{item.address}</p>  {/* Render 'address' from the object */}
//             <p>Price: ${item.price}</p> {/* Render 'price' */}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default GridLayout;



// ok  v1.4 onclick open UserPage, add header
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import Slider from 'react-slick'; // Import Slider for carousel functionality
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './UserList.css'; // Import your CSS file for custom styling
import './GridLayout.css';
import Header from "./Header";
import { AppContainer } from "../App.styles2";

// ref insta, tiktok
const GridLayout = ({ items }) => {
    // console.log("######## ExplorePage.js #####");
    // console.log(items); // Log the items once at the start

    return (
      <div className="grid-container">
        {items.map((item) => {
        //   console.log("######## ExplorePage.js #####");
        //   console.log(item);

          const imageUrl = item.media && item.media.length > 0 ? 'https://'+ item.media[0].url : null;

          return (
            <div className="grid-item" key={item._id}>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt={item.city}
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              ) : (
                <div style={{ width: '100%', height: '200px', backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
                  <p>No image available</p>
                </div>
              )}
              <h3>{item.city}</h3>
              <p>{item.address}</p>
              <p>Price: ${item.price}</p>
            </div>
          );
        })}
      </div>
    );
};

const UserList = ({ items }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchUsers = async () => {
      const url = config.getAllURL + 'users/allusers';

      try {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (response.data.success) {
          setUsers(response.data.users);
        } else {
          console.error("Failed to fetch users:", response.data);
          setError("Failed to load users.");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("An error occurred while fetching users.");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleUserIconClick = (userId) => {
    // Navigate to UserPage with the specific userId
    navigate(`/user/${userId}`);
  };

  // Slick carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <AppContainer>
    <Header/>
    <div className="user-list">
      <br></br>
      {/* <h1>All Users</h1> */}
      {users.length === 0 ? (
        <p>No users found.</p>
      ) : (
        <Slider {...settings} className="user-carousel">
          {users.map(user => (
            <div className="user-card" key={user._id}>
              <div className="user-icon" onClick={() => handleUserIconClick(user._id)}>
                <img
                  src={user.profilePicture || 'https://obj.tang17so82duytan.com/demo-map//default user2.png'}
                  alt={user.username}
                  className="user-image"
                />
              </div>
              <p>{user.username}</p>
            </div>
          ))}
        </Slider>
      )}
      <GridLayout items={items}></GridLayout>
    </div>
    </AppContainer>
  );
};

export default UserList;


// ok v1.3 add user map and cart list below
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import config from '../config'; // Ensure this path matches your actual config file
// import Slider from 'react-slick'; // Import Slider for carousel functionality
// import './UserList.css'; // Import your CSS file for custom styling
// import './GridLayout.css';


// // ref insta, tiktok
// // like add a row of round user pic on top, use the github proj on gg doc
// const GridLayout = ({ items }) => {
//     console.log("######## ExplorePage.js #####");
//     console.log(items); // Log the items once at the start

//     // MinIO Base URL (adjust it as per your MinIO setup)
//     // const minioBaseURL = "https://<your-minio-endpoint>/<bucket-name>";

//     return (
//       <div className="grid-container">
//         {items.map((item) => {
//           // Log each item inside the map function
//           console.log("######## ExplorePage.js #####");
//           console.log(item);

//           // Safely check if media exists and has at least one item
//           const imageUrl = item.media && item.media.length > 0 ? 'https://'+ item.media[0].url : null;

//           return (
//             <div className="grid-item" key={item._id}>
//               {/* Access and display individual properties of each item */}
//               {imageUrl ? (
//                 <img
//                   src={imageUrl}  // Use the safe image URL
//                   alt={item.city}  // Use 'city' for the alt text
//                   style={{ width: '100%', borderRadius: '8px' }}
//                 />
//               ) : (
//                 <div style={{ width: '100%', height: '200px', backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
//                   {/* Display a placeholder if no media URL is available */}
//                   <p>No image available</p>
//                 </div>
//               )}
//               <h3>{item.city}</h3>   {/* Render 'city' from the object */}
//               <p>{item.address}</p>  {/* Render 'address' from the object */}
//               <p>Price: ${item.price}</p> {/* Render 'price' */}
//             </div>
//           );
//         })}
//       </div>
//     );
// };



// const UserList = ( { items } ) => {
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       const url = config.getAllURL + 'users/allusers';

//       try {
//         const response = await axios.get(url, {
//           headers: {
//             'Content-Type': 'application/json',
//             // Add Authorization header if needed:
//             // 'Authorization': `Bearer ${localStorage.getItem('token')}`
//           }
//         });

//         if (response.data.success) {
//           setUsers(response.data.users);
//         } else {
//           console.error("Failed to fetch users:", response.data);
//           setError("Failed to load users.");
//         }
//       } catch (error) {
//         console.error("Error fetching users:", error);
//         setError("An error occurred while fetching users.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUsers();
//   }, []);

//   if (loading) {
//     return <div>Loading users...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   // Slick carousel settings
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="user-list">
//       <h1>All Users</h1>
//       {users.length === 0 ? (
//         <p>No users found.</p>
//       ) : (
//         <Slider {...settings} className="user-carousel">
//           {users.map(user => (
//             <div className="user-card" key={user._id}>
//               <div className="user-icon">
//                 {/* Here you can use a placeholder or user's image */}
//                 <img
//                   src={user.profilePicture || 'https://obj.tang17so82duytan.com/demo-map//default user2.png'}
//                   alt={user.username}
//                   className="user-image"
//                 />
//               </div>
//               <p>{user.username}</p>
//             </div>
//           ))}
//         </Slider>
//       )}
//       <GridLayout items={items}></GridLayout>
//     </div>
//   );
// };

// export default UserList;




// ok v1.2 make user list a list of round icon,
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import config from '../config'; // Ensure this path matches your actual config file
// import Slider from 'react-slick'; // Import Slider for carousel functionality
// import './UserList.css'; // Import your CSS file for custom styling

// const UserList = () => {
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       const url = config.getAllURL + 'users/allusers';

//       try {
//         const response = await axios.get(url, {
//           headers: {
//             'Content-Type': 'application/json',
//             // Add Authorization header if needed:
//             // 'Authorization': `Bearer ${localStorage.getItem('token')}`
//           }
//         });

//         if (response.data.success) {
//           setUsers(response.data.users);
//         } else {
//           console.error("Failed to fetch users:", response.data);
//           setError("Failed to load users.");
//         }
//       } catch (error) {
//         console.error("Error fetching users:", error);
//         setError("An error occurred while fetching users.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUsers();
//   }, []);

//   if (loading) {
//     return <div>Loading users...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   // Slick carousel settings
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="user-list">
//       <h1>All Users</h1>
//       {users.length === 0 ? (
//         <p>No users found.</p>
//       ) : (
//         <Slider {...settings} className="user-carousel">
//           {users.map(user => (
//             <div className="user-card" key={user._id}>
//               <div className="user-icon">
//                 {/* Here you can use a placeholder or user's image */}
//                 <img
//                   src={user.profilePicture || 'https://obj.tang17so82duytan.com/demo-map//default user2.png'}
//                   alt={user.username}
//                   className="user-image"
//                 />
//               </div>
//               <p>{user.username}</p>
//             </div>
//           ))}
//         </Slider>
//       )}
//     </div>
//   );
// };

// export default UserList;




// ok v1.1 change to display users
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import config from '../config'; // Ensure this path matches your actual config file
// import './UserList.css'; // Import your CSS file for custom styling

// const UserList = () => {
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       const url = config.getAllURL + 'users/allusers';

//       try {
//         const response = await axios.get(url, {
//           headers: {
//             'Content-Type': 'application/json',
//             // Add Authorization header if needed:
//             // 'Authorization': `Bearer ${localStorage.getItem('token')}`
//           }
//         });

//         if (response.data.success) {
//           setUsers(response.data.users);
//         } else {
//           console.error("Failed to fetch users:", response.data);
//           setError("Failed to load users.");
//         }
//       } catch (error) {
//         console.error("Error fetching users:", error);
//         setError("An error occurred while fetching users.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUsers();
//   }, []);

//   if (loading) {
//     return <div>Loading users...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="user-list">
//       <h1>All Users</h1>
//       {users.length === 0 ? (
//         <p>No users found.</p>
//       ) : (
//         <div className="user-grid">
//           {users.map(user => (
//             <div className="user-card" key={user._id}>
//               <h2>{user.username}</h2>
//               <p>Email: {user.email}</p>
//               <p>City: {user.city}</p>
//               {/* Add other user properties as needed */}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserList;



// ok v1 query all locations
// import React from 'react';
// import './GridLayout.css';


// // ref insta, tiktok
// // like add a row of round user pic on top, use the github proj on gg doc
// const GridLayout = ({ items }) => {
//     console.log("######## ExplorePage.js #####");
//     console.log(items); // Log the items once at the start

//     // MinIO Base URL (adjust it as per your MinIO setup)
//     // const minioBaseURL = "https://<your-minio-endpoint>/<bucket-name>";

//     return (
//       <div className="grid-container">
//         {items.map((item) => {
//           // Log each item inside the map function
//           console.log("######## ExplorePage.js #####");
//           console.log(item);

//           // Safely check if media exists and has at least one item
//           const imageUrl = item.media && item.media.length > 0 ? 'https://'+ item.media[0].url : null;

//           return (
//             <div className="grid-item" key={item._id}>
//               {/* Access and display individual properties of each item */}
//               {imageUrl ? (
//                 <img
//                   src={imageUrl}  // Use the safe image URL
//                   alt={item.city}  // Use 'city' for the alt text
//                   style={{ width: '100%', borderRadius: '8px' }}
//                 />
//               ) : (
//                 <div style={{ width: '100%', height: '200px', backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
//                   {/* Display a placeholder if no media URL is available */}
//                   <p>No image available</p>
//                 </div>
//               )}
//               <h3>{item.city}</h3>   {/* Render 'city' from the object */}
//               <p>{item.address}</p>  {/* Render 'address' from the object */}
//               <p>Price: ${item.price}</p> {/* Render 'price' */}
//             </div>
//           );
//         })}
//       </div>
//     );
//   };

//   export default GridLayout;

