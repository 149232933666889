import styled from 'styled-components';

export const AppContainer = styled.div`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background-color: #f4f4f9;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
  }

  // .listings {
  //   width: 30%;
  //   margin-left: 70.5%;
  //   position: relative;
  //   z-index: 3;
  //   background-color: #fff;
  //   border-radius: 6px;
  //   box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  //   overflow: hidden;
  //   transition: transform 0.3s ease-in-out;
  // }


.listings {
  position: fixed; /* Use fixed positioning to position it relative to the screen */
  top: 0; /* Align it to the top of the screen */
  right: 0; /* Align it to the right side of the screen */
  width: 30%; /* Set the width to 30% */
  height: 100%; /* Make it take the full height of the screen */
  z-index: 3; /* Ensure it's on top of other elements */
  background-color: rgba(0, 0, 0, 0); /* Transparent background */
  border-radius: 6px; /* Rounded corners */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  overflow-y: auto; /* Enable vertical scrolling */
  transition: transform 0.3s ease-in-out; /* Smooth transition for transform property */
}


  .cards {
    max-width: 100%;
  }

  .cards-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2px; /* Reduced the gap between cards */
    padding: 2px;
    box-sizing: border-box;
    // background-color: #fff;
    background-color: rgba(100, 100, 100, .35);
    &.is-empty {
      background-color: #eaeaea;
    }
  }

  .card {
    flex: 1 1 calc(33.333% - 8px); /* Adjusted the gap size by reducing space here */
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    // background-color: #fff;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: hidden;
    margin-bottom: 0px; /* Reduce bottom margin for less space between rows */

    &:hover {
      transform: translateY(-8px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    }

    .index {
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      padding: 8px 15px;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 14px;
      border-radius: 20px;
    }

    .price {
      margin: 10px 0;
      padding: 8px 0;
      font-weight: 600;
      color: #0077ff;
    }

    .details {
      padding: 10px;
    }

    .features {
      list-style: none;
      display: flex;
      gap: 16px;
      padding: 0;
      margin: 0;
      flex-wrap: wrap;

      li {
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-right: 10px;
        font-size: 14px;
        color: #666;

        span {
          display: none;
        }

        &.icon-bed {
          background: url(assets/images/bed-left-side.svg) no-repeat;
          background-size: contain;
        }
        &.icon-bath {
          background: url(assets/images/bathtub.svg) no-repeat;
          background-size: contain;
        }
        &.icon-car {
          background: url(assets/images/car-compact.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    &.is-active {
      border-color: #1078ff;
      .index {
        background-color: #1078ff;
      }
    }
  }

  .warning {
    font-size: 2rem;
    text-align: center;
    margin-top: 15%;
    color: #444;
    img {
      max-width: 80px;
      margin-bottom: 20px;
    }
  }

  .credits {
    text-align: center;
    padding: 40px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 1;
    }
    a {
      color: #4b4668;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: #1078ff;
      }
    }
  }

  // .mapContainer {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 30%;
  //   z-index: 2;
  //   border-radius: 6px;
  //   overflow: hidden;
  // }

  // #map {
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(173, 216, 230, 0.4);
  // }

.mapContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; /* Change from 30% to 0 to make it full width */
  z-index: 2;
  border-radius: 0; /* Optional: remove border-radius for a full screen effect */
  overflow: hidden;
}

#map {
  width: 100%;
  height: 100%;
  background-color: rgba(173, 216, 230, 0.4);
}

  .pin {
    cursor: pointer;
    transition: transform 0.2s ease;
    &:hover {
      transform: scale(1.2);
    }
  }

  header {
    display: flex;
    text-align: center;
    flex-direction: row;
    padding: 5px 15px;
    position: relative;
    // position: fixed;
    // background-color:  #e0e0e0;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    z-index: 2;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);  /* Light shadow */


    img {
      max-width: 40px;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 18px;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 10px;
    }

    .btn-clear {
      background: transparent;
      border: none;
      text-decoration: underline;
      color: #1078ff;
      font-weight: 600;
      transition: color 0.2s;

      &:hover,
      &:active {
        text-decoration: none;
        color: #004d7f;
      }
  }

// horizontal is ok but the img does not fill the container
// header {
//   text-align: center;
//   display: flex;
//   flex-direction: row;  /* Change to row for horizontal alignment */
//   align-items: center;  /* Align items vertically in the center */
//   justify-content: space-between;  /* Space out items horizontally */
//   padding: 20px 30px;
//   position: relative;
//   border-radius: 6px;
//   z-index: 2;
//   box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Light shadow */

//   img {
//     max-width: 40px;
//     margin-bottom: 0;  /* Remove margin to keep aligned horizontally */
//     margin-right: 10px;  /* Add right margin to space out from h1 */
//   }

//   h1 {
//     font-size: 18px;
//     text-transform: uppercase;
//     color: #fff;
//     margin-bottom: 0;  /* Remove bottom margin */
//     margin-right: 20px;  /* Space out from the next elements */
//   }

//   .btn-clear {
//     background: transparent;
//     border: none;
//     text-decoration: underline;
//     color: #1078ff;
//     font-weight: 600;
//     transition: color 0.2s;

//     &:hover,
//     &:active {
//       text-decoration: none;
//       color: #004d7f;
//     }
//   }
// }


    @media (min-width: 992px) {
      h1 {
        font-size: 20px;
      }
      img {
        max-width: 70px;
      }
    }
  }

.user-status {
  display: flex; /* Flexbox for easy alignment */
  flex-direction: column; /* Stack the elements vertically */
  align-items: flex-start; /* Align the items to the start (left) */
  padding: 20px; /* Add some space around the text */
  width: 100%; /* Ensure it takes the full width of its container */
  box-sizing: border-box; /* Ensure padding doesn't overflow the container */
}

.user-status span {
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Ensure long words break and wrap to the next line */
}

@media (max-width: 768px) {
  /* Header positioning for small screens */
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3vh; /* Adjust height of the header */
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 9999; /* Ensure it's above other elements */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  }

  /* Adjust the mapContainer to avoid overlap with the header */
  .mapContainer {
    position: fixed;
    top: 0; /* Offset by the header height */
    left: 0;
    right: 0;
    bottom: 0; /* Take full height below the header */
    z-index: 2;
    overflow: hidden;
  }

  #map {
    width: 100%;
    height: 100%;
    background-color: rgba(173, 216, 230, 0.4); /* Optional background color for map */
  }

  /* Adjust listings positioning on small screens */
  .listings {
    position: fixed;
    top: 65%; /* Start listings slightly below the header */
    right: 0;
    width: 100%;
    height: 90vh; /* Adjust to take up remaining space below the header */
    z-index: 3;
    background-color: rgba(0, 0, 0, .7); /* Optional background color */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    overflow-y: auto; /* Ensure scrolling is enabled */
    padding-bottom: 10px; /* Optional: add padding to bottom for better spacing */
  }

  .cards-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    padding: 0px;
    background-color: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
  }

  .card {
    flex: 1 1 calc(33.333% - 10px); /* 3 cards per row with gap considered */
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    aspect-ratio: 1 / 1;
  }

  /* Make the filter visible on mobile */
  .filter {
    position: fixed;
    top: 10vh; /* Ensure the filter appears below the header */
    right: 0;
    width: 100%;
    height: auto;
    background-color: #fff;
    z-index: 3;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px;
    transition: transform 0.3s ease-in-out;
  }

  /* Make sure listings are not hidden under the map */
  .filter-is-visible .filter {
    transform: translateY(0);
  }
}


  .advanced-search-v3,
  .site-footer,
  #footer {
    display: none;
  }

  .btn-filter {
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    background: #1078ff;
    color: #fff;
    border: none;
    transition: background 0.3s ease;
    width: 100%;
    margin-top: 20px;

    &:hover,
    &:active {
      background-color: #005bb5;
    }
  }

  .filter {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    padding: 32px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 3;

    .filterBox {
      margin: 0 20px 20px 0;
      display: inline-block;

      label {
        display: block;
        font-weight: 500;
        color: #444;
        margin-bottom: 8px;
      }

      input,
      select {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        margin-top: 5px;
        font-size: 14px;
        transition: border-color 0.2s;

        &:focus {
          border-color: #1078ff;
        }
      }
    }

    .btn-filter {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: #1078ff;
      color: #fff;

      &:hover,
      &:active {
        background-color: #005bb5;
      }
    }
  }

  .filter-is-visible .filter {
    transform: translateY(0%);
  }
`;
