
// v11 try to handle .ts file
import React, { useState, useRef, useEffect } from 'react';
import Hls from 'hls.js';
import './S3VideoPlayer.css'; // Ensure styles for the progress bar and modal are defined

const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    let hls;

    if (videoUrl.endsWith('.ts')) {
      // Handle .ts (HLS) files
      if (Hls.isSupported() && video) {
        hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          setDuration(video.duration);
          generateThumbnail(); // Pre-generate the thumbnail
        });

        hls.on(Hls.Events.ERROR, (_, data) => {
          if (data.fatal) {
            setIsError(true);
          }
        });

        video.addEventListener('timeupdate', () => {
          setCurrentTime(video.currentTime);
        });

        return () => {
          if (hls) {
            hls.destroy();
          }
        };
      }
    } else {
      // Handle other video formats
      if (video) {
        video.src = videoUrl;
        video.addEventListener('loadedmetadata', () => {
          setDuration(video.duration);
          generateThumbnail(); // Pre-generate the thumbnail
        });

        video.addEventListener('timeupdate', () => {
          setCurrentTime(video.currentTime);
        });
      }
    }
  }, [videoUrl]);

  useEffect(() => {
    if (videoRef.current) {
      if (isHovering) {
        videoRef.current.play().catch(() => {}); // Handle errors with a catch
      } else {
        videoRef.current.pause();
      }
    }
  }, [isHovering]);

  const handleError = () => {
    setIsError(true);
  };

  const handleProgressClick = (e) => {
    if (videoRef.current && progressBarRef.current) {
      const boundingRect = progressBarRef.current.getBoundingClientRect();
      const clickPosition = e.clientX - boundingRect.left;
      const newTime = (clickPosition / boundingRect.width) * duration;
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const generateThumbnail = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current || document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = 640; // Set width for canvas
      canvas.height = 360; // Set height for canvas

      video.currentTime = 5; // Seek to the 5th second

      video.addEventListener('seeked', () => {
        if (context) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
        }
      }, { once: true });
    }
  };

  return (
    <div
      // className={`file-item card col-sm-12 col-md-6 col-lg-4`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={openModal}
      style={{ width, height, position: 'relative', cursor: 'pointer' }}
    >
      {isError ? (
        <p style={{ color: 'red' }}>Failed to load video.</p>
      ) : (
        <>
          <video
            ref={videoRef}
            width="100%"
            height="100%"
            controls={false} // Hide default controls
            onError={handleError}
            style={{ objectFit: 'cover', display: isHovering ? 'block' : 'none' }}
          />
          <canvas
            ref={canvasRef}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              display: !isHovering ? 'block' : 'none', // Show canvas as thumbnail
            }}
          />
          {isHovering && (
            <div
              ref={progressBarRef}
              className="progress-bar"
              onClick={handleProgressClick}
            >
              <div
                className="progress-bar-fill"
                style={{ width: `${(currentTime / duration) * 100}%` }}
              />
            </div>
          )}
        </>
      )}

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>✕</button>
            <video
              controls
              width="100%"
              height="100%"
              src={videoUrl}
              style={{ objectFit: 'contain' }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default S3VideoPlayer;



// v10 support .ts files, still cannot play .ts file; fix close button (it it z index on close button); fix the thumbnail too (create a 2d canvas, then use it)
// import React, { useState, useRef, useEffect } from 'react';
// import './S3VideoPlayer.css'; // Ensure styles for the progress bar, and modal are defined

// const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
//   const [isError, setIsError] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [isHovering, setIsHovering] = useState(false);
//   const videoRef = useRef(null);
//   const progressBarRef = useRef(null);
//   const canvasRef = useRef(null);

//   useEffect(() => {
//     const video = videoRef.current;

//     if (video) {
//       const updateProgress = () => {
//         if (video) {
//           setCurrentTime(video.currentTime);
//           setDuration(video.duration);
//         }
//       };

//       video.addEventListener('timeupdate', updateProgress);
//       video.addEventListener('loadedmetadata', () => {
//         if (video) {
//           setDuration(video.duration);
//           generateThumbnail(); // Pre-generate the thumbnail
//         }
//       });

//       return () => {
//         if (video) {
//           video.removeEventListener('timeupdate', updateProgress);
//         }
//       };
//     }
//   }, []);

//   useEffect(() => {
//     if (videoRef.current) {
//       if (isHovering) {
//         videoRef.current.play().catch(() => {}); // Handle errors with a catch
//       } else {
//         videoRef.current.pause();
//       }
//     }
//   }, [isHovering]);

//   const handleError = () => {
//     setIsError(true);
//   };

//   const handleProgressClick = (e) => {
//     if (videoRef.current && progressBarRef.current) {
//       const boundingRect = progressBarRef.current.getBoundingClientRect();
//       const clickPosition = e.clientX - boundingRect.left;
//       const newTime = (clickPosition / boundingRect.width) * duration;
//       videoRef.current.currentTime = newTime;
//       setCurrentTime(newTime);
//     }
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const generateThumbnail = () => {
//     if (videoRef.current) {
//       const video = videoRef.current;
//       const canvas = canvasRef.current || document.createElement('canvas');
//       const context = canvas.getContext('2d');

//       canvas.width = 640; // Set width for canvas
//       canvas.height = 360; // Set height for canvas

//       video.currentTime = 5; // Seek to the 5th second

//       video.addEventListener('seeked', () => {
//         if (context) {
//           context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         }
//       }, { once: true });
//     }
//   };

//   return (
//     <div
//       onMouseEnter={() => setIsHovering(true)}
//       onMouseLeave={() => setIsHovering(false)}
//       onClick={openModal}
//       style={{ width, height, position: 'relative', cursor: 'pointer' }}
//     >
//       {isError ? (
//         <p style={{ color: 'red' }}>Failed to load video.</p>
//       ) : (
//         <>
//           <video
//             ref={videoRef}
//             width="100%"
//             height="100%"
//             src={videoUrl}
//             controls={false} // Hide default controls
//             onError={handleError}
//             style={{ objectFit: 'cover', display: isHovering ? 'block' : 'none' }}
//           />
//           <canvas
//             ref={canvasRef}
//             style={{
//               width: '100%',
//               height: '100%',
//               position: 'absolute',
//               top: 0,
//               left: 0,
//               display: !isHovering ? 'block' : 'none', // Show canvas as thumbnail
//             }}
//           />
//           {isHovering && (
//             <div
//               ref={progressBarRef}
//               className="progress-bar"
//               onClick={handleProgressClick}
//             >
//               <div
//                 className="progress-bar-fill"
//                 style={{ width: `${(currentTime / duration) * 100}%` }}
//               />
//             </div>
//           )}
//         </>
//       )}

//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <button className="modal-close" onClick={closeModal}>✕</button>
//             <video
//               controls
//               width="100%"
//               height="100%"
//               src={videoUrl}
//               style={{ objectFit: 'contain' }}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default S3VideoPlayer;



// failed v9 try to fix thumbnail
// import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';

// const MinioFileList = () => {
//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [page, setPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(1);
//     const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup
//     const [thumbnails, setThumbnails] = useState({}); // State to cache thumbnails

//     const baseUrl = 'https://demo.comtruaduytan.com/all-media/';

//     useEffect(() => {
//         const fetchFiles = async () => {
//             setLoading(true);
//             try {
//                 const response = await fetch(`https://www.demo.comtruaduytan.com/wp-json/minio/v1/files?page=${page}&per_page=10`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();
                
//                 console.log('API Response:', data);

//                 if (Array.isArray(data.files)) {
//                     setFiles(data.files);
//                     setTotalPages(data.totalPages || 1);

//                     // Generate thumbnails for all video files
//                     const videoFiles = data.files.filter(file => file.endsWith('.mp4') || file.endsWith('.webm'));
//                     videoFiles.forEach(file => {
//                         generateThumbnail(`${baseUrl}${file}`).then(thumbnail => {
//                             setThumbnails(prevThumbnails => ({
//                                 ...prevThumbnails,
//                                 [file]: thumbnail
//                             }));
//                         });
//                     });
//                 } else {
//                     throw new Error('Unexpected response format');
//                 }
//                 setLoading(false);
//             } catch (err) {
//                 setError(err);
//                 setLoading(false);
//             }
//         };

//         fetchFiles();
//     }, [page]);

//     const handlePageChange = (newPage) => {
//         if (newPage >= 1 && newPage <= totalPages) {
//             setPage(newPage);
//         }
//     };

//     const handleVideoClick = (file) => {
//         setSelectedVideo(file); // Open the video popup
//     };

//     const handleClosePopup = () => {
//         setSelectedVideo(null); // Close the video popup
//     };

//     const handleMouseEnter = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.play();
//         }
//     };

//     const handleMouseLeave = (event) => {
//         const video = event.currentTarget.querySelector('video');
//         if (video) {
//             video.pause();
//         }
//     };

//     const generateThumbnail = (videoUrl) => {
//         return new Promise((resolve, reject) => {
//             const video = document.createElement('video');
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             video.src = videoUrl;
//             video.currentTime = 5; // Seek to 5 seconds

//             video.addEventListener('seeked', () => {
//                 // Wait for the video to fully pause before capturing
//                 setTimeout(() => {
//                     canvas.width = video.videoWidth;
//                     canvas.height = video.videoHeight;
//                     context.drawImage(video, 0, 0, canvas.width, canvas.height);
//                     resolve(canvas.toDataURL()); // Return the image as a data URL
//                 }, 100); // Slight delay to ensure video has paused
//             });

//             video.addEventListener('error', reject);
//         });
//     };

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error.message}</p>;

//     return (
//         <div>
//             <h1>Files</h1>
//             <div>
//                 {files.length > 0 ? (
//                     files.map((file, index) => {
//                         const fileUrl = `${baseUrl}${file}`;
//                         const fileExtension = file.split('.').pop().toLowerCase();
//                         const isVideo = ['mp4', 'webm'].includes(fileExtension);
//                         const thumbnail = thumbnails[file];

//                         return (
//                             <div
//                                 key={index}
//                                 style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', position: 'relative' }}
//                                 onMouseEnter={handleMouseEnter}
//                                 onMouseLeave={handleMouseLeave}
//                             >
//                                 {isVideo ? (
//                                     <div>
//                                         <img
//                                             src={thumbnail || 'https://via.placeholder.com/150'} // Fallback image
//                                             alt={`Thumbnail ${index}`}
//                                             style={{ width: '100%', cursor: 'pointer' }}
//                                             onClick={() => handleVideoClick(file)}
//                                         />
//                                         <video
//                                             style={{ display: 'none', width: '100%' }}
//                                             controls
//                                             src={fileUrl}
//                                         />
//                                     </div>
//                                 ) : (
//                                     <p>Unsupported file type: {file}</p>
//                                 )}
//                             </div>
//                         );
//                     })
//                 ) : (
//                     <p>No files found.</p>
//                 )}
//             </div>
//             <div style={{ marginTop: '20px' }}>
//                 <button
//                     onClick={() => handlePageChange(page - 1)}
//                     disabled={page === 1}
//                 >
//                     Previous
//                 </button>
//                 <span style={{ margin: '0 10px' }}>Page {page} of {totalPages}</span>
//                 <button
//                     onClick={() => handlePageChange(page + 1)}
//                     disabled={page === totalPages}
//                 >
//                     Next
//                 </button>
//             </div>

//             {/* Video Popup */}
//             {selectedVideo && ReactDOM.createPortal(
//                 <div
//                     style={{
//                         position: 'fixed',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                         padding: '20px',
//                         zIndex: 1000,
//                         width: '80%',
//                         maxWidth: '800px',
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                         justifyContent: 'center'
//                     }}
//                 >
//                     <button
//                         onClick={handleClosePopup}
//                         style={{
//                             position: 'absolute',
//                             top: '10px',
//                             right: '10px',
//                             background: 'white',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding: '10px',
//                             zIndex: 1001 // Ensure button is on top
//                         }}
//                     >
//                         Close
//                     </button>
//                     <video
//                         controls
//                         style={{ width: '100%' }}
//                         src={`${baseUrl}${selectedVideo}`}
//                     >
//                         Your browser does not support the video tag.
//                     </video>
//                 </div>,
//                 document.body
//             )}
//         </div>
//     );
// };

// export default MinioFileList;



// later v8 fix close button -> retry v5 for thumbnail
// import React, { useState, useRef, useEffect } from 'react';
// import './S3VideoPlayer.css'; // Ensure styles for the progress bar, thumbnail, and modal are defined

// const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
//   const [isError, setIsError] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [thumbnailUrl, setThumbnailUrl] = useState(''); // Store the URL for the thumbnail
//   const [isHovering, setIsHovering] = useState(false);
//   const videoRef = useRef(null);
//   const progressBarRef = useRef(null);
//   const [selectedVideo, setSelectedVideo] = useState(null); // State to manage the popup


//   useEffect(() => {
//     const video = videoRef.current;

//     if (video) {
//       const updateProgress = () => {
//         setCurrentTime(video.currentTime);
//         setDuration(video.duration);
//       };

//       video.addEventListener('timeupdate', updateProgress);
//       video.addEventListener('loadedmetadata', () => {
//         setDuration(video.duration);
//         generateThumbnail(); // Pre-generate the thumbnail
//       });

//       return () => {
//         video.removeEventListener('timeupdate', updateProgress);
//       };
//     }
//   }, []);

//   useEffect(() => {
//     if (videoRef.current) {
//       if (isHovering) {
//         videoRef.current.play().catch(() => {}); // Handle errors with a catch
//       } else {
//         videoRef.current.pause();
//       }
//     }
//   }, [isHovering]);

//   const handleError = () => {
//     setIsError(true);
//   };

//   const handleProgressClick = (e) => {
//     if (videoRef.current) {
//       const boundingRect = progressBarRef.current.getBoundingClientRect();
//       const clickPosition = e.clientX - boundingRect.left;
//       const newTime = (clickPosition / boundingRect.width) * duration;
//       videoRef.current.currentTime = newTime;
//       setCurrentTime(newTime);
//     }
//   };

//   const handleVideoClick = (file) => {
//     setSelectedVideo(file); // Open the video popup
//   };

//   const handleClosePopup = () => {
//       setSelectedVideo(null); // Close the video popup
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

  
//   const generateThumbnail = () => {
//     if (videoRef.current) {
//       const video = videoRef.current;
//       const canvas = document.createElement('canvas');
//       const context = canvas.getContext('2d');

//       canvas.width = 640; // Set width for canvas
//       canvas.height = 360; // Set height for canvas

//       video.currentTime = 30; // Seek to the 30th second

//       video.addEventListener('seeked', () => {
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         const dataUrl = canvas.toDataURL('image/png');
//         setThumbnailUrl(dataUrl);
//       }, { once: true });
//     }
//   };

//   return (
//     <div
//       onMouseEnter={() => setIsHovering(true)}
//       onMouseLeave={() => setIsHovering(false)}
//       // onClick={openModal}
//       onClick={() => handleVideoClick(videoUrl)}
//       style={{ width, height, position: 'relative', cursor: 'pointer' }}
//     >
//       {isError ? (
//         <p style={{ color: 'red' }}>Failed to load video.</p>
//       ) : (
//         <>
//           <video
//             ref={videoRef}
//             width="100%"
//             height="100%"
//             src={videoUrl}
//             controls={false} // Hide default controls
//             onError={handleError}
//             style={{ objectFit: 'cover', display: isHovering ? 'block' : 'none' }}
//           />
//           <img
//             src={thumbnailUrl} // Display thumbnail
//             alt="Thumbnail"
//             style={{
//               width: '100%',
//               height: '100%',
//               objectFit: 'cover',
//               position: 'absolute',
//               top: 0,
//               left: 0,
//               display: thumbnailUrl ? 'block' : 'none', // Show thumbnail only if available
//             }}
//           />
//           {isHovering && (
//             <div
//               ref={progressBarRef}
//               className="progress-bar"
//               onClick={handleProgressClick}
//             >
//               <div
//                 className="progress-bar-fill"
//                 style={{ width: `${(currentTime / duration) * 100}%` }}
//               />
//             </div>
//           )}
//         </>
//       )}

//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <button className="modal-close" onClick={closeModal}>✕</button>
//             <video
//               controls
//               width="100%"
//               height="100%"
//               src={videoUrl}
//               style={{ objectFit: 'contain' }}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default S3VideoPlayer;


// // failed v7 (just like v6 nothing improved) fix auto thumb withotu hovering
// import React, { useState, useRef, useEffect } from 'react';
// import './S3VideoPlayer.css'; // Ensure styles for the progress bar, thumbnail, and modal are defined

// const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
//   const [isError, setIsError] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [thumbnailUrl, setThumbnailUrl] = useState(''); // Store the URL for the thumbnail
//   const [isHovering, setIsHovering] = useState(false);
//   const videoRef = useRef(null);
//   const progressBarRef = useRef(null);

//   useEffect(() => {
//     const video = videoRef.current;

//     if (video) {
//       const updateProgress = () => {
//         setCurrentTime(video.currentTime);
//         setDuration(video.duration);
//       };

//       video.addEventListener('timeupdate', updateProgress);
//       video.addEventListener('loadedmetadata', () => {
//         setDuration(video.duration);
//         generateThumbnail(); // Pre-generate the thumbnail
//       });

//       return () => {
//         video.removeEventListener('timeupdate', updateProgress);
//       };
//     }
//   }, []);

//   useEffect(() => {
//     if (videoRef.current) {
//       if (isHovering) {
//         videoRef.current.play().catch(() => {}); // Handle errors with a catch
//       } else {
//         videoRef.current.pause();
//       }
//     }
//   }, [isHovering]);

//   const handleError = () => {
//     setIsError(true);
//   };

//   const handleProgressClick = (e) => {
//     if (videoRef.current) {
//       const boundingRect = progressBarRef.current.getBoundingClientRect();
//       const clickPosition = e.clientX - boundingRect.left;
//       const newTime = (clickPosition / boundingRect.width) * duration;
//       videoRef.current.currentTime = newTime;
//       setCurrentTime(newTime);
//     }
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const generateThumbnail = () => {
//     if (videoRef.current) {
//       const video = videoRef.current;
//       const canvas = document.createElement('canvas');
//       const context = canvas.getContext('2d');

//       canvas.width = 640; // Set width for canvas
//       canvas.height = 360; // Set height for canvas

//       video.currentTime = 30; // Seek to the 30th second

//       video.addEventListener('seeked', () => {
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         const dataUrl = canvas.toDataURL('image/png');
//         setThumbnailUrl(dataUrl);
//       }, { once: true });
//     }
//   };

//   return (
//     <div
//       onMouseEnter={() => setIsHovering(true)}
//       onMouseLeave={() => setIsHovering(false)}
//       onClick={openModal}
//       style={{ width, height, position: 'relative', cursor: 'pointer' }}
//     >
//       {isError ? (
//         <p style={{ color: 'red' }}>Failed to load video.</p>
//       ) : (
//         <>
//           <video
//             ref={videoRef}
//             width="100%"
//             height="100%"
//             src={videoUrl}
//             controls={false} // Hide default controls
//             onError={handleError}
//             style={{ objectFit: 'cover', display: isHovering ? 'block' : 'none' }}
//           />
//           <img
//             src={thumbnailUrl} // Display thumbnail
//             alt="Thumbnail"
//             style={{
//               width: '100%',
//               height: '100%',
//               objectFit: 'cover',
//               position: 'absolute',
//               top: 0,
//               left: 0,
//               display: thumbnailUrl ? 'block' : 'none', // Show thumbnail only if available
//             }}
//           />
//           {isHovering && (
//             <div
//               ref={progressBarRef}
//               className="progress-bar"
//               onClick={handleProgressClick}
//             >
//               <div
//                 className="progress-bar-fill"
//                 style={{ width: `${(currentTime / duration) * 100}%` }}
//               />
//             </div>
//           )}
//         </>
//       )}

//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <button className="modal-close" onClick={closeModal}>✕</button>
//             <video
//               controls
//               width="100%"
//               height="100%"
//               src={videoUrl}
//               style={{ objectFit: 'contain' }}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default S3VideoPlayer;





// OK (some how it loose the vid thumbnail, maybe css, later) v6 ( fix a time, not random as v5, fater but still need hover mouse to view thumb nial) try to make the vid thumb nail gen faster
// import React, { useState, useRef, useEffect } from 'react';
// import './S3VideoPlayer.css'; // Ensure styles for the progress bar, thumbnail, and modal are defined

// const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
//   const [isError, setIsError] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [thumbnailUrl, setThumbnailUrl] = useState(''); // Store the URL for the thumbnail
//   const [isHovering, setIsHovering] = useState(false);
//   const videoRef = useRef(null);
//   const progressBarRef = useRef(null);

//   useEffect(() => {
//     const video = videoRef.current;

//     if (video) {
//       const updateProgress = () => {
//         setCurrentTime(video.currentTime);
//         setDuration(video.duration);
//       };

//       video.addEventListener('timeupdate', updateProgress);
//       video.addEventListener('loadedmetadata', () => {
//         setDuration(video.duration);
//         generateThumbnail(); // Pre-generate the thumbnail
//       });

//       return () => {
//         video.removeEventListener('timeupdate', updateProgress);
//       };
//     }
//   }, []);

//   useEffect(() => {
//     if (videoRef.current) {
//       if (isHovering) {
//         videoRef.current.play().catch(() => {}); // Handle errors with a catch
//       } else {
//         videoRef.current.pause();
//       }
//     }
//   }, [isHovering]);

//   const handleError = () => {
//     setIsError(true);
//   };

//   const handleProgressClick = (e) => {
//     if (videoRef.current) {
//       const boundingRect = progressBarRef.current.getBoundingClientRect();
//       const clickPosition = e.clientX - boundingRect.left;
//       const newTime = (clickPosition / boundingRect.width) * duration;
//       videoRef.current.currentTime = newTime;
//       setCurrentTime(newTime);
//     }
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

  
//   const generateThumbnail = () => {
//     if (videoRef.current) {
//       const video = videoRef.current;
//       const canvas = document.createElement('canvas');
//       const context = canvas.getContext('2d');

//       canvas.width = 640; // Set width for canvas
//       canvas.height = 360; // Set height for canvas

//       video.currentTime = 30; // Seek to the 30th second

//       video.addEventListener('seeked', () => {
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         const dataUrl = canvas.toDataURL('image/png');
//         setThumbnailUrl(dataUrl);
//       }, { once: true });
//     }
//   };

//   return (
//     <div
//       onMouseEnter={() => setIsHovering(true)}
//       onMouseLeave={() => setIsHovering(false)}
//       onClick={openModal}
//       style={{ width, height, position: 'relative', cursor: 'pointer' }}
//     >
//       {isError ? (
//         <p style={{ color: 'red' }}>Failed to load video.</p>
//       ) : (
//         <>
//           <video
//             ref={videoRef}
//             width="100%"
//             height="100%"
//             src={videoUrl}
//             controls={false} // Hide default controls
//             onError={handleError}
//             style={{ objectFit: 'cover', display: isHovering ? 'block' : 'none' }}
//           />
//           <img
//             src={thumbnailUrl} // Display thumbnail
//             alt="Thumbnail"
//             style={{
//               width: '100%',
//               height: '100%',
//               objectFit: 'cover',
//               position: 'absolute',
//               top: 0,
//               left: 0,
//               display: thumbnailUrl ? 'block' : 'none', // Show thumbnail only if available
//             }}
//           />
//           {isHovering && (
//             <div
//               ref={progressBarRef}
//               className="progress-bar"
//               onClick={handleProgressClick}
//             >
//               <div
//                 className="progress-bar-fill"
//                 style={{ width: `${(currentTime / duration) * 100}%` }}
//               />
//             </div>
//           )}
//         </>
//       )}

//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <button className="modal-close" onClick={closeModal}>✕</button>
//             <video
//               controls
//               width="100%"
//               height="100%"
//               src={videoUrl}
//               style={{ objectFit: 'contain' }}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default S3VideoPlayer;


// v5 (does use the vid fram at randome time as thumb nail but it is slow) try to use vid as thumbnail without taking snapshot png image
// import React, { useState, useRef, useEffect } from 'react';
// import './S3VideoPlayer.css'; // Ensure styles for the progress bar, thumbnail, and modal are defined

// const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
//   const [isError, setIsError] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [thumbnailTime, setThumbnailTime] = useState(0); // Store the time for the thumbnail
//   const [isHovering, setIsHovering] = useState(false);
//   const videoRef = useRef(null);
//   const progressBarRef = useRef(null);

//   useEffect(() => {
//     const video = videoRef.current;

//     if (video) {
//       const updateProgress = () => {
//         setCurrentTime(video.currentTime);
//         setDuration(video.duration);
//       };

//       video.addEventListener('timeupdate', updateProgress);
//       video.addEventListener('loadedmetadata', () => {
//         setDuration(video.duration);
//         generateRandomThumbnail();
//       });

//       return () => {
//         video.removeEventListener('timeupdate', updateProgress);
//       };
//     }
//   }, []);

//   useEffect(() => {
//     if (videoRef.current) {
//       if (isHovering) {
//         videoRef.current.play().catch(() => {}); // Handle errors with a catch
//       } else {
//         videoRef.current.pause();
//         videoRef.current.currentTime = thumbnailTime; // Stop at the thumbnail frame
//       }
//     }
//   }, [isHovering, thumbnailTime]);

//   const handleError = () => {
//     setIsError(true);
//   };

//   const handleProgressClick = (e) => {
//     if (videoRef.current) {
//       const boundingRect = progressBarRef.current.getBoundingClientRect();
//       const clickPosition = e.clientX - boundingRect.left;
//       const newTime = (clickPosition / boundingRect.width) * duration;
//       videoRef.current.currentTime = newTime;
//       setCurrentTime(newTime);
//     }
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const generateRandomThumbnail = () => {
//     if (videoRef.current) {
//       const video = videoRef.current;
//       const randomTime = Math.random() * video.duration; // Choose a random time
//       setThumbnailTime(randomTime);
//       video.currentTime = randomTime;
      
//       // Once the video seeks to the random time, pause it there
//       video.addEventListener('seeked', () => {
//         video.pause(); // Pause at the random frame
//       }, { once: true });
//     }
//   };

//   return (
//     <div
//       onMouseEnter={() => setIsHovering(true)}
//       onMouseLeave={() => setIsHovering(false)}
//       onClick={openModal}
//       style={{ width, height, position: 'relative', cursor: 'pointer' }}
//     >
//       {isError ? (
//         <p style={{ color: 'red' }}>Failed to load video.</p>
//       ) : (
//         <>
//           <video
//             ref={videoRef}
//             width="100%"
//             height="100%"
//             src={videoUrl}
//             controls={false} // Hide default controls
//             onError={handleError}
//             style={{ objectFit: 'cover', display: isHovering ? 'block' : 'none' }}
//           />
//           {!isHovering && (
//             <div
//               style={{
//                 width: '100%',
//                 height: '100%',
//                 objectFit: 'cover',
//                 display: !isHovering ? 'block' : 'none',
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 background: `url(${videoUrl})`,
//                 backgroundPosition: `0 -${thumbnailTime}px`,
//                 backgroundSize: 'contain',
//               }}
//             />
//           )}
//           {isHovering && (
//             <div
//               ref={progressBarRef}
//               className="progress-bar"
//               onClick={handleProgressClick}
//             >
//               <div
//                 className="progress-bar-fill"
//                 style={{ width: `${(currentTime / duration) * 100}%` }}
//               />
//             </div>
//           )}
//         </>
//       )}

//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <button className="modal-close" onClick={closeModal}>✕</button>
//             <video
//               controls
//               width="100%"
//               height="100%"
//               src={videoUrl}
//               style={{ objectFit: 'contain' }}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default S3VideoPlayer;





















// v4( has more features but lost some v1 working ones): video popup, timebar, ref v1 for autoplay( this v4 has some auto playprob), the close button did not work either (v1 seems ok)

// import React, { useState, useRef, useEffect } from 'react';
// import './S3VideoPlayer.css'; // Ensure styles for the progress bar, thumbnail, and modal are defined

// const S3VideoPlayer = ({ videoUrl, thumbnailUrl, width = '640px', height = '360px' }) => {
//   const [isError, setIsError] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const [isHovering, setIsHovering] = useState(false);
//   const videoRef = useRef(null);
//   const progressBarRef = useRef(null);

//   useEffect(() => {
//     const video = videoRef.current;

//     if (video) {
//       const updateProgress = () => {
//         setCurrentTime(video.currentTime);
//         setDuration(video.duration);
//       };

//       video.addEventListener('timeupdate', updateProgress);
//       video.addEventListener('loadedmetadata', updateProgress);

//       return () => {
//         video.removeEventListener('timeupdate', updateProgress);
//         video.removeEventListener('loadedmetadata', updateProgress);
//       };
//     }
//   }, []);

//   useEffect(() => {
//     if (videoRef.current) {
//       if (isHovering) {
//         videoRef.current.play().catch(() => {}); // Handle errors with a catch
//       } else {
//         videoRef.current.pause();
//         videoRef.current.currentTime = 0; // Reset to start
//       }
//     }
//   }, [isHovering]);

//   const handleError = () => {
//     setIsError(true);
//   };

//   const handleProgressClick = (e) => {
//     if (videoRef.current) {
//       const boundingRect = progressBarRef.current.getBoundingClientRect();
//       const clickPosition = e.clientX - boundingRect.left;
//       const newTime = (clickPosition / boundingRect.width) * duration;
//       videoRef.current.currentTime = newTime;
//       setCurrentTime(newTime);
//     }
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div
//       onMouseEnter={() => setIsHovering(true)}
//       onMouseLeave={() => setIsHovering(false)}
//       onClick={openModal}
//       style={{ width, height, position: 'relative', cursor: 'pointer' }}
//     >
//       {isError ? (
//         <p style={{ color: 'red' }}>Failed to load video.</p>
//       ) : (
//         <>
//           <video
//             ref={videoRef}
//             width="100%"
//             height="100%"
//             src={videoUrl}
//             controls={false} // Hide default controls
//             onError={handleError}
//             style={{ objectFit: 'cover', display: isHovering ? 'block' : 'none' }}
//           />
//           {!isHovering && (
//             <img
//               src={thumbnailUrl}
//               alt="Thumbnail"
//               style={{
//                 width: '100%',
//                 height: '100%',
//                 objectFit: 'cover',
//                 display: !isHovering ? 'block' : 'none',
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//               }}
//             />
//           )}
//           {isHovering && (
//             <div
//               ref={progressBarRef}
//               className="progress-bar"
//               onClick={handleProgressClick}
//             >
//               <div
//                 className="progress-bar-fill"
//                 style={{ width: `${(currentTime / duration) * 100}%` }}
//               />
//             </div>
//           )}
//         </>
//       )}

//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <button className="modal-close" onClick={closeModal}>✕</button>
//             <video
//               controls
//               width="100%"
//               height="100%"
//               src={videoUrl}
//               style={{ objectFit: 'contain' }}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default S3VideoPlayer;



// OK v3
// import React, { useState, useRef, useEffect } from 'react';
// import './S3VideoPlayer.css'; // Make sure to add styles for the progress bar and modal

// const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
//   const [isError, setIsError] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [currentTime, setCurrentTime] = useState(0);
//   const [duration, setDuration] = useState(0);
//   const videoRef = useRef(null);
//   const progressBarRef = useRef(null);

//   useEffect(() => {
//     const video = videoRef.current;

//     if (video) {
//       const updateProgress = () => {
//         setCurrentTime(video.currentTime);
//         setDuration(video.duration);
//       };

//       video.addEventListener('timeupdate', updateProgress);
//       video.addEventListener('loadedmetadata', updateProgress);

//       return () => {
//         video.removeEventListener('timeupdate', updateProgress);
//         video.removeEventListener('loadedmetadata', updateProgress);
//       };
//     }
//   }, []);

//   const handleMouseEnter = () => {
//     if (videoRef.current) {
//       videoRef.current.play();
//     }
//   };

//   const handleMouseLeave = () => {
//     if (videoRef.current) {
//       videoRef.current.pause();
//       videoRef.current.currentTime = 0; // Reset to start
//     }
//   };

//   const handleError = () => {
//     setIsError(true);
//   };

//   const handleProgressClick = (e) => {
//     if (videoRef.current) {
//       const boundingRect = progressBarRef.current.getBoundingClientRect();
//       const clickPosition = e.clientX - boundingRect.left;
//       const newTime = (clickPosition / boundingRect.width) * duration;
//       videoRef.current.currentTime = newTime;
//       setCurrentTime(newTime);
//     }
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div>
//       <div
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         onClick={openModal}
//         style={{ width, height, position: 'relative', cursor: 'pointer' }}
//       >
//         {isError ? (
//           <p style={{ color: 'red' }}>Failed to load video.</p>
//         ) : (
//           <video
//             ref={videoRef}
//             width="100%"
//             height="100%"
//             src={videoUrl}
//             controls={false} // Hide default controls
//             onError={handleError}
//             style={{ objectFit: 'cover' }}
//           />
//         )}
//         <div
//           ref={progressBarRef}
//           className="progress-bar"
//           onClick={handleProgressClick}
//         >
//           <div
//             className="progress-bar-fill"
//             style={{ width: `${(currentTime / duration) * 100}%` }}
//           />
//         </div>
//       </div>

//       {isModalOpen && (
//         <div className="modal-overlay">
//           <div className="modal-content">
//             <button className="modal-close" onClick={closeModal}>✕</button>
//             <video
//               controls
//               width="100%"
//               height="100%"
//               src={videoUrl}
//               style={{ objectFit: 'contain' }}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default S3VideoPlayer;




// OK v2
// import React, { useState, useRef } from 'react';

// const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
//   const [isError, setIsError] = useState(false);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const videoRef = useRef(null);

//   const handleMouseEnter = () => {
//     if (videoRef.current) {
//       videoRef.current.play();
//       setIsPlaying(true);
//     }
//   };

//   const handleMouseLeave = () => {
//     if (videoRef.current) {
//       videoRef.current.pause();
//       videoRef.current.currentTime = 0; // Reset to start
//       setIsPlaying(false);
//     }
//   };

//   const handleError = () => {
//     setIsError(true);
//   };

//   const handleSkip = () => {
//     if (videoRef.current) {
//       videoRef.current.currentTime = videoRef.current.duration; // Skip to end
//       setIsPlaying(false);
//     }
//   };

//   const openPopup = () => {
//     setIsPopupOpen(true);
//   };

//   const closePopup = () => {
//     setIsPopupOpen(false);
//   };

//   return (
//     <div>
//       <div
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         style={{ width, height, position: 'relative', cursor: 'pointer' }}
//         onClick={openPopup}
//       >
//         {isError ? (
//           <p style={{ color: 'red' }}>Failed to load video.</p>
//         ) : (
//           <video
//             ref={videoRef}
//             width="100%"
//             height="100%"
//             src={videoUrl}
//             muted
//             preload="auto"
//             onError={handleError}
//             style={{ objectFit: 'cover', display: isPlaying ? 'block' : 'none' }}
//           />
//         )}
//         <button
//           onClick={(e) => {
//             e.stopPropagation(); // Prevent triggering the popup
//             handleSkip();
//           }}
//           style={{
//             position: 'absolute',
//             top: '10px',
//             right: '10px',
//             backgroundColor: 'rgba(0,0,0,0.7)',
//             color: 'white',
//             border: 'none',
//             borderRadius: '5px',
//             padding: '5px 10px',
//             cursor: 'pointer',
//             zIndex: 10,
//           }}
//         >
//           Skip
//         </button>
//       </div>

//       {/* Popup */}
//       {isPopupOpen && (
//         <div
//           style={{
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(0,0,0,0.8)',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             zIndex: 1000,
//           }}
//           onClick={closePopup}
//         >
//           <div
//             style={{
//               position: 'relative',
//               maxWidth: '90%',
//               maxHeight: '90%',
//               backgroundColor: 'white',
//               padding: '10px',
//             }}
//             onClick={(e) => e.stopPropagation()} // Prevent click event from closing the popup
//           >
//             <video
//               controls
//               src={videoUrl}
//               style={{ width: '100%', height: 'auto' }}
//             />
//             <button
//               onClick={closePopup}
//               style={{
//                 position: 'absolute',
//                 top: '10px',
//                 right: '10px',
//                 backgroundColor: 'rgba(0,0,0,0.7)',
//                 color: 'white',
//                 border: 'none',
//                 borderRadius: '5px',
//                 padding: '5px 10px',
//                 cursor: 'pointer',
//                 zIndex: 10,
//               }}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default S3VideoPlayer;


// OK V1

// import React, { useState, useRef } from 'react';

// const S3VideoPlayer = ({ videoUrl, width = '640px', height = '360px' }) => {
//   const videoRef = useRef(null);

//   const handleMouseEnter = () => {
//     if (videoRef.current) {
//       videoRef.current.play();
//     }
//   };

//   const handleMouseLeave = () => {
//     if (videoRef.current) {
//       videoRef.current.pause();
//       videoRef.current.currentTime = 0; // Reset to start
//     }
//   };

//   return (
//     <div
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//       style={{ width, height, position: 'relative' }}
//     >
//       <video
//         ref={videoRef}
//         width="100%"
//         height="100%"
//         src={videoUrl}
//         muted
//         preload="auto"
//         style={{ objectFit: 'cover' }}
//       />
//     </div>
//   );
// };

// export default S3VideoPlayer;
