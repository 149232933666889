const data = {
    "properties": [
    {
        "_id": "593e9297e17df20c4a237d42",
        "index": 0,
        "price": 937180,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Singer",
        "address": "76 Duy Tân",
        "latitude": 21.031064,
        "longitude": 105.784059,
        "bedrooms": 2,
        "bathrooms": 2,
        "carSpaces": 2,
        "des":""
    },
    {
        "_id": "593e9297ec4cca9c56bf61af",
        "index": 1,
        "price": 703725,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Machias",
        "address": "74 Duy Tân",
        "latitude": 21.031064,
        "longitude": 105.784159,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e929773c71925e5d7c11c",
        "index": 2,
        "price": 837111,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Bend",
        "address": "72 Duy Tân",
        "latitude": 21.031064,
        "longitude": 105.784259,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e92973d4d15eedb129aee",
        "index": 3,
        "price": 648223,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Ivanhoe",
        "address": "70 Duy Tân",
        "latitude": 21.031064,
        "longitude": 105.784359,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e929728b7f8543b7907e1",
        "index": 4,
        "price": 771826,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Lydia",
        "address": "68 Duy Tân",
        "latitude": 21.031064,
        "longitude": 105.784459,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e92975996a6263d146444",
        "index": 5,
        "price": 686238,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Rosedale",
        "address": "66 Duy Tân",
        "latitude": 21.031064,
        "longitude": 105.784559,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e9297a13e246da5ba0884",
        "index": 6,
        "price": 595377,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Allison",
        "address": "64 Duy Tân",
        "latitude": 21.031064,
        "longitude": 105.784659,
        "bedrooms": 2,
        "bathrooms": 2,
        "carSpaces": 2,
        "des":""
    },
    {
        "_id": "593e9297afa51a013fcd07d5",
        "index": 7,
        "price": 696189,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Nicut",
        "latitude": 21.031064,
        "longitude": 105.784759,
        "bedrooms": 4,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e92972bd8fd05f2ab1a89",
        "index": 8,
        "price": 716114,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Canterwood",
        "address": "437 Mill Street",
        "latitude": 21.031064,
        "longitude": 105.784859,
        "bedrooms": 3,
        "bathrooms": 1,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e9297fe2e6d32c859ecf4",
        "index": 9,
        "price": 739852,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Cobbtown",
        "address": "553 Friel Place",
        "latitude": 21.031064,
        "longitude": 105.784959,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e92970481ccf52f948e6d",
        "index": 10,
        "price": 625917,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Blue",
        "address": "687 Opal Court",
        "latitude": 21.031064,
        "longitude": 105.785059,
        "bedrooms": 4,
        "bathrooms": 1,
        "carSpaces": 2,
        "des":""
    },
    {
        "_id": "593e9297e09417746b79e1c5",
        "index": 11,
        "price": 591236,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Craig",
        "address": "183 Canton Court",
        "latitude": -33.938557,
        "longitude": 151.24773,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e92978e7160fa2b9992d9",
        "index": 12,
        "price": 995539,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Orovada",
        "address": "234 Prospect Street",
        "latitude": -33.938345,
        "longitude": 151.25045,
        "bedrooms": 1,
        "bathrooms": 2,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e92973459fbdfa1f1c041",
        "index": 13,
        "price": 866924,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Clay",
        "address": "205 Ocean Parkway",
        "latitude": -33.937763,
        "longitude": 151.24721,
        "bedrooms": 1,
        "bathrooms": 1,
        "carSpaces": 2,
        "des":""
    },
    {
        "_id": "593e92970306538830483e14",
        "index": 14,
        "price": 810745,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Windsor",
        "address": "324 Degraw Street",
        "latitude": -33.938662,
        "longitude": 151.2513,
        "bedrooms": 2,
        "bathrooms": 2,
        "carSpaces": 2,
        "des":""
    },
    {
        "_id": "593e92976042982766c97ca1",
        "index": 15,
        "price": 793134,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Bluetown",
        "address": "644 Ashford Street",
        "latitude": -33.940989,
        "longitude": 151.25156,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e92971901e888a036a258",
        "index": 16,
        "price": 984589,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Wakulla",
        "address": "472 Wilson Street",
        "latitude": -33.944395,
        "longitude": 151.24723,
        "bedrooms": 1,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e929780e6af9c11a10b90",
        "index": 17,
        "price": 836244,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Greer",
        "address": "455 Crown Street",
        "latitude": -33.941819,
        "longitude": 151.25784,
        "bedrooms": 1,
        "bathrooms": 2,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e9297441033e3f818ff6b",
        "index": 18,
        "price": 962426,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Wright",
        "address": "459 Duffield Street",
        "latitude": -33.940486,
        "longitude": 151.25562,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e9297964d25b59d94e8aa",
        "index": 19,
        "price": 768916,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Manchester",
        "address": "537 Gelston Avenue",
        "latitude": -33.942826,
        "longitude": 151.24943,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e929737636c879539416d",
        "index": 20,
        "price": 956974,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Ballico",
        "address": "689 Orange Street",
        "latitude": -33.943478,
        "longitude": 151.24779,
        "bedrooms": 1,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e9297b2516c3e5af4601f",
        "index": 21,
        "price": 875917,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Tuskahoma",
        "address": "874 Rugby Road",
        "latitude": -33.93817,
        "longitude": 151.25545,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 2,
        "des":""
    },
    {
        "_id": "593e92972f6034590df04c45",
        "index": 22,
        "price": 913858,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Temperanceville",
        "address": "687 Alabama Avenue",
        "latitude": -33.938195,
        "longitude": 151.25286,
        "bedrooms": 3,
        "bathrooms": 1,
        "carSpaces": 2,
        "des":""
    },
    {
        "_id": "593e9297aa0e233a421f1a58",
        "index": 23,
        "price": 622995,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Cliffside",
        "address": "739 Vermont Street",
        "latitude": -33.94209,
        "longitude": 151.25645,
        "bedrooms": 2,
        "bathrooms": 2,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e92977526a30b580dcfb2",
        "index": 24,
        "price": 593777,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Sunbury",
        "address": "735 Humboldt Street",
        "latitude": -33.944276,
        "longitude": 151.25442,
        "bedrooms": 2,
        "bathrooms": 2,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e92975141c74b24375bd9",
        "index": 25,
        "price": 757686,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Gracey",
        "address": "617 Charles Place",
        "latitude": -33.939279,
        "longitude": 151.25559,
        "bedrooms": 3,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e9297b3ffeb95bb008453",
        "index": 26,
        "price": 976878,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Farmers",
        "address": "786 George Street",
        "latitude": -33.943311,
        "longitude": 151.25781,
        "bedrooms": 3,
        "bathrooms": 1,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e9297e262c91acd91ab81",
        "index": 27,
        "price": 804859,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Mapletown",
        "address": "711 Sunnyside Avenue",
        "latitude": -33.943775,
        "longitude": 151.24929,
        "bedrooms": 1,
        "bathrooms": 2,
        "carSpaces": 0,
        "des":""
    },
    {
        "_id": "593e9297df21e62e0465f91f",
        "index": 28,
        "price": 611018,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Snyderville",
        "address": "403 Lawrence Avenue",
        "latitude": -33.942007,
        "longitude": 151.25262,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 2,
        "des":""
    },
    {
        "_id": "593e929787f567dccda86910",
        "index": 29,
        "price": 707939,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Inkerman",
        "address": "265 Ocean Court",
        "latitude": -33.943142,
        "longitude": 151.25074,
        "bedrooms": 1,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e929787f567dccda86911",
        "index": 30,
        "price": 707939,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Inkerman",
        "address": "265 Ocean Court",
        "latitude": -33.943142,
        "longitude": 151.25074,
        "bedrooms": 1,
        "bathrooms": 1,
        "carSpaces": 1,
        "des":""
    },
    {
        "_id": "593e929788f567dccda86912",
        "index": 31,
        "price": 650000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Bridgeport",
        "address": "101 Maple Avenue",
        "latitude": 40.732430,
        "longitude": -73.998210,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Spacious home near the park."
    },
    {
        "_id": "593e929788f567dccda86913",
        "index": 32,
        "price": 780000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Harrison",
        "address": "202 Oak Street",
        "latitude": 39.952335,
        "longitude": -75.163537,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 3,
        "des": "Modern house with a great view."
    },
    {
        "_id": "593e929788f567dccda86914",
        "index": 33,
        "price": 895000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Montrose",
        "address": "303 Pine Lane",
        "latitude": 34.052235,
        "longitude": -118.243683,
        "bedrooms": 5,
        "bathrooms": 4,
        "carSpaces": 4,
        "des": "Luxury property in a prime location."
    },
    {
        "_id": "593e929788f567dccda86915",
        "index": 34,
        "price": 540000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Sunnyside",
        "address": "404 Birch Road",
        "latitude": 37.774929,
        "longitude": -122.419418,
        "bedrooms": 2,
        "bathrooms": 1,
        "carSpaces": 1,
        "des": "Charming home with a garden."
    },
    {
        "_id": "593e929788f567dccda86916",
        "index": 35,
        "price": 620000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Elmwood",
        "address": "505 Cedar Drive",
        "latitude": 36.778259,
        "longitude": -119.417931,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Cozy home with modern amenities."
    },
    {
        "_id": "593e929788f567dccda86917",
        "index": 36,
        "price": 720000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Westfield",
        "address": "606 Willow Street",
        "latitude": 32.715736,
        "longitude": -117.161087,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 3,
        "des": "Beautiful family home in a quiet neighborhood."
    },
    {
        "_id": "593e929788f567dccda86918",
        "index": 37,
        "price": 530000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Brooklyn",
        "address": "707 Elm Street",
        "latitude": 40.678178,
        "longitude": -73.944158,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Classic Brooklyn brownstone with original features."
    },
    {
        "_id": "593e929788f567dccda86919",
        "index": 38,
        "price": 760000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Riverside",
        "address": "808 Maple Street",
        "latitude": 41.878114,
        "longitude": -87.629798,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 3,
        "des": "Spacious property with a river view."
    },
    {
        "_id": "593e929788f567dccda8691a",
        "index": 39,
        "price": 590000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Greenville",
        "address": "909 Cedar Street",
        "latitude": 33.749001,
        "longitude": -84.387978,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Lovely home with a spacious backyard."
    },
    {
        "_id": "593e929788f567dccda8691b",
        "index": 40,
        "price": 850000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Northfield",
        "address": "1010 Birch Avenue",
        "latitude": 42.331427,
        "longitude": -83.045754,
        "bedrooms": 5,
        "bathrooms": 4,
        "carSpaces": 3,
        "des": "Modern home with top-of-the-line finishes."
    },
    {
        "_id": "593e929788f567dccda8691c",
        "index": 41,
        "price": 680000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Windsor",
        "address": "1111 Pine Street",
        "latitude": 45.421530,
        "longitude": -75.697193,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Charming house with easy access to downtown."
    },
    {
        "_id": "593e929788f567dccda8691d",
        "index": 42,
        "price": 740000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Oakland",
        "address": "1212 Spruce Street",
        "latitude": 37.804363,
        "longitude": -122.271111,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Elegant home with modern design."
    },
    {
        "_id": "593e929788f567dccda8691e",
        "index": 43,
        "price": 590000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Downtown",
        "address": "1313 Oak Drive",
        "latitude": 34.052235,
        "longitude": -118.243683,
        "bedrooms": 2,
        "bathrooms": 2,
        "carSpaces": 1,
        "des": "Condo with city views."
    },
    {
        "_id": "593e929788f567dccda8691f",
        "index": 44,
        "price": 620000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Manhattan",
        "address": "1414 Maple Avenue",
        "latitude": 40.783060,
        "longitude": -73.971249,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Stylish apartment in the heart of Manhattan."
    },
    {
        "_id": "593e929788f567dccda86920",
        "index": 45,
        "price": 680000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Cherry Hill",
        "address": "1515 Cedar Road",
        "latitude": 39.9208,
        "longitude": -75.0974,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 3,
        "des": "Family-friendly home with large yard."
    },
    {
        "_id": "593e929788f567dccda86921",
        "index": 46,
        "price": 830000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Newport",
        "address": "1616 Elm Street",
        "latitude": 41.49008,
        "longitude": -71.31279,
        "bedrooms": 5,
        "bathrooms": 4,
        "carSpaces": 3,
        "des": "Luxury residence with ocean views."
    },
    {
        "_id": "593e929788f567dccda86922",
        "index": 47,
        "price": 540000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Santa Monica",
        "address": "1717 Birch Avenue",
        "latitude": 34.024212,
        "longitude": -118.496475,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Cozy home near the beach."
    },
    {
        "_id": "593e929788f567dccda86923",
        "index": 48,
        "price": 710000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Beverly Hills",
        "address": "1818 Willow Lane",
        "latitude": 34.069738,
        "longitude": -118.405685,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Elegant estate with a pool."
    },
    {
        "_id": "593e929788f567dccda86924",
        "index": 49,
        "price": 680000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Bel Air",
        "address": "1919 Maple Street",
        "latitude": 34.090009,
        "longitude": -118.406281,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Luxury home with a stunning view."
    },
    {
        "_id": "593e929788f567dccda86925",
        "index": 50,
        "price": 750000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Pasadena",
        "address": "2020 Pine Avenue",
        "latitude": 34.052235,
        "longitude": -118.243683,
        "bedrooms": 5,
        "bathrooms": 4,
        "carSpaces": 3,
        "des": "Spacious home with modern amenities."
    },
    {
        "_id": "593e929788f567dccda86926",
        "index": 51,
        "price": 560000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Laguna Beach",
        "address": "2121 Oak Drive",
        "latitude": 33.542674,
        "longitude": -117.779336,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Charming coastal home."
    },
    {
        "_id": "593e929788f567dccda86927",
        "index": 52,
        "price": 690000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Encino",
        "address": "2222 Cedar Street",
        "latitude": 34.169677,
        "longitude": -118.550510,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Beautiful home with a private garden."
    },
    {
        "_id": "593e929788f567dccda86928",
        "index": 53,
        "price": 830000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Calabasas",
        "address": "2323 Birch Avenue",
        "latitude": 34.150660,
        "longitude": -118.638565,
        "bedrooms": 5,
        "bathrooms": 4,
        "carSpaces": 3,
        "des": "Spacious estate with high-end finishes."
    },
    {
        "_id": "593e929788f567dccda86929",
        "index": 54,
        "price": 550000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Sherman Oaks",
        "address": "2424 Willow Lane",
        "latitude": 34.146865,
        "longitude": -118.450114,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Charming home with a great backyard."
    },
    {
        "_id": "593e929788f567dccda8692a",
        "index": 55,
        "price": 730000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Beverly Hills",
        "address": "2525 Maple Avenue",
        "latitude": 34.069738,
        "longitude": -118.405685,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Elegant home with a spacious layout."
    },
    {
        "_id": "593e929789f567dccda8692b",
        "index": 56,
        "price": 600000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Aspen",
        "address": "2626 Elm Street",
        "latitude": 39.1911,
        "longitude": -106.8175,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Cozy mountain retreat with stunning views."
    },
    {
        "_id": "593e929789f567dccda8692c",
        "index": 57,
        "price": 770000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Sedona",
        "address": "2727 Oak Drive",
        "latitude": 34.8697,
        "longitude": -111.7609,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Elegant home with a desert landscape."
    },
    {
        "_id": "593e929789f567dccda8692d",
        "index": 58,
        "price": 690000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Park City",
        "address": "2828 Pine Avenue",
        "latitude": 40.6461,
        "longitude": -111.4980,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Charming property near ski resorts."
    },
    {
        "_id": "593e929789f567dccda8692e",
        "index": 59,
        "price": 800000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Santa Fe",
        "address": "2929 Cedar Street",
        "latitude": 35.6870,
        "longitude": -105.9378,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 3,
        "des": "Beautiful home with southwestern architecture."
    },
    {
        "_id": "593e929789f567dccda8692f",
        "index": 60,
        "price": 560000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Tucson",
        "address": "3030 Birch Lane",
        "latitude": 32.2226,
        "longitude": -110.9747,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Spacious home with desert views."
    },
    {
        "_id": "593e929789f567dccda86930",
        "index": 61,
        "price": 720000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Flagstaff",
        "address": "3131 Maple Street",
        "latitude": 35.1983,
        "longitude": -111.6513,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Rustic home with mountain views."
    },
    {
        "_id": "593e929789f567dccda86931",
        "index": 62,
        "price": 780000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Jackson Hole",
        "address": "3232 Oak Avenue",
        "latitude": 43.4799,
        "longitude": -110.7624,
        "bedrooms": 5,
        "bathrooms": 4,
        "carSpaces": 3,
        "des": "Luxury home with breathtaking views."
    },
    {
        "_id": "593e929789f567dccda86932",
        "index": 63,
        "price": 650000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Bozeman",
        "address": "3333 Pine Street",
        "latitude": 45.6790,
        "longitude": -111.0440,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Charming home with a spacious yard."
    },
    {
        "_id": "593e929789f567dccda86933",
        "index": 64,
        "price": 710000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Ketchum",
        "address": "3434 Willow Lane",
        "latitude": 43.6789,
        "longitude": -114.3734,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Elegant home with a mountain backdrop."
    },
    {
        "_id": "593e929789f567dccda86934",
        "index": 65,
        "price": 690000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Sun Valley",
        "address": "3535 Maple Avenue",
        "latitude": 43.6832,
        "longitude": -114.3600,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Cozy retreat with great amenities."
    },
    {
        "_id": "593e929789f567dccda86935",
        "index": 66,
        "price": 760000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Sedona",
        "address": "3636 Oak Street",
        "latitude": 34.8697,
        "longitude": -111.7609,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Beautiful home with unique architecture."
    },
    {
        "_id": "593e929789f567dccda86936",
        "index": 67,
        "price": 740000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Carlsbad",
        "address": "3737 Cedar Street",
        "latitude": 33.1581,
        "longitude": -117.3506,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Spacious home close to the beach."
    },
    {
        "_id": "593e929789f567dccda86937",
        "index": 68,
        "price": 650000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Boulder",
        "address": "3838 Maple Avenue",
        "latitude": 40.0150,
        "longitude": -105.2705,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Charming home with mountain views."
    },
    {
        "_id": "593e929789f567dccda86938",
        "index": 69,
        "price": 680000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Vail",
        "address": "3939 Pine Street",
        "latitude": 39.6403,
        "longitude": -106.3742,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Luxury ski-in/ski-out home."
    },
    {
        "_id": "593e929789f567dccda86939",
        "index": 70,
        "price": 720000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Aspen",
        "address": "4040 Willow Lane",
        "latitude": 39.1911,
        "longitude": -106.8175,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Elegant property with a modern design."
    },
    {
        "_id": "593e929789f567dccda8693a",
        "index": 71,
        "price": 760000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Jackson Hole",
        "address": "4141 Birch Avenue",
        "latitude": 43.4799,
        "longitude": -110.7624,
        "bedrooms": 5,
        "bathrooms": 4,
        "carSpaces": 3,
        "des": "Spacious home with incredible views."
    },
    {
        "_id": "593e929789f567dccda8693b",
        "index": 72,
        "price": 590000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Park City",
        "address": "4242 Oak Drive",
        "latitude": 40.6461,
        "longitude": -111.4980,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Cozy home close to outdoor activities."
    },
    {
        "_id": "593e929789f567dccda8693c",
        "index": 73,
        "price": 710000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property03.jpg",
        "city": "Sun Valley",
        "address": "4343 Cedar Street",
        "latitude": 43.6832,
        "longitude": -114.3600,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Modern home with great amenities."
    },
    {
        "_id": "593e929789f567dccda8693d",
        "index": 74,
        "price": 690000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property04.jpg",
        "city": "Tucson",
        "address": "4444 Birch Lane",
        "latitude": 32.2226,
        "longitude": -110.9747,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Spacious home with desert landscaping."
    },
    {
        "_id": "593e929789f567dccda8693e",
        "index": 75,
        "price": 740000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property05.jpg",
        "city": "Santa Fe",
        "address": "4545 Maple Avenue",
        "latitude": 35.6870,
        "longitude": -105.9378,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 3,
        "des": "Elegant southwestern-style home."
    },
    {
        "_id": "593e929789f567dccda8693f",
        "index": 76,
        "price": 680000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property01.jpg",
        "city": "Boulder",
        "address": "4646 Pine Street",
        "latitude": 40.0150,
        "longitude": -105.2705,
        "bedrooms": 3,
        "bathrooms": 2,
        "carSpaces": 2,
        "des": "Charming home with easy access to trails."
    },
    {
        "_id": "593e929789f567dccda86940",
        "index": 77,
        "price": 720000,
        "picture": "https://ihatetomatoes.net/demos/_rw/01-real-estate/tn_property02.jpg",
        "city": "Vail",
        "address": "4747 Cedar Lane",
        "latitude": 39.6403,
        "longitude": -106.3742,
        "bedrooms": 4,
        "bathrooms": 3,
        "carSpaces": 2,
        "des": "Luxury home with ski access."
    }
    
    
    ]
}

export default data;